import React from "react";
import useSWR from "swr";

import { DASHBOARD_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";
import OccurrencesData from "@/components/OccurrencesData";

export function OccurrencesDataContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(DASHBOARD_URL, fetcher, { suspense: true });

  return (
    <OccurrencesData
      total={data.total_occurrences}
      secondTitle="WoW Trend"
      secondValue={data.wow_trend}
      thirdTitle="MoM Trend"
      thirdValue={data.mom_trend}
      occurrences={data.occurrences}
      coloredDates
    />
  );
}

export default OccurrencesDataContainer;

/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "@/types";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Habits: {
            screens: {
              HabitsList: "habits",
              HabitView: "habits/:id",
              HabitEdit: "habits/:id/edit",
              ViceView: "vices/:id",
              ViceEdit: "vices/:id/edit",
              LocationAutomationEdit: "location-automations/:id/edit",
              HealthAutomationEdit: "health-automations/:id/edit",
            },
          },
          Timeline: {
            screens: {
              TimelineList: "timeline",
              OccurrenceEdit: "occurrences/:id/edit",
              IncidentEdit: "incidents/:id/edit",
            },
          },
          Dashboard: 'dashboard',
          Settings: {
            screens: {
              SettingsList: 'settings',
              ProjectsList: 'projects',
              ProjectsEdit: 'projects/:id/edit',
              Profile: 'profile',
              Notifications: 'notifications',
              AccountDeletion: "account_deletion",
            },
          }
        },
      },
      SignIn: "signin",
      SignUp: "signup",
      PasswordReset: "password_reset",
      NewPassword: "new_password",
      Confirmation: "confirmation",
      Modal: "modal",
      NotFound: "*",
    },
  },
};

export default linking;

import React, { useEffect } from "react";
import { ColorSchemeName } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  NavigationContainer,
  useNavigation,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Button, useThemeMode } from "@rneui/themed";
import { useStoreActions, useStoreState } from "easy-peasy";

import DevMenu from "@/components/DevMenu";
import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import useNotifications from "@/hooks/useNotifications";
import ModalScreen from "@/screens/ModalScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import DashboardScreen from "@/screens/DashboardScreen";
import SignInScreen from "@/screens/SignInScreen";
import SignUpScreen from "@/screens/SignUpScreen";
import PasswordResetScreen from "@/screens/PasswordResetScreen";
import NewPasswordScreen from "@/screens/NewPasswordScreen";
import ConfirmationScreen from "@/screens/ConfirmationScreen";
import OnboardingScreen from "@/screens/OnboardingScreen";
import { RootStackParamList, RootTabParamList } from "@/types";

import LinkingConfiguration from "./LinkingConfiguration";
import HabitStackScreen from "./HabitStackScreen";
import SettingsStackScreen from "./SettingsStackScreen";
import TimelineStackScreen from "./TimelineStackScreen";

export default function Navigation({
  colorScheme,
  theme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const { setMode } = useThemeMode();
  const devMenuOpen = useStoreState((state) => state.general.devMenuOpen);

  useEffect(() => {
    setMode(colorScheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorScheme]);

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={theme}
    >
      <RootNavigator />

      {devMenuOpen && (
        <DevMenu />
      )}
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const user = useStoreState((state) => state.session.user);
  const tokens = useStoreState((state) => state.session.tokens);
  const hasCompletedOnboarding = useStoreState(
    (state) => state.general.hasCompletedOnboarding,
  );
  const clearModal = useStoreActions((actions) => actions.modal.clearModal);
  const title = useStoreState((state) => state.modal.title);

  // Setup Notifications (this has to happen after nav init
  // because we need to useNavigation in the notifications
  useNotifications();

  const getStacks = () => {
    // Onboarding
    if (!hasCompletedOnboarding) {
      return (
        <>
          <Stack.Screen
            name="Onboarding"
            component={OnboardingScreen}
            options={{
              headerShown: false,
              title: "Onboarding",
            }}
          />
        </>
      );
    }

    // Not authenticated
    if (!user || !user.can_use_app || !tokens) {
      return (
        <>
          <Stack.Screen
            name="SignIn"
            component={SignInScreen}
            options={{ title: "Sign In" }}
          />
          <Stack.Screen
            name="SignUp"
            component={SignUpScreen}
            options={{ title: "Sign Up" }}
          />
          <Stack.Screen
            name="PasswordReset"
            component={PasswordResetScreen}
            options={{ title: "Password Reset" }}
          />
          <Stack.Screen
            name="NewPassword"
            component={NewPasswordScreen}
            options={{ title: "New Password" }}
          />
          <Stack.Screen
            name="Confirmation"
            component={ConfirmationScreen}
            options={{ title: "Confirmation" }}
          />
        </>
      );
    }

    return (
      <>
        <Stack.Screen
          name="Root"
          component={BottomTabNavigator}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
        <Stack.Group screenOptions={{ presentation: "modal" }}>
          <Stack.Screen
            name="Modal"
            component={ModalScreen}
            listeners={{
              beforeRemove: () => {
                clearModal();
              },
            }}
            options={{
              headerBackVisible: false,
              headerShown: true,
              title,
              headerLeft: () => (
                <Button type="clear" onPress={() => navigation.goBack()}>
                  <FontAwesome
                    name="long-arrow-left"
                    size={30}
                    color={Colors[colorScheme].cta}
                    style={{ marginRight: 15 }}
                  />
                </Button>
              ),
            }}
          />
        </Stack.Group>
      </>
    );
  };

  return (
    <Stack.Navigator backBehavior="order">
      {getStacks()}
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      backBehavior="order"
      initialRouteName="Habits"
    >
      <BottomTab.Screen
        name="Habits"
        component={HabitStackScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="check-square-o" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Timeline"
        component={TimelineStackScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons
              name="timeline-text-outline"
              size={30}
              color={color}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{
          title: "Dashboard",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="dashboard" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="Settings"
        component={SettingsStackScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="gear" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

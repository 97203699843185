import React from "react";
import { Calendar } from "react-native-calendars";
import { format } from "date-fns";
import Rainbow from "rainbowvis.js";

import { FontAwesome } from "@/components/Themed";
import Statistics from "@/components/Statistics";
import Statistic from "@/components/Statistic";

const RED = "A42A04";
const GREEN = "008000";

interface Props {
  total: number;
  secondTitle: string;
  secondValue: number;
  thirdTitle: string;
  thirdValue: number;
  occurrences: any[];
  coloredDates: boolean;
  longestStreak: number;
  currentStreak: number;
  showStreaks: boolean;
  isLoading: boolean;
}

export default function OccassionData({
  total,
  secondTitle,
  secondValue,
  thirdTitle,
  thirdValue,
  occurrences,
  coloredDates,
  longestStreak,
  currentStreak,
  showStreaks,
  isLoading = false,
}: Props) {
  return (
    <>
      {showStreaks && (
        <Statistics>
          <Statistic
            label="Longest Streak"
            value={isLoading ? "loading..." : `${longestStreak} days`}
          />

          <Statistic
            label="Current Streak"
            value={isLoading ? "loading..." : `${currentStreak} days`}
          />
        </Statistics>
      )}

      <Statistics>
        <Statistic label="Total" value={total || "loading..."} />

        <Statistic
          label={secondTitle}
          icon={
            isLoading ? undefined : (
              <FontAwesome
                size={30}
                name={getPeriodIcon(secondValue)}
                style={{ color: getPeriodColor(secondValue) }}
              />
            )
          }
          value={isLoading ? "loading..." : getPeriodTrend(secondValue)}
        />

        <Statistic
          label={thirdTitle}
          icon={
            isLoading ? undefined : (
              <FontAwesome
                size={30}
                name={getPeriodIcon(thirdValue)}
                style={{ color: getPeriodColor(thirdValue) }}
              />
            )
          }
          value={isLoading ? "loading..." : getPeriodTrend(thirdValue)}
        />
      </Statistics>

      {!isLoading && (
        <Calendar
          // Show week numbers to the left. Default = false
          showWeekNumbers={true}
          // Enable the option to swipe between months. Default = false
          enableSwipeMonths={true}
          markingType={"period"}
          markedDates={getMarkedDates(occurrences, coloredDates)}
        />
      )}
    </>
  );
}

const getPeriodTrend = (trend) => {
  if (trend === null) {
    return "NaN";
  }

  if (trend === "Infinity") {
    return "♾️";
  }

  return `${trend.toFixed(2)} %`;
};

const getPeriodIcon = (trend) => {
  if (trend === null) {
    return "minus";
  }

  if (Number.isFinite(trend) && trend < 50) {
    return "angle-down";
  }

  return "angle-up";
};

const getPeriodColor = (trend) => {
  if (trend === null) {
    return "grey";
  }

  if (Number.isFinite(trend) && trend < 50) {
    return "red";
  }

  return "green";
};

const getMarkedDates = (occurrences, coloredDates = true) => {
  if (coloredDates) {
    const occurrenceDates = occurrences.map((occurrence) => {
      return format(new Date(occurrence.occurred_at), "yyyy-MM-dd");
    });

    const occurrenceCounts = {};
    for (const date of occurrenceDates) {
      occurrenceCounts[date] = (occurrenceCounts[date] || 0) + 1;
    }

    const maxOccurrences = Math.max(
      Math.max(...Object.values(occurrenceCounts)), // Potentially -Infinity
      Math.max(...[1]),
    );
    const rainbow = new Rainbow();
    rainbow.setSpectrum(GREEN, RED);
    rainbow.setNumberRange(0, maxOccurrences);

    for (const key of Object.keys(occurrenceCounts)) {
      occurrenceCounts[key] = {
        color: `#${rainbow.colourAt(occurrenceCounts[key])}`,
        textColor: "white",
      };
    }

    return occurrenceCounts;
  }

  return occurrences.reduce((array, occurrence) => {
    const date = new Date(occurrence.occurred_at);

    return {
      ...array,
      [format(date, "yyyy-MM-dd")]: { color: "green", textColor: "white" },
    };
  }, {});
};

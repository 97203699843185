import React from "react";
import { useWindowDimensions, StyleSheet } from 'react-native';
import distinctColors from 'distinct-colors'
import {
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryAxis,
} from 'victory-native';

import { View, Text } from "@/components/Themed";

interface Props {
  isLoading: boolean;
  data: any;
}

export function UrgencyGraph({
  isLoading,
  data,
  selectedProject,
  projects = [],
}: Props) {
  const { width } = useWindowDimensions();
  const selectedProjectName = projects.find(project => project.id === selectedProject)?.name;

  const lineDataObjects = {};

  if (!isLoading) {
    for (let [datetime, value] of Object.entries(data)) {
      for (let [project, urgency] of Object.entries(value)) {
        if (!lineDataObjects[project]) {
          lineDataObjects[project] = [];
        }

        // START_HERE: figure out how to filter projects
        if (selectedProjectName && project !== selectedProjectName) {
          continue;
        }

        lineDataObjects[project].push({
          x: datetime,
          y: urgency || 0,
          project,
        });
      }
    }
  }

  const paddingLeft = 50;
  const chartHeight = 300;
  const paddingBottom = 80;
  const palette = distinctColors({ count: Object.keys(lineDataObjects).length })
  const projectColors = {}

  const chartWidth = width;

  for (const [index, project] of Object.keys(lineDataObjects).entries()) {
    projectColors[project] = palette[index].hex();
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Average Urgency</Text>
      <VictoryChart
        height={chartHeight}
        width={chartWidth}
        padding={{ bottom: paddingBottom + 20 }}
        domainPadding={{ y: [50, 50], x: [50, 50] }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryLegend
          orientation="horizontal"
          itemsPerRow={4}
          gutter={10}
          y={chartHeight - paddingBottom + 20}
          x={paddingLeft - 10}
          data={Object.keys(projectColors)
            .map(project => ({
              name: project,
              symbol: {
                fill: projectColors[project],
              },
            }))
            .filter(datum => selectedProjectName ? datum.name === selectedProjectName : true)
          }
        />
        <VictoryAxis
          dependentAxis
          orientation="right"
          offsetX={chartWidth/2}
        />
        <VictoryAxis
          tickFormat={(x) => new Date(x).getMonth() + '/' + new Date(x).getDay()}
        />
        {Object.keys(lineDataObjects).map(project => (
          <VictoryLine
            style={{
              data: {
                stroke: projectColors[project],
              }
            }}
            key={project}
            interpolation="natural"
            data={lineDataObjects[project]}
            labels={({ datum }) => `${datum.project}: ${datum.y || 'what'}`}
            labelComponent={<VictoryTooltip renderInPortal={false} />}
          />
        ))}
      </VictoryChart>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 20,
  },
});

export default UrgencyGraph;

import React, { Suspense } from "react";
import { StyleSheet } from "react-native";

import { View } from "@/components/Themed";
import { RootTabScreenProps } from "@/types";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";
import ViceView from "@/components/ViceView/ViceView";
import ViceViewContainer from "@/components/ViceView";

export const EMPTY_SELECTED_TAG = undefined;

export default function VicesViewScreen({
  route,
}: RootTabScreenProps<"Habits">) {
  const { id } = route.params;

  return (
    <View style={styles.container}>
      <BannerAd />

      <View style={styles.contentContainer}>
        <ErrorBoundary>
          <Suspense fallback={<ViceView isLoading />}>
            <ViceViewContainer viceId={id} />
          </Suspense>
        </ErrorBoundary>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 10,
  },
});

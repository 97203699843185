import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";

import Colors from "@/constants/Colors";
import { Text, View } from "@/components/Themed";
import useColorScheme from "@/hooks/useColorScheme";

interface Props {
  occurrence: any;
}

export default function OccurrenceCard({ occurrence }: Props) {
  const navigation = useNavigation();
  const colorScheme = useColorScheme();

  const onEdit = () => {
    navigation.navigate("Timeline", {
      screen: "OccurrenceEdit",
      initial: false,
      params: { id: occurrence.id },
    });
  };

  const wasAutomated = !!occurrence?.health_automation_id || !!occurrence?.location_automation_id;

  return (
    <TouchableOpacity onPress={onEdit}>
      <View
        style={[
          styles.card,
          { backgroundColor: Colors[colorScheme].cardBackground },
        ]}
      >
        <View style={styles.nameContainer}>
          <Text style={styles.name}>{occurrence.habit.name}</Text>
          <Text style={styles.datetime}>
            {format(new Date(occurrence.occurred_at), "pp")}
          </Text>
        </View>

        <View style={styles.iconContainer}>
          {wasAutomated && (
            <FontAwesome5
              name="robot"
              size={30}
            />
          )}
          {occurrence.was_created_from_nfc && (
            <MaterialCommunityIcons
              name="nfc-variant"
              size={30}
            />
          )}
          {!!occurrence?.habit?.discarded_at && <FontAwesome5 name="inbox" size={30} />}
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  nameContainer: {
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  iconContainer: {
    justifyContent: "center",
    backgroundColor: "transparent",
    flexDirection: 'row',
    gap: 10,
  },
  name: {
    paddingRight: 20,
    fontSize: 18,
  },
  datetime: {
    fontStyle: "italic",
    fontSize: 12,
  },
  card: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    textAlign: "center",
    fontSize: 20,
    padding: 20,
    borderLeftWidth: 5,
    borderLeftColor: "green",
    borderRadius: 10,
  },
});

const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export const RED = "A42A04";
export const YELLOW = "FFBF00";
export const GREEN = "008000";

export default {
  light: {
    text: "#000",
    background: "#fff",
    cardBackground: "#ddd",
    cardBackgroundDisabled: "#ccc",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    cta: "#5f93d1",
  },
  dark: {
    text: "#fff",
    background: "#000",
    cardBackground: "#333",
    cardBackgroundDisabled: "#222",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    cta: "#5f93d1",
  },
};

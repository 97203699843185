import React, { Suspense, useState } from "react";
import { StyleSheet } from "react-native";
import { useStoreState } from "easy-peasy";
import useSWR from "swr";
import { useIsFocused } from '@react-navigation/native';

import { ScrollView } from "@/components/Themed";
import DashboardContainer from "@/components/Dashboard";
import Dashboard from "@/components/Dashboard/Dashboard";
import UrgencyGraphContainer from "@/components/UrgencyGraph";
import UrgencyGraph from "@/components/UrgencyGraph/UrgencyGraph";
import OccurrencesDataContainer from "@/components/Dashboard/OccurrencesDataContainer";
import OccurrencesData from "@/components/OccurrencesData";
import AverageUrgency from "@/components/Dashboard/AverageUrgency/AverageUrgency";
import AverageUrgencyContainer from "@/components/Dashboard/AverageUrgency";
import ErrorBoundary from "@/components/ErrorBoundary";
import UpgradeBanner from "@/components/UpgradeBanner";
import { PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";
import { FilterAndSort } from "@/components/FilterAndSort";

export function DashboardScreen() {
  const isFocused = useIsFocused();
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(
    PROJECTS_URL,
    fetcher,
  );
  const customerIsSubscribed = useStoreState(
    (state) => state.general.customerIsSubscribed,
  );
  const user = useStoreState((state) => state.session.user);

  const userIsSubscribed = user?.treat_as_subscribed || customerIsSubscribed;

  // Search and sort
  const [project, setProject] = useState();

  return (
    <ScrollView style={styles.container}>
      {isFocused && (
        <FilterAndSort
          project={project}
          onChangeProject={setProject}
          isFocused={isFocused}
        />
      )}

      {!userIsSubscribed && <UpgradeBanner />}

      <ErrorBoundary>
        <Suspense fallback={<UrgencyGraph isLoading />}>
          <UrgencyGraphContainer
            selectedProject={project}
            projects={data?.projects}
          />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<Dashboard isLoading />}>
          <DashboardContainer
            selectedProject={project}
          />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense fallback={<AverageUrgency isLoading />}>
          <AverageUrgencyContainer />
        </Suspense>
      </ErrorBoundary>

      <ErrorBoundary>
        <Suspense
          fallback={
            <OccurrencesData
              isLoading
              secondTitle="WoW Trend"
              thirdTitle="MoM Trend"
            />
          }
        >
          <OccurrencesDataContainer />
        </Suspense>
      </ErrorBoundary>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
  },
});

export default DashboardScreen;

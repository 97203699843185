import React from "react";
import { BaseToast, ErrorToast } from "react-native-toast-message";

export const toastProps = {
  config: {
    success: (props) => (
      <BaseToast
        {...props}
        style={{
          height: props?.isVisible ? 90 : 0,
          borderLeftColor: "#69C779",
        }}
        text1NumberOfLines={1}
        text2NumberOfLines={3}
        text2Style={{
          fontSize: 12,
          color: "black",
        }}
      />
    ),
    error: (props) => (
      <ErrorToast
        {...props}
        style={{
          height: 90,
          // We have to include this manually because we're overriding
          // the default ErrorToast style prop (which has this color thing)
          borderLeftColor: "#FE6301",
        }}
        text1NumberOfLines={1}
        text2NumberOfLines={3}
        text2Style={{
          fontSize: 12,
          color: "black",
        }}
      />
    ),
  },
  position: "bottom",
  bottomOffset: 80,
  visibilityTime: 2000,
};

export default toastProps;

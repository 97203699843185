import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import DropDownPicker from 'react-native-dropdown-picker';
import { useTheme } from 'react-native-paper';

import { View } from "@/components/Themed";

interface Props {
  tag: Record<string, any>[];
  tags: Record<string, any>[];
  setTag: any;
}

export function TagSelector({
  tag,
  tags = [],
  setTag,
}: Props) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(tags.map(p => ({
    label: p.name,
    value: p.id,
  })));

  return (
    <View style={styles.container}>
      <DropDownPicker
        placeholder="Select a tag"
        listMode="FLATLIST"
        dropDownDirection="TOP"
        style={{
          minHeight: 40,
          backgroundColor: theme.colors.surfaceVariant,
          borderColor: theme.colors.border,
        }}
        containerStyle={{
          marginLeft: 20,
          marginRight: 10,
        }}
        open={open}
        value={tag}
        items={items}
        setOpen={setOpen}
        onSelectItem={({ value }) => setTag(value)}
        setItems={setItems}
      />

      <MaterialIcons
        disabled={!tag}
        color={tag ? "red" : "grey"}
        name="clear"
        size={30}
        onPress={() => setTag()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginHorizontal: 25,
  },
});

export default TagSelector;

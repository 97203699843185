import { action, computed, createStore, persist, thunk } from "easy-peasy";

import storage from "./helpers/storage-redux";
import headerTokens from "./helpers/header-tokens";
import isEmpty from "./helpers/isEmpty";
import request, {
  AUTH_URL,
  SIGN_IN_URL,
  SIGN_OUT_URL,
  VALIDATE_URL,
  PASSWORD_URL,
  USERS_URL,
} from "./helpers/api";
import Config from "./helpers/config";
import logger from "./helpers/logger";

const NO_USER = undefined;
const NO_TOKENS = undefined;
const NO_CUSTOMER_INFO = undefined;
const NO_HAS_COMPLETED_ONBOARDING = undefined;

// Fixes issue with hot reload and state not triggering updates
if (window) {
  window.requestIdleCallback = undefined;
}

const INITIAL_ENTITIES = {
  habitIdForAutomationCreation: undefined,
  habitCreate: undefined,
  projectCreate: undefined,
  viceCreate: undefined,
  viewUpgrade: undefined,
};

export const store = createStore(
  persist(
    {
      modal: {
        ...INITIAL_ENTITIES,
        title: computed((state) => {
          if (state.occurrence) {
            return "Edit Occurrence";
          }

          if (state.habitCreate) {
            return "New Habit";
          }

          if (state.viceCreate) {
            return "New Vice";
          }

          if (state.projectCreate) {
            return "New Project";
          }

          if (state.viewNavigation) {
            return "Navigation State";
          }

          if (state.viewLocationSimulator) {
            return "Location Simulator";
          }

          if (state.viewRegisteredNotifications) {
            return "Registered Notifications";
          }

          if (state.viewLogs) {
            return "Logs";
          }

          return "";
        }),
        viewAutomationCreate: action((state, payload) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.habitIdForAutomationCreation = payload;
        }),
        viewHabitCreate: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.habitCreate = true;
        }),
        viewProjectCreate: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.projectCreate = true;
        }),
        viewViceCreate: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viceCreate = true;
        }),
        viewUpgrade: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viewUpgrade = true;
        }),
        viewNavigation: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viewNavigation = true;
        }),
        viewLocationSimulator: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viewLocationSimulator = true;
        }),
        viewRegisteredNotifications: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viewRegisteredNotifications = true;
        }),
        viewLogs: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
          state.viewLogs = true;
        }),
        clearModal: action((state) => {
          for (const [key, value] of Object.entries(INITIAL_ENTITIES)) {
            state[key] = value;
          }
        }),
      },
      general: {
        habitSwitch: true,
        devMenuOpen: false,
        setDevMenuOpen: action((state, payload) => {
          state.devMenuOpen = payload;
        }),
        setHabitSwitch: action((state, payload) => {
          state.habitSwitch = payload;
        }),
        selectedProject: undefined,
        setSelectedProject: action((state, payload) => {
          state.selectedProject = payload;
        }),
        customerInfo: NO_CUSTOMER_INFO,
        customerIsSubscribed: computed((state) => {
          return state?.customerInfo?.entitlements.active[
            Config.revenueCat.entitlementId
          ];
        }),
        setCustomerInfo: action((state, payload) => {
          state.customerInfo = payload;
        }),
        clearCustomerInfo: action((state) => {
          state.customerInfo = NO_CUSTOMER_INFO;
        }),
        hasCompletedOnboarding: NO_HAS_COMPLETED_ONBOARDING,
        setHasCompletedOnboarding: action((state, payload) => {
          state.hasCompletedOnboarding = payload;
        }),
      },
      session: {
        user: NO_USER,
        tokens: NO_TOKENS,
        shouldReceiveNotifications: true,
        setShouldReceiveNotifications: action((state, payload) => {
          state.shouldReceiveNotifications = payload;
        }),
        clearUser: action((state) => {
          state.user = NO_USER;
        }),
        clearTokens: action((state) => {
          state.tokens = NO_TOKENS;
        }),
        setUser: action((state, payload) => {
          state.user = payload;
        }),
        setTokens: action((state, payload) => {
          state.tokens = payload;
        }),
        setTokensIfNecessary: action((state, payload) => {
          // Seems like the access-token can be a string with one space in it:
          // https://github.com/lynndylanhurley/devise_token_auth/blob/702eab7e585503a51d32d28819edf7526e7394da/app/controllers/devise_token_auth/concerns/set_user_by_token.rb#L191
          if (
            !isEmpty(payload["access-token"]) &&
            payload["access-token"] !== " "
          ) {
            state.tokens = payload;
          }
        }),
        syncExpoPushToken: thunk(async (actions, payload, { getState }) => {
          logger.info("store#syncExpoPushToken", { payload });
          const { tokens, user } = getState();

          if (!user) {
            logger.warn("syncExpoPushToken_no-user-given");
            return;
          }

          const { result, headers } = await request(`${USERS_URL}/${user.id}`, {
            body: {
              expo_push_token: payload,
            },
            headers: tokens,
            method: "PATCH",
          });

          actions.setTokensIfNecessary(headerTokens(headers));
          actions.setUser(result.user);
        }),
        refreshUser: thunk(async (actions, payload, { getState }) => {
          const { tokens, user } = getState();

          if (!user) {
            logger.warn("refreshUser_no-user-given");
            return;
          }

          const { result, headers } = await request(`${USERS_URL}/${user.id}`, {
            headers: tokens,
            method: "GET",
          });

          actions.setTokensIfNecessary(headerTokens(headers));
          actions.setUser(result.user);
        }),
        signIn: thunk(async (actions, { email, password }) => {
          const { result, headers } = await request(SIGN_IN_URL, {
            body: {
              email,
              password,
            },
            method: "POST",
          });
          actions.setTokens(headerTokens(headers));
          actions.setUser(result.data);
        }),
        signUp: thunk(
          async (
            actions,
            { email, password, passwordConfirmation, confirmSuccessUrl },
          ) => {
            const { result, headers } = await request(AUTH_URL, {
              body: {
                email,
                password,
                password_confirmation: passwordConfirmation,
                confirm_success_url: confirmSuccessUrl,
              },
              method: "POST",
            });
            // I don't think we need to necessarily set tokens,
            // but is potentially useful if we want to disable
            // confirmable
            actions.setTokensIfNecessary(headerTokens(headers));
            actions.setUser(result.data);
          },
        ),
        signOut: thunk(async (actions, payload, { getState }) => {
          const { tokens } = getState();
          await request(SIGN_OUT_URL, { method: "DELETE", headers: tokens });
          actions.clearUser();
          actions.clearTokens();
        }),
        resetPassword: thunk(async (actions, { email, redirectUrl }) => {
          return request(PASSWORD_URL, {
            method: "POST",
            body: {
              email,
              redirect_url: redirectUrl,
            },
          });
        }),
        setNewPassword: thunk(
          async (
            actions,
            { password, passwordConfirmation, resetPasswordToken },
          ) => {
            return request(PASSWORD_URL, {
              method: "PUT",
              body: {
                password,
                password_confirmation: passwordConfirmation,
                reset_password_token: resetPasswordToken,
              },
            });
          },
        ),
        validateToken: thunk(async (actions, payload, { getState }) => {
          try {
            const { tokens } = getState();
            await request(VALIDATE_URL, { headers: tokens });
          } catch (error) {
            actions.clearUser();
            actions.clearTokens();

            logger.error("unable-to-validate-token", { error });
          }
        }),
        deleteAccount: thunk(async (actions, payload, { getState }) => {
          const { tokens } = getState();
          await request(AUTH_URL, {
            method: "DELETE",
            headers: tokens,
          });
          actions.clearUser();
          actions.clearTokens();
        }),
      },
    },
    {
      // Only persist the session state
      allow: ["session"],
      storage,
    },
  ),
);

export default store;

import React from "react";
import { StyleSheet } from "react-native";
import Animated, { Layout } from "react-native-reanimated";
import { Button, SearchBar } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { useStoreActions } from "easy-peasy";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from 'react-native-paper';

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { Text, View } from "@/components/Themed";

import { ViceCard } from "./ViceCard";

interface Props {
  vices: Record<string, unknown>[];
  isLoading: boolean;
  mutate: any;
  isValidating: boolean;
}

const getFilteredVices = (vices, text) => {
  const filteredVices = vices.filter((habit) => {
    return habit.name.toLowerCase().match(text.toLowerCase());
  });

  return filteredVices;
};

export function ViceList({
  mutate,
  isValidating = false,
  vices = [],
  isLoading = false,
}: Props) {
  const theme = useTheme();
  const navigation = useNavigation();
  const [text, setText] = React.useState("");
  const colorScheme = useColorScheme();

  const viewViceCreate = useStoreActions(
    (actions) => actions.modal.viewViceCreate,
  );
  const viewViceCreateAndShowModal = () => {
    viewViceCreate();
    navigation.navigate("Modal");
  };

  const filteredVices = getFilteredVices(vices, text);

  return (
    <View>
      {isLoading ? (
        <Text>loading...</Text>
      ) : (
        <View>
          <View style={styles.searchHeader}>
            <SearchBar
              placeholder="Search"
              platform="ios"
              onChangeText={setText}
              value={text}
              showLoading={isValidating || isLoading}
              autoCapitalize="none"
              containerStyle={{ width: "85%" }}
              inputStyle={{ color: theme.colors.primary }}
            />

            <Button type="clear" onPress={() => viewViceCreateAndShowModal()}>
              <FontAwesome5
                name="plus-circle"
                size={40}
                style={{ color: Colors[colorScheme].cta }}
              />
            </Button>
          </View>

          <Animated.FlatList
            itemLayoutAnimation={layout}
            data={filteredVices}
            keyExtractor={(item) => item.id.toString()}
            onRefresh={() => mutate()}
            refreshing={false}
            contentContainerStyle={styles.list}
            renderItem={({ item }) => {
              return <ViceCard vice={item} />;
            }}
          />
        </View>
      )}
    </View>
  );
}

const layout = Layout.springify();

const styles = StyleSheet.create({
  searchHeader: {
    paddingTop: 10,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    paddingBottom: 450,
    paddingTop: 10,
    paddingHorizontal: 10,
  },
});

export default ViceList;

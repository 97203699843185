import React from "react";
import { StyleSheet, ScrollView } from "react-native";

import { Text } from "@/components/Themed";

export function HealthSelector() {
  return (
    <ScrollView style={styles.container} contentInset={{ bottom: 30 }}>
      <Text style={styles.title}>Health automations not supported on Web! Please try our native apps!</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 25,
  },
  title: {
    fontSize: 16,
    marginBottom: 25,
  },
});

export default HealthSelector;

import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Formik } from "formik";
import NumericInput from "react-native-numeric-input";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {
  Button as PaperButton,
  Text as PaperText,
  TextInput,
  HelperText,
  Switch as PaperSwitch,
  Modal,
} from 'react-native-paper';
import { Portal } from "@gorhom/portal";

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { View } from "@/components/Themed";
import MapSelector from "@/components/MapSelector";

interface Props {
  onSubmit: any;
  initialValues: any;
  shownInModal: boolean;
}

export const LocationAutomationForm = ({
  onSubmit,
  initialValues = {},
  shownInModal = false
}: Props) => {
  const colorScheme = useColorScheme();

  // Automation Type Dropdown
  const [visible, setVisible] = useState(false);

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const containerStyle = { margin: 20, backgroundColor: 'white', padding: 20, height: '80%'};

  const onSubmitMiddleware = (
    formikValues,
    formikHelpers,
  ) => {
    const newFormikValues = {
      ...formikValues,
      radiusInKilometers: formikValues.radiusInMeters / 1000,
    };

    return onSubmit(newFormikValues, formikHelpers);
  }

  return (
    <Formik
      initialValues={{
        name: undefined,
        latitude: undefined,
        longitude: undefined,
        radiusInMeters: 100,
        shouldConfirm: true,
        ...initialValues,
      }}
      onSubmit={onSubmitMiddleware}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => {
        return (
          <KeyboardAwareScrollView contentContainerStyle={styles.container}>
            <Portal hostName={shownInModal ? "ModalModalHost" : "ModalHost"}>
              <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                {visible && (
                  <MapSelector
                    latitude={values.latitude}
                    longitude={values.longitude}
                    radiusInMeters={values.radiusInMeters}
                    onSubmit={({ longitude, latitude, radiusInMeters }) => {
                      setFieldValue("radiusInMeters", radiusInMeters);
                      setFieldValue("longitude", String(longitude));
                      setFieldValue("latitude", String(latitude));

                      hideModal();
                    }}
                  />
                )}
              </Modal>
            </Portal>

            <View style={styles.formGroup}>
              <TextInput
                label="Name"
                value={values.nam}
                onBlur={handleBlur("name")}
                onChangeText={handleChange("name")}
              />

              {touched.name && errors.name && (
                <HelperText type="error" visible={true}>
                  {errors.name}
                </HelperText>
              )}
            </View>

            <View style={styles.formGroup}>
              <TextInput
                label="Latitude"
                value={values.latitude}
                onBlur={handleBlur("latitude")}
                onChangeText={handleChange("latitude")}
              />

              {touched.latitude && errors.latitude && (
                <HelperText type="error" visible={true}>
                  {errors.latitude}
                </HelperText>
              )}
            </View>

            <View style={styles.formGroup}>
              <TextInput
                label="Longitude"
                value={values.longitude}
                onBlur={handleBlur("longitude")}
                onChangeText={handleChange("longitude")}
              />

              {touched.longitude && errors.longitude && (
                <HelperText type="error" visible={true}>
                  {errors.longitude}
                </HelperText>
              )}
            </View>

            <View style={styles.formGroup}>
              <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Radius (in meters):</PaperText>

                <NumericInput
                  minValue={1}
                  totalHeight={40}
                  rounded
                  value={values.radiusInMeters}
                  initValue={values.radiusInMeters}
                  onChange={(value) => setFieldValue("radiusInMeters", value)}
                  inputStyle={{ color: Colors[colorScheme].text }}
                />
              </View>

              {touched.frequencyTarget && errors.frequencyTarget && (
                <HelperText type="error" visible={true}>
                  {errors.frequencyTarget}
                </HelperText>
              )}
            </View>

            <PaperButton
              icon="map"
              mode="outlined"
              onPress={showModal}
              style={{ marginBottom: 20 }}
            >
              Map Selector
            </PaperButton>

            <View style={styles.formGroup}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Confirm before completing:</PaperText>

                <PaperSwitch
                  value={values.shouldConfirm}
                  onValueChange={(val) => setFieldValue("shouldConfirm", val)}
                />
              </View>

              {touched.shouldConfirm && errors.shouldConfirm && (
                <HelperText type="error" visible={true}>
                  {errors.shouldConfirm}
                </HelperText>
              )}
            </View>

            <PaperButton
              icon="content-save"
              mode="contained"
              loading={isSubmitting}
              onPress={handleSubmit}
            >
              Submit
            </PaperButton>
          </KeyboardAwareScrollView>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 100,
  },
  formGroup: {
    marginBottom: 30,
  },
});

export default LocationAutomationForm;

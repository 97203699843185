import React from "react";
import { StyleSheet } from "react-native";
import { Formik } from "formik";
import { Button } from "react-native-paper";
import NumericInput from "react-native-numeric-input";

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { Text, View } from "@/components/Themed";

interface ErrorTextProps {
  children: React.ReactNode;
}

export const ErrorText = ({ children }: ErrorTextProps) => (
  <Text style={styles.errorText}>{children}</Text>
);

interface Props {
  onSubmit: any;
  initialValues: any;
}

export const ProfileForm = ({
  onSubmit,
  initialValues = {},
}: Props) => {
  const colorScheme = useColorScheme();


  const onSubmitMiddleware = (
    formikValues,
    formikHelpers,
  ) => {
    return onSubmit(formikValues, formikHelpers);
  }

  return (
    <Formik
      initialValues={{
        bandwidthBufferPercentage: 20,
        averageDailyFreetimeInMinutes: 240,
        ...initialValues,
      }}
      onSubmit={onSubmitMiddleware}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <View style={styles.container}>
          <View style={styles.formGroup}>
            <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ marginRight: 10 }}>Bandwidth Buffer</Text>

              <NumericInput
                totalHeight={40}
                containerStyle={{ marginRight: 10 }}
                value={values.bandwidthBufferPercentage}
                onChange={(value) => setFieldValue("bandwidthBufferPercentage", value)}
                inputStyle={{ color: Colors[colorScheme].text }}
              />

              <Text style={styles.label}>%</Text>
            </View>
          </View>

          {touched.bandwidthBufferPercentage && errors.bandwidthBufferPercentage ? (
            <ErrorText>{errors.bandwidthBufferPercentage}</ErrorText>
          ) : undefined}

          <View style={styles.formGroup}>
            <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ marginRight: 10 }}>Daily Freetime</Text>

              <NumericInput
                containerStyle={{ marginRight: 10 }}
                totalHeight={40}
                value={values.averageDailyFreetimeInMinutes}
                onChange={(value) => setFieldValue("averageDailyFreetimeInMinutes", value)}
                inputStyle={{ color: Colors[colorScheme].text }}
              />

              <Text style={styles.label}>min</Text>
            </View>
          </View>

          {touched.averageDailyFreetimeInMinutes && errors.averageDailyFreetimeInMinutes ? (
            <ErrorText>{errors.averageDailyFreetimeInMinutes}</ErrorText>
          ) : undefined}

          <Button
            mode="contained"
            onPress={handleSubmit}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  errorText: {
    color: "red",
    marginVertical: 10,
  },
  formGroup: {
    marginBottom: 30,
  },
});

export default ProfileForm;

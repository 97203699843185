import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import { Button } from "react-native-paper";
import { Input } from "@rneui/themed";
import Popover from "react-native-popover-view";
import { FontAwesome5 } from "@expo/vector-icons";

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { Text, View } from "@/components/Themed";

interface Props {
  onSubmit: any;
  initialValues: any;
}

export const ViceForm = ({ onSubmit, initialValues = {} }: Props) => {
  const colorScheme = useColorScheme();

  return (
    <Formik
      initialValues={{
        name: "",
        icon: "",
        ...initialValues,
      }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        isSubmitting,
        touched,
        errors,
      }) => (
        <View>
          <Input
            placeholder="Name"
            onChangeText={handleChange("name")}
            onBlur={handleBlur("name")}
            value={values.name}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
          />

          <View style={styles.fieldContainer}>
            <Input
              containerStyle={styles.popoverInput}
              placeholder="Icon"
              onChangeText={handleChange("icon")}
              onBlur={handleBlur("icon")}
              value={values.icon}
              autoCapitalize={"none"}
              autoCorrect={false}
              rightIcon={
                <FontAwesome5
                  name={values.icon}
                  size={35}
                  style={{ color: Colors[colorScheme].text }}
                />
              }
              errorMessage={touched.icon && errors.icon ? errors.icon : undefined}
            />

            <Popover
              from={
                <TouchableOpacity>
                  <FontAwesome5
                    name="info-circle"
                    size={20}
                    style={{ color: Colors[colorScheme].text }}
                  />
                </TouchableOpacity>
              }
            >
              <Text style={styles.popover}>
                This icon will show on the vice card.
              </Text>
            </Popover>
          </View>

          <Button
            onPress={handleSubmit}
            mode="contained"
            loading={isSubmitting}
          >
            Submit
          </Button>
        </View>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  fieldContainer: {
    flexDirection: "row",
    alignContent: "center",
    marginBottom: 20,
  },
  popoverInput: {
    width: "80%",
  },
  popover: {
    padding: 10,
    color: "black",
  },
});

export default ViceForm;

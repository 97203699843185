import React, { useState, useEffect } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { Card } from 'react-native-paper';
import * as Notifications from 'expo-notifications';

import { ScrollView, Text } from "@/components/Themed";

export function ViewRegisteredNotifications() {
  // Scheduled notifications
  const [scheduledNotifications, setScheduledNotifications] = useState();
  useEffect(() => {
    const getNotifications = async () => {
      const notifications = await Notifications.getAllScheduledNotificationsAsync();
      setScheduledNotifications(notifications);
    }

    getNotifications();
  }, []);

  if (!scheduledNotifications) {
    return <ActivityIndicator size="large" />;
  }

  return (
    <ScrollView style={styles.container}>
      {scheduledNotifications.map(notification => (
        <Card key={notification.id} style={styles.card}>
          <Card.Content>
            <Text variant="bodyMedium">
              {JSON.stringify(notification, null, 2)}
            </Text>
          </Card.Content>
        </Card>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    flex: 1,
  },
  card: {
    marginBottom: 20,
  }
});

export default ViewRegisteredNotifications;

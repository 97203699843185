import "./helpers/logIgnore";
import "expo-dev-client";
import "./tasks";

import { Platform } from 'react-native';
import { PortalProvider, PortalHost } from "@gorhom/portal";
import {
  adaptNavigationTheme,
  PaperProvider,
  MD3DarkTheme,
  MD3LightTheme,
} from 'react-native-paper';
import React from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import * as Sentry from "sentry-expo";
import { ThemeProvider, createTheme } from "@rneui/themed";
import { StoreProvider } from "easy-peasy";
import Toast from "react-native-toast-message";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SWRConfig } from "swr";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';

import merge from 'deepmerge';

import useCachedResources from "@/hooks/useCachedResources";
import useColorScheme from "@/hooks/useColorScheme";
import useMobileAds from "@/hooks/useMobileAds";
import useBackgroundTasks from "@/hooks/useBackgroundTasks";
import useAppStateToRefreshStore from "@/hooks/useAppStateToRefreshStore";
import useHabitNotifications from "@/hooks/useHabitNotifications";
import Navigation from "@/navigation";
import Config from "@/helpers/config";
import toastProps from "@/helpers/toastProps";
import { store } from "@/store";
import { Text } from "@/components/Themed";

const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
});

const CombinedDefaultTheme = merge(MD3LightTheme, LightTheme);
const CombinedDarkTheme = merge(MD3DarkTheme, DarkTheme);

const sentrySettings = {
  dsn: "https://72cbd8ec07b6420c84c205d09407c478@o1290439.ingest.sentry.io/6511204",
  enableInExpoDevelopment: false,
  debug: Config.env === 'development', // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  environment: Config.env,
  tracesSampleRate: 0.5,
}

if (Platform.OS === 'web') {
  sentrySettings.integrations = [
    new Sentry.Browser.BrowserTracing({
      // tracingOrigins: ['localhost', 'domain.com', /^\//],
    }),
    new Sentry.Browser.Replay(),
  ];

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  sentrySettings.replaysSessionSampleRate = 0.1;

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  sentrySettings.replaysOnErrorSampleRate = 1;
} else {
  sentrySettings.integrations = [new Sentry.Native.ReactNativeTracing()];
}

Sentry.init(sentrySettings);

const theme = createTheme({});

function ContextualizedAndLoadedApp() {
  const colorScheme = useColorScheme();

  // Hooks that require cached resources (stores, etc)
  useBackgroundTasks();
  useAppStateToRefreshStore();
  useHabitNotifications();

  const activeTheme = colorScheme === "dark" ? CombinedDarkTheme : CombinedDefaultTheme;

  return (
    <PortalProvider>
      <PaperProvider theme={activeTheme}>
        <Navigation
          colorScheme={colorScheme}
          theme={activeTheme}
        />
        <StatusBar />

        <PortalHost name="ModalHost" />
      </PaperProvider>
    </PortalProvider>
  );
}

function ContextualizedApp() {
  const isLoadingComplete = useCachedResources();

  // Hooks that don't require cached resources (stores, etc)
  useMobileAds();

  if (!isLoadingComplete) {
    return <Text>loading...</Text>;
  }

  return <ContextualizedAndLoadedApp />;
}

function App() {
  const swrConfig = {};

  // Set refresh interval to 30 seconds if not in development
  if (!__DEV__) {
    swrConfig.refreshInterval = 30000;
  }

  return (
    <SafeAreaProvider>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <SWRConfig
              value={swrConfig}
            >
              <ContextualizedApp />

              <Toast {...toastProps} />
            </SWRConfig>
          </GestureHandlerRootView>
        </ThemeProvider>
      </StoreProvider>
    </SafeAreaProvider>
  );
}

export default App;

import React, { Suspense } from "react";
import { StyleSheet } from "react-native";

import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";
import Profile from "@/components/Profile/Profile";
import ProfileContainer from "@/components/Profile";

export default function ProfileScreen() {
  return (
    <View style={styles.container}>
      <BannerAd />

      <View style={styles.container}>
        <ErrorBoundary>
          <Suspense fallback={<Profile isLoading />}>
            <ProfileContainer />
          </Suspense>
        </ErrorBoundary>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import * as Linking from "expo-linking";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";

import Colors from "@/constants/Colors";
import { FontAwesome, Text, View } from "@/components/Themed";
import useColorScheme from "@/hooks/useColorScheme";
import pluralize from "@/helpers/pluralize";
import timesPhrase from "@/helpers/times-phrase";

export const getFrequencyText = (habit) => {
  let period = habit.frequency_period;

  if (habit.frequency_number > 1) {
    period = pluralize(habit.frequency_number, habit.frequency_period);
  }

  return `${timesPhrase(habit.frequency_target)} every ${period}`;
};

interface Props {
  habit: any;
  children: React.ReactNode;
}

export function HabitCardArchived({ habit }: Props) {
  const navigation = useNavigation();
  const colorScheme = useColorScheme();

  const onView = () => {
    navigation.navigate("HabitView", { id: habit.id });
  };

  const onPressLink = () => {
    Linking.openURL(habit.link);
  };

  const MiddleWrapper = (props) => {
    if (habit.link) {
      return <TouchableOpacity {...props}>{props.children}</TouchableOpacity>;
    }

    return <View {...props}>{props.children}</View>;
  };

  return (
    <TouchableOpacity onPress={onView}>
      <View
        style={[
          styles.container,
          { backgroundColor: Colors[colorScheme].cardBackgroundDisabled },
        ]}
      >
        <View style={styles.nameContainer}>
          <MiddleWrapper style={styles.nameContainerTop} onPress={onPressLink}>
            <Text
              style={[
                styles.name,
                habit.link ? { textDecorationLine: "underline" } : {},
              ]}
            >
              {habit.name}
              {habit.link && (
                <View style={styles.externalLink}>
                  <FontAwesome name="external-link" />
                </View>
              )}
            </Text>
          </MiddleWrapper>
        </View>

        <View style={styles.rightSide}>
          <Text style={[styles.rateBase]}>
            Archived On:{"\n"}
            {format(new Date(habit.discarded_at), "yyyy-MM-dd")}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  externalLink: {
    paddingLeft: 5,
    backgroundColor: "transparent",
  },
  rateBase: {
    fontWeight: "bold",
    textAlign: "right",
  },
  rightSide: {
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  nameContainer: {
    justifyContent: "center",
    paddingLeft: 20,
    backgroundColor: "transparent",
    width: 0,
    flexGrow: 1,
  },
  nameContainerTop: {
    backgroundColor: "transparent",
  },
  name: {
    fontSize: 18,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    borderRadius: 10,
  },
});

export default HabitCardArchived;

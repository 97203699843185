import React from "react";
import useSWR from "swr";

import { PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ProjectEdit from "./ProjectEdit";

interface Props {
  projectId: any;
}

export function ProjectEditContainer({ projectId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(`${PROJECTS_URL}/${projectId}`, fetcher, {
    suspense: true,
  });

  if (!data) return;

  return <ProjectEdit project={data.project} />;
}

export default ProjectEditContainer;

const convertToDaily = (habit) => {
  switch(habit.frequency_period) {
    case "year": {
      return habit.duration_in_minutes / 365;
    }
    case "month": {
      return habit.duration_in_minutes / 30;
    }
    case "week": {
      return habit.duration_in_minutes / 7;
    }
    case "day": {
      return habit.duration_in_minutes;
    }
    case "hour": {
      return habit.duration_in_minutes * 24;
    }
    default: {
      throw new Error('getDailyBandwidth: Unsupported frequency_period');
    }
  }
}

export const getDailyDurationInMinutes = (habit): number => {
  const dailyDuration = convertToDaily(habit);

  return dailyDuration / habit.frequency_number * habit.frequency_target;
}

export const getDailyBandwidth = (selectedHabits): number => {
  return selectedHabits.reduce((accum, habit) => {
    return accum + getDailyDurationInMinutes(habit);
  }, 0)
};

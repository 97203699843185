import React, { Suspense } from "react";
import { StyleSheet } from "react-native";

import Timeline from "@/components/Timeline/Timeline";
import TimelineContainer from "@/components/Timeline";
import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";

export default function TimelineScreen() {
  return (
    <View style={styles.container}>
      <ErrorBoundary>
        <BannerAd />

        <View style={styles.contentContainer}>
          <Suspense fallback={<Timeline isLoading />}>
            <TimelineContainer />
          </Suspense>
        </View>
      </ErrorBoundary>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 10,
  },
});

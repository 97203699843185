import React from "react";
import * as Linking from "expo-linking";

import { Text } from "@/components/Themed";

interface Props {
  onPress: any;
  href: string;
  children: React.ReactNode;
}

export function Anchor(props: Props) {
  const { onPress, href, children } = props;

  const handlePress = () => {
    Linking.openURL(href);
    onPress && onPress();
  };

  return (
    <Text {...props} onPress={handlePress}>
      {children}
    </Text>
  );
}

export default Anchor;

import React from "react";

import Statistics from "@/components/Statistics";
import Statistic from "@/components/Statistic";

interface Props {
  habits?: any[];
}

export function AverageUrgency({ habits = [] }: Props) {
  const averageableHabits = habits.filter((habit) =>
    Number.isFinite(habit.urgency_index) && !habit.is_discarded,
  );

  const total = averageableHabits
    .map((habit) => habit.urgency_index)
    .reduce((partialSum, a) => partialSum + a, 0);

  const average = total / averageableHabits.length;

  return (
    <Statistics>
      <Statistic label="Average Urgency" value={average} />
    </Statistics>
  );
}

export default AverageUrgency;

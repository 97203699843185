import AsyncStorage from '@react-native-async-storage/async-storage';

const prefix = "general-storage";

export const storage = {
  // getItem(key) => any | Promise<any> | void
  get: async (key) => {
    const value = await AsyncStorage.getItem(`${prefix}/${key}`)

    if (value) {
      return JSON.parse(value);
    }
  },
  // setItem(key, data) => void | Promise<void>
  set: async (key, data) => {
    await AsyncStorage.setItem(`${prefix}/${key}`, JSON.stringify(data))
  },
  // removeItem(key) => void | Promise<void>
  removeItem: async (key) => {
    await AsyncStorage.removeItem(`${prefix}/${key}`);
  },
};

export default storage;

import React, { Suspense } from "react";
import { useStoreState } from "easy-peasy";
import Toast from "react-native-toast-message";
import { StyleSheet } from "react-native";
import { PortalHost } from "@gorhom/portal";

import AutomationCreate from "@/components/AutomationCreate";
import HabitCreate from "@/components/HabitCreate/HabitCreate";
import HabitCreateContainer from "@/components/HabitCreate";
import ProjectCreate from "@/components/ProjectCreate/ProjectCreate";
import ProjectCreateContainer from "@/components/ProjectCreate";
import ViceCreate from "@/components/ViceCreate/ViceCreate";
import ViceCreateContainer from "@/components/ViceCreate";
import Upgrade from "@/components/Upgrade/Upgrade";
import UpgradeContainer from "@/components/Upgrade";
import Explanation from "@/components/Explanation";
import ErrorBoundary from "@/components/ErrorBoundary";
import ViewNavigation from "@/components/ViewNavigation";
import ViewLocationSimulator from "@/components/ViewLocationSimulator";
import ViewRegisteredNotifications from "@/components/ViewRegisteredNotifications";
import ViewLogs from "@/components/ViewLogs";
import { View } from "@/components/Themed";
import toastProps from "@/helpers/toastProps";

export function ModalScreen() {
  const habitCreate = useStoreState((state) => state.modal.habitCreate);
  const viceCreate = useStoreState((state) => state.modal.viceCreate);
  const projectCreate = useStoreState((state) => state.modal.projectCreate);
  const viewUpgrade = useStoreState((state) => state.modal.viewUpgrade);
  const viewNavigation = useStoreState((state) => state.modal.viewNavigation);
  const viewLocationSimulator = useStoreState((state) => state.modal.viewLocationSimulator);
  const viewRegisteredNotifications = useStoreState((state) => state.modal.viewRegisteredNotifications);
  const viewLogs = useStoreState((state) => state.modal.viewLogs);
  const habitIdForAutomationCreation = useStoreState((state) => {
    return state.modal.habitIdForAutomationCreation;
  });

  const getContent = () => {
    if (habitCreate) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<HabitCreate isLoading />}>
            <HabitCreateContainer />
          </Suspense>
        </ErrorBoundary>
      );
    }

    if (projectCreate) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<ProjectCreate isLoading />}>
            <ProjectCreateContainer />
          </Suspense>
        </ErrorBoundary>
      );
    }

    if (viceCreate) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<ViceCreate isLoading />}>
            <ViceCreateContainer />
          </Suspense>
        </ErrorBoundary>
      );
    }

    if (viewUpgrade) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<Upgrade isLoading />}>
            <UpgradeContainer />
          </Suspense>
        </ErrorBoundary>
      );
    }

    if (habitIdForAutomationCreation) {
      return <AutomationCreate habitId={habitIdForAutomationCreation} />;
    }

    if (viewNavigation) {
      return <ViewNavigation />;
    }

    if (viewLocationSimulator) {
      return <ViewLocationSimulator />;
    }

    if (viewRegisteredNotifications) {
      return <ViewRegisteredNotifications />;
    }

    if (viewLogs) {
      return <ViewLogs />;
    }

    return <Explanation />;
  };

  return (
    <View style={styles.container}>
      {getContent()}

      <Toast {...toastProps} />

      <PortalHost name="ModalModalHost" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default ModalScreen;

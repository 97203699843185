import { format } from "date-fns";

export const formatTime = ({ hours, minutes }) => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return format(date, "p");
}

export default formatTime;

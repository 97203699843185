import React from "react";
import { StyleSheet } from "react-native";
import { Ionicons } from '@expo/vector-icons';

import { View, Text } from "@/components/Themed";

interface Props {
  noun: string;
}

export function EmptyList({ noun, containerStyles = {} }: Props) {
  return (
    <View style={[styles.emptyListContainer, containerStyles]}>
      <Text>Looks like you don&apos;t have any {noun}!</Text>
      <Ionicons name="file-tray-outline" size={50} color="black" />
      <Text>Add some!</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyListContainer: {
    alignItems: 'center',
  }
});

export default EmptyList;

import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import { useRoute } from "@react-navigation/native";

import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";
import HealthAutomationEdit from "@/components/HealthAutomationEdit/HealthAutomationEdit";
import HealthAutomationEditContainer from "@/components/HealthAutomationEdit";

export default function HealthAutomationEditScreen() {
  const route = useRoute();

  const { id } = route.params;

  return (
    <View style={styles.container}>
      <BannerAd />

      <View style={styles.container}>
        <ErrorBoundary>
          <Suspense fallback={<HealthAutomationEdit isLoading />}>
            <HealthAutomationEditContainer healthAutomationId={id} />
          </Suspense>
        </ErrorBoundary>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

import React from "react";
import { StyleSheet } from "react-native";
import { Button } from "@rneui/themed";
import { useStoreActions } from "easy-peasy";
import { useNavigation } from "@react-navigation/native";

import { View } from "@/components/Themed";

export function UpgradeBanner() {
  const navigation = useNavigation();

  const onUpgrade = useStoreActions((actions) => actions.modal.viewUpgrade);

  return (
    <View style={styles.container}>
      <Button
        type="primary"
        onPress={() => {
          onUpgrade();
          navigation.navigate("Modal");
        }}
        title="Upgrade to Premium!"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginTop: 10,
    justifyContent: "center",
    // paddingHorizontal: 40,
    // margin: 10,
  },
});

export default UpgradeBanner;

import React, { useState, useLayoutEffect } from "react";
import { StyleSheet, ScrollView } from 'react-native';
import { Card, Button } from '@rneui/themed';
import { FontAwesome5 } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Button as PaperButton } from 'react-native-paper';

import { Text, View } from "@/components/Themed";
import LocationAutomationCreate from "@/components/LocationAutomationCreate";
import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import logger from "@/helpers/logger";

import HealthSelector from "./HealthSelector";

const NO_SELECTED_TYPE = undefined;
const HEALTH_SELECTION_TYPE = 'health';
const LOCATION_SELECTION_TYPE = 'location';
const LARGE_ICON_SIZE = 160;

interface Props {
  habitId: number;
}

export function AutomationCreate({ habitId }: Props) {
  const [selectedType, setSelectedType] = useState(NO_SELECTED_TYPE);
  const navigation = useNavigation();
  const colorScheme = useColorScheme();

  useLayoutEffect(() => {
    if ([HEALTH_SELECTION_TYPE, LOCATION_SELECTION_TYPE].includes(selectedType)) {
      navigation.setOptions({
        headerLeft: () => (
          <Button type="clear" onPress={() => setSelectedType(NO_SELECTED_TYPE)}>
            <Text style={{ color: 'red' }}>Select another type</Text>
          </Button>
        ),
      });
    } else {
      navigation.setOptions({
        headerLeft: () => (
          <Button type="clear" onPress={() => navigation.goBack()}>
            <FontAwesome
              name="long-arrow-left"
              size={30}
              color={Colors[colorScheme].cta}
              style={{ marginRight: 15 }}
            />
          </Button>
        ),
        headerRight: undefined,
      });
    }
  }, [selectedType, colorScheme, navigation]);

  const getContent = () => {
    if (selectedType === HEALTH_SELECTION_TYPE) {
      return <HealthSelector habitId={habitId} />;
    }

    if (selectedType === LOCATION_SELECTION_TYPE) {
      return <LocationAutomationCreate habitId={habitId} />
    }

    return (
      <ScrollView contentInset={{bottom: 20}}>
        <Text style={styles.title}>Select a Type:</Text>
        <Card>
          <Card.Title h2>Health</Card.Title>
          <Card.Divider />
          <View style={{position:"relative",alignItems:"center"}}>
            <FontAwesome5 name="heartbeat" size={160} color="black" />
          </View>

          <Text style={styles.description}>
            Select a health activity type for automating habits.
          </Text>

          <PaperButton
            mode="contained"
            icon='heart'
            onPress={() => {
              setSelectedType(HEALTH_SELECTION_TYPE);
            }}
          >
            Setup
          </PaperButton>
        </Card>

        <Card>
          <Card.Title h2>Location</Card.Title>
          <Card.Divider />
          <View style={{position:"relative",alignItems:"center"}}>
            <FontAwesome5 name="globe-americas" size={160} color="black" />
          </View>

          <Text style={styles.description}>
            Select a location and a radius, and automatically trigger your habit when you enter the area.
          </Text>

          <PaperButton
            onPress={() => {
              setSelectedType(LOCATION_SELECTION_TYPE);
            }}
            icon="earth"
            mode="contained"
          >
            Setup
          </PaperButton>
        </Card>

        <Card>
          <Card.Title h2>Weather</Card.Title>
          <Card.Divider />
          <View style={{position:"relative",alignItems:"center"}}>
            <MaterialCommunityIcons name="weather-pouring" size={LARGE_ICON_SIZE} color="black" />
          </View>
          <Text style={styles.description}>
            Select a location and weather pattern for automating habits.
          </Text>

          <PaperButton
            disabled
            icon="weather-pouring"
            mode="contained"
            onPress={() => {
              logger.info('implement me!');
              // setSelectedType(LOCATION_SELECTION_TYPE);
            }}
          >
            Setup (Coming Soon!)
          </PaperButton>
        </Card>
      </ScrollView>
    );
  }

  return (
    <View style={styles.container}>
      {getContent()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 24,
    marginLeft: 15,
    marginTop: 20,
    marginBottom: 10,
  },
  description: {
    marginVertical: 20,
    textAlign: 'center',
  },
});

export default AutomationCreate;

import React from "react";
import useSWR from "swr";

import { DASHBOARD_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import UrgencyGraph from "./UrgencyGraph";

interface Props {
  projects: any[],
}

export function UrgencyGraphContainer({
  selectedProject,
  projects = [],
}: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(
    `${DASHBOARD_URL}/urgency-over-time`,
    fetcher,
    { suspense: true,
  });

  return (
    <UrgencyGraph
      data={data.data}
      selectedProject={selectedProject}
      projects={projects}
    />
  );
}

export default UrgencyGraphContainer;

import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { StyleSheet } from "react-native";
import {
  Text,
  Switch,
} from 'react-native-paper';

import { View } from '@/components/Themed';

export function EditNotifications() {
  const shouldReceiveNotifications = useStoreState((state) => state.session.shouldReceiveNotifications);
  const setShouldReceiveNotifications = useStoreActions((actions) => actions.session.setShouldReceiveNotifications);

  return (
    <View style={styles.container}>
      <Text variant="labelLarge" style={styles.label}>Receive Notifications</Text>

      <Switch
        value={shouldReceiveNotifications}
        onValueChange={(val) => setShouldReceiveNotifications(val)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 30,
  },
  label: {
    marginBottom: 8,
  }
});

export default EditNotifications;

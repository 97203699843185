import React from "react";
import useSWR from "swr";

import { VICES_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ViceView from "./ViceView";

interface Props {
  viceId: number;
}

export function ViceViewContainer({ viceId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(`${VICES_URL}/${viceId}`, fetcher, {
    suspense: true,
  });

  if (!data) return;

  return <ViceView vice={data.vice} />;
}

export default ViceViewContainer;

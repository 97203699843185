import Config from "@/helpers/config";
import logger from "@/helpers/logger";

export const HABITS_URL = `${Config.apiUrl}/api/habits`;
export const OCCURRENCES_URL = `${Config.apiUrl}/api/occurrences`;
export const DASHBOARD_URL = `${Config.apiUrl}/api/dashboard`;
export const TAGS_URL = `${Config.apiUrl}/api/tags`;
export const VICES_URL = `${Config.apiUrl}/api/vices`;
export const INCIDENTS_URL = `${Config.apiUrl}/api/incidents`;
export const HEALTH_AUTOMATIONS_URL = `${Config.apiUrl}/api/health_automations`;
export const LOCATION_AUTOMATIONS_URL = `${Config.apiUrl}/api/location_automations`;
export const AUTOMATIONS_URL = `${Config.apiUrl}/api/automations`;
export const USERS_URL = `${Config.apiUrl}/api/users`;
export const PROJECTS_URL = `${Config.apiUrl}/api/projects`;

export const AUTH_URL = `${Config.apiUrl}/auth`;
export const SIGN_IN_URL = `${AUTH_URL}/sign_in`;
export const SIGN_OUT_URL = `${AUTH_URL}/sign_out`;
export const VALIDATE_URL = `${AUTH_URL}/validate_token`;
export const PASSWORD_URL = `${AUTH_URL}/password`;

export const request = async (url, options = {}) => {
  const { body, headers = {}, method = "GET" } = options;

  const args = {
    method,
    headers: {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (body) {
    args.body = JSON.stringify(body);
  }

  const response = await fetch(url, args);
  const text = await response.text();

  // Try to get some data from response
  let result;
  try {
    result = JSON.parse(text);
  } catch (error) {
    result = text;

    // TODO are these too noisy?
    logger.error("Unable to parse JSON", { error });
  }

  if (response.ok) {
    return {
      result,
      headers: Object.fromEntries(response.headers.entries()),
    };
  } else {
    const error = new Error("Error with request");
    error.result = result;
    error.statusCode = response.status;

    logger.error("Response non-ok", {
      error,
      extra: {
        status: response.status,
        statusText: response.statusText,
        result,
      },
    });

    throw error;
  }
};

export default request;

import React, { Suspense, useEffect } from "react";
import { StyleSheet } from "react-native";
import { useRoute } from "@react-navigation/native";

import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";
import HabitView from "@/components/HabitView/HabitView";
import HabitViewContainer from "@/components/HabitView";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { createOccurrence } from "@/helpers/api/occurrences";
import logger from "@/helpers/logger";

export default function HabitsViewScreen() {
  const requestAndSetTokens = useRequestAndSetTokens();
  const route = useRoute();
  const { id, action } = route.params;

  useEffect(() => {
    const handleAction = async () => {
      switch(action) {
        case 'createOccurrence': {
          await createOccurrence({
            habitId: id,
            occurredAt: new Date(),
            wasCreatedFromNfc: true,
            requestAndSetTokens,
          });

          break;
        }
        default: {
          logger.warn("action-not-recognized");
          // code block
        }
      }

      // Don't think we actually need this
      // navigation.replace("HabitView", { id });
    };

    if (action) {
      handleAction();
    }
  }, [action, id, requestAndSetTokens]);

  return (
    <View style={styles.container}>
      <BannerAd />

      <ErrorBoundary>
        <Suspense fallback={<HabitView isLoading />}>
          <HabitViewContainer habitId={id} />
        </Suspense>
      </ErrorBoundary>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

/* eslint-disable react/prop-types */

import React from "react";

import { Text, View } from "@/components/Themed";
import logger from "@/helpers/logger";

// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: undefined };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    logger.error("ErrorBoundary#componentDidCatch", {
      error,
      extra: {
        errorInfo,
      },
    });
  }

  render() {
    const { error, hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      if (fallback) return fallback;

      const stringifiedError = JSON.stringify(
        error,
        Object.getOwnPropertyNames(error),
      );

      return (
        <View>
          <Text>Oops, an error occurred:</Text>
          <Text>{stringifiedError}</Text>
        </View>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

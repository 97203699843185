import React from "react";
import useSWR from "swr";

import { TAGS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import TagSelector from "./TagSelector";

interface Props {
  tag: Record<string, unknown>;
  setTag: any;
}

export function TagSelectorContainer({ tag, setTag }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data, isValidating } = useSWR(TAGS_URL, fetcher, { suspense: true });

  if (!data) return;

  return (
    <TagSelector
      isValidating={isValidating}
      tags={data.tags}
      tag={tag}
      setTag={setTag}
    />
  );
}

export default TagSelectorContainer;


import React from "react";

import { Text } from "@/components/Themed";

export const MapSelector = () => {
  return (
    <Text>Maps are not currently supported on web.</Text>
  );
}

export default MapSelector;

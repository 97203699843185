import React from "react";
import useSWR from "swr";

import { PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ProjectSelector from "./ProjectSelector";

interface Props {
  project: Record<string, unknown>;
  setProject: any;
}

export function ProjectSelectorContainer(props: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data, isValidating } = useSWR(PROJECTS_URL, fetcher, { suspense: true });

  if (!data) return;

  return (
    <ProjectSelector
      {...props}
      isValidating={isValidating}
      projects={data.projects}
    />
  );
}

export default ProjectSelectorContainer;

import React from "react";
import { ActivityIndicator, Alert, Platform, StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import { mutate } from "swr";
import camelCase from "camelcase";
import { Button } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { LOCATION_AUTOMATIONS_URL } from "@/helpers/api";
import LocationAutomationForm from "@/components/LocationAutomationForm";
import logger from "@/helpers/logger";

interface Props {
  locationAutomation: any;
  isLoading?: boolean;
  existingTags: any[];
}

export function LocationAutomationEdit({ isLoading = false, locationAutomation }: Props) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();

  const onSubmit = async (
    formikValues,
    formikHelpers,
  ) => {
    selectionAsync();

    const {
      name,
      latitude,
      longitude,
      radiusInKilometers: radius_in_kilometers,
      shouldConfirm: should_confirm,
    } = formikValues;
    formikHelpers.setSubmitting(true);

    try {
      const url = `${LOCATION_AUTOMATIONS_URL}/${locationAutomation.id}`;
      await requestAndSetTokens(url, {
        method: "PATCH",
        body: {
          location_automation: {
            name,
            latitude,
            longitude,
            radius_in_kilometers,
            should_confirm
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Location automation updated! 😻",
        position: "bottom",
      });

      // Update all the cached stuff
      mutate(LOCATION_AUTOMATIONS_URL);

      navigation.goBack();
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        logger.error("Error updating location automation", { error: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        logger.error("Error updating location automation", { error });

        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while updating the location automation.",
          position: "bottom",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  React.useLayoutEffect(() => {
    if (!locationAutomation) return;

    const onDelete = async () => {
      selectionAsync();

      try {
        const url = `${LOCATION_AUTOMATIONS_URL}/${locationAutomation.id}`;
        await requestAndSetTokens(url, {
          method: "DELETE",
        });

        // Update all the cached stuff
        mutate(LOCATION_AUTOMATIONS_URL);

        Toast.show({
          type: "success",
          text1: "Success",
          text2: "location automation deleted! 🪦",
          position: "bottom",
        });

        navigation.goBack()
      } catch (error) {
        logger.error("Error deleting location automation", { error });
      }
    };

    const createAlert = () =>
      Alert.alert(
        "Delete Confirmation",
        `Are you sure you want to delete this location automation?`,
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          { text: 'Delete', onPress: onDelete },
        ],
      );

    navigation.setOptions({
      headerRight: () => (
        <Button type="clear" onPress={() => createAlert()}>
          <FontAwesome5 name="trash" size={30} style={{ color: "red" }} />
        </Button>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(locationAutomation), navigation, requestAndSetTokens]);

  if (isLoading) {
    return (
      <ActivityIndicator style={styles.loader} size="large" />
    );
  }

  return (
    <View style={styles.container}>
      <LocationAutomationForm
        onSubmit={onSubmit}
        initialValues={{
          name: locationAutomation.name,
          latitude: locationAutomation.latitude,
          longitude: locationAutomation.longitude,
          radiusInKilometers: locationAutomation.radius_in_kilometers,
          shouldConfirm: locationAutomation.should_confirm,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  loader: {
    marginTop: 100,
  },
});

export default LocationAutomationEdit;

import Toast from "react-native-toast-message";
import { mutate } from "swr";

import { OCCURRENCES_URL, HABITS_URL } from "./";

import { Habit } from './habits'

export interface Occurrence {
  id: number;
  habit_id: number;
}

export interface CreateOccurrenceDto {
  habitId: number;
  occurredAt: string;
  habits?: Habit[];
  occurredAt: string;
  requestAndSetTokens: any;
  wasCreatedFromNfc: boolean;
  healthAutomationId?: number;
  additionalData?: Record<any, unknown>;
}

export const createOccurrenceMutations = async ({ occurrence, habits } = {}) => {
  // Build a new habits array
  const updatedHabit = occurrence?.list_habit;

  if (updatedHabit && habits) {
    const updatedHabits = habits.map((h) => {
      return h.id === updatedHabit.id ? updatedHabit : h;
    });

    const payload = {
      habits: updatedHabits,
      updatedHabitId: updatedHabit.id,
      lastUpdated: Date.now(),
    };

    await mutate(HABITS_URL, payload, { revalidate: false });
  } else {
    await mutate(HABITS_URL);
  }

  // Always refresh the occurrences list
  // TODO: this can probably be manually revalidated with the returned occurrence
  mutate(OCCURRENCES_URL);
}

export const createOccurrence = async (dto: CreateOccurrenceDto): Promise<Occurrence> => {
  const {
    habitId,
    occurredAt,
    wasCreatedFromNfc,
    healthAutomationId,
    requestAndSetTokens,
    habits,
    additionalData = {},
  } = dto;

  const json = await requestAndSetTokens(OCCURRENCES_URL, {
    method: "POST",
    body: {
      occurrence: {
        habit_id: habitId,
        occurred_at: occurredAt,
        health_automation_id: healthAutomationId,
        was_created_from_nfc: wasCreatedFromNfc,
        ...additionalData,
      },
    },
  });

  const { occurrence } = json;

  if (!occurrence) {
    throw new Error("No occurrence was returned from the server!");
  }

  Toast.show({
    type: "success",
    text1: "Success",
    text2: "Habit completed! 😻",
    position: "bottom",
  });

  await createOccurrenceMutations({ occurrence, habits });

  return occurrence;
}

import React, { Suspense, useState, useCallback, useRef, useEffect } from 'react';
import {
  Button,
  Divider,
  Badge,
} from "@rneui/themed";
import { Portal } from "@gorhom/portal";
import { Keyboard, StyleSheet } from "react-native";
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import DropDownPicker from 'react-native-dropdown-picker';
import { MaterialIcons } from "@expo/vector-icons";
import Animated, { FadeInRight, FadeOutRight } from 'react-native-reanimated';
import { IconButton, TextInput } from 'react-native-paper';

import 'react-spring-bottom-sheet/dist/style.css'

import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import ProjectSelectorContainer from "@/components/ProjectSelector";
import ProjectSelector from "@/components/ProjectSelector/ProjectSelector";
import TagSelectorContainer from "@/components/TagSelector";
import TagSelector from "@/components/TagSelector/TagSelector";

interface Props {
  tag: any,
  onChangeTag: any,
  text: string,
  onChangeText: any,
  project: any,
  onChangeProject: any,
  sortOrder: string,
  onChangeSortOrder: any,
}

export function FilterAndSort({
  isFocused,
  tag,
  onChangeTag,
  text,
  onChangeText,
  project,
  onChangeProject,
  sortOrder,
  onChangeSortOrder,
}: Props) {
  // Sort
  const [open, setOpen] = useState(false);
  const [items] = useState([
    {
      label: "Time Consuming",
      value: "durationInMinutes",
    },
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Project",
      value: "project",
    }
  ]);
  const clearSearch = () => {
    onChangeTag?.();
    onChangeText?.();
    onChangeProject?.();
  }

  // Bottom sheet
  const sheetRef = useRef<BottomSheetRef>()
  const [sheetOpen, setSheetOpen] = useState(false)

  const handleClosePress = useCallback(() => {
    Keyboard.dismiss();
    setSheetOpen(false);
  }, [setSheetOpen]);

  const handleExpandPress = useCallback(() => {
    setSheetOpen(true);
  }, [setSheetOpen]);

  useEffect(() => {
    // Work-around so that clicking "Done" on the search component works
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setSheetOpen(false);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
    };
  }, []);

  // Badge count
  let filterCount = 0;
  if (tag) filterCount += 1;
  if (text) filterCount += 1;
  if (project) filterCount += 1;

  if (!isFocused) {
    return undefined;
  }

  return (
    <Portal>
      <BottomSheet
        open={sheetOpen}
        ref={sheetRef}
        snapPoints={({ maxHeight }) => maxHeight*0.8}
      >
        <View style={styles.contentContainer}>
          {onChangeText && (
            <TextInput
              label="Search"
              value={text}
              style={styles.textInput}
              returnKeyType="done"
              autoCapitalize="none"
              clearButtonMode="always"
              onChangeText={onChangeText}
              onSubmitEditing={handleClosePress}
            />
          )}

          {onChangeTag && (
            <View style={styles.tagSelectorContainer}>
              <ErrorBoundary fallback={undefined}>
                <Suspense fallback={<TagSelector isLoading />}>
                  <TagSelectorContainer tag={tag} setTag={onChangeTag} />
                </Suspense>
              </ErrorBoundary>
            </View>
          )}

          {onChangeProject && (
            <View style={styles.projectSelectorContainer}>
              <ErrorBoundary fallback={undefined}>
                <Suspense fallback={<ProjectSelector isLoading />}>
                  <ProjectSelectorContainer project={project} setProject={onChangeProject} />
                </Suspense>
              </ErrorBoundary>
            </View>
          )}

          {onChangeSortOrder && (
            <>
              <Divider />

              <View style={styles.sortRow}>
                <DropDownPicker
                  placeholder="Select a sort order"
                  listMode="FLATLIST"
                  dropDownDirection="TOP"
                  style={{
                    minHeight: 40,
                  }}
                  containerStyle={{
                    marginLeft: 20,
                    marginRight: 10,
                  }}
                  open={open}
                  value={sortOrder}
                  items={items}
                  setOpen={setOpen}
                  onSelectItem={({ value }) => onChangeSortOrder(value)}
                />

                <MaterialIcons
                  disabled={!sortOrder}
                  name="clear"
                  size={30}
                  color={sortOrder ? "red" : "grey"}
                  onPress={() => onChangeSortOrder()}
                />
              </View>
            </>
          )}

          <View style={styles.footerButtonContainer}>
            <Button
              type="clear"
              title="Reset"
              buttonStyle={styles.clearButtonContainer}
              titleStyle={styles.clearButtonText}
              onPress={() => clearSearch() }
            />

            <Button
              title="Done"
              onPress={() => handleClosePress() }
            />
          </View>
        </View>
      </BottomSheet>

      <Animated.View style={styles.buttonContainer} entering={FadeInRight} exiting={FadeOutRight}>
        <Button
          containerStyle={styles.searchButtonContainer}
          type="clear"
          onPress={handleExpandPress}
          clearButtonMode="while-editing"
        >
          <IconButton
            icon="magnify"
            size={30}
            selected
            mode='contained'
          />

          {filterCount > 0 && (
            <Badge
              status="error"
              value={filterCount}
              containerStyle={{ position: 'absolute', top: 10, left: 55 }}
            />
          )}
        </Button>
      </Animated.View>

    </Portal>
  );
}

const styles = StyleSheet.create({
  searchButtonContainer: {
    position: "absolute",
    bottom: 70,
    right: 10,
  },
  contentContainer: {
    padding: 40,
    justifyContent: 'space-between',
    // justifyContent: 'space-around',
    // paddingBottom: 10,
    // backgroundColor: "transparent",
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
  },
  textInput: {
    marginBottom: 40,
  },
  tagSelectorContainer:{
    marginBottom: 40,
  },
  projectSelectorContainer:{
    marginBottom: 40,
  },
  sortRow: {
    backgroundColor: "transparent",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 20,
    marginVertical: 40,
  },
  footerButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 10,
    backgroundColor: "transparent",
  },
  clearButtonText: {
    color: 'red',
  },
});

export default FilterAndSort;

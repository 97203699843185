import React from "react";
import { ActivityIndicator, useWindowDimensions, StyleSheet } from 'react-native';
import { Text as SvgText} from 'react-native-svg'
import { useNavigation } from "@react-navigation/native";
import { Pressable } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import Speedometer, {
  DangerPath,
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
  Indicator,
} from 'react-native-cool-speedometer';
import Rainbow from "rainbowvis.js";
import { useStoreState } from "easy-peasy";
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
} from 'victory-native';
import { useTheme } from 'react-native-paper';

import { View, Text } from "@/components/Themed";
import isEmpty from "@/helpers/isEmpty";
import {
  getDailyBandwidth,
  getDailyDurationInMinutes,
} from "@/helpers/get-daily-bandwidth";

// Gradient calculator
const rainbow = new Rainbow();
const RED = "A42A04";
const YELLOW = "FFBF00";
const GREEN = "008000";
rainbow.setSpectrum(RED, YELLOW, GREEN);
rainbow.setNumberRange(0, 100);

interface Props {
  projects: any[];
}

const getDailyDurantionByProject = (habits, projects) => {
  const results = {};

  for (const habit of habits) {
    if (!habit.project_id) continue;

    const project = projects.find(p => p.id === habit.project_id);

    if (!project) continue;

    const dailyDuration = getDailyDurationInMinutes(habit);

    if (results[project.name]) {
      results[project.name] += dailyDuration;
    } else {
      results[project.name] = dailyDuration;
    }
  }


  const entries = Object.entries(results).map(([projectName, value]) => {
    return {
      x: projectName,
      y: value,
    };
  });;

  return entries;
};

export function Dashboard({
  isLoading,
  selectedProject,
  projects = [],
  habits = [],
}: Props) {
  const theme = useTheme();
  const user = useStoreState((state) => state.session.user);
  const { width } = useWindowDimensions();
  const navigation = useNavigation();

  if (isLoading) {
    return (
      <ActivityIndicator size="large" />
    );
  };

  let selectedHabits = habits.filter(habit => !habit.discarded_at);

  if (selectedProject) {
    selectedHabits = selectedHabits
    .filter(habit => habit.project_id === selectedProject)
  }

  const completionRate = getCompletionRate(selectedHabits);
  const completionColor = `#${rainbow.colourAt(completionRate)}`;

  const bandwidth = getDailyBandwidth(selectedHabits);
  const bandwidthMax = user.average_daily_freetime_in_minutes;
  const numberOfTicks = 10;
  const totalAngle = 250;
  const dangerAngle = totalAngle * (user.bandwidth_buffer_percentage / 100);

  const speedometerWidth = (width - 40) / 2;
  const center = speedometerWidth / 2;

  return (
    <>
      <View style={styles.gauges}>
        <View>
          <View style={styles.bandwidthHeader}>
            <Text style={styles.header}>Bandwidth</Text>
            <Pressable
              onPress={() => {
                navigation.navigate("Settings", {
                  screen: "Profile",
                  initial: false,
                });
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}
            >
              <FontAwesome
                name="edit"
                size={20}
                color={theme.colors.primary}
              />
            </Pressable>
          </View>

          <Speedometer
            value={bandwidth}
            width={speedometerWidth}
            max={bandwidthMax}
          >
            <Background />
            <Arc arcWidth={4} />
            <Needle
              baseOffset={40}
              circleRadius={30}
            />
            <DangerPath angle={dangerAngle} />
            <Progress arcWidth={10} />
            <Marks step={Math.round(bandwidthMax / numberOfTicks)} />
            <Indicator>
              {(value, textProps) => (
                <SvgText
                  {...textProps}
                  fontSize={16}
                  fill="white"
                  x={center - 15}
                  y={center + 60}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                >
                  {value}{'  '}min
                </SvgText>
              )}
            </Indicator>
          </Speedometer>
        </View>

        <View>
          <Text style={styles.header}>Completion</Text>
          <Speedometer
            width={speedometerWidth}
            value={completionRate}
            max={100}
            angle={360}
            lineCap="round"
            accentColor={completionColor}
          >
            <Arc arcWidth={20} />
            <Progress arcWidth={20} />
            <Indicator>
              {(value, textProps) => (
                <SvgText
                  {...textProps}
                  fontSize={30}
                  fill={completionColor}
                  x={center - 15}
                  y={center + 5}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                >
                  {value}%
                </SvgText>
              )}
            </Indicator>
          </Speedometer>
        </View>
      </View>

      <View style={styles.barGraphContainer}>
        <Text style={styles.header}>Duration by Project</Text>
        <VictoryChart
          padding={{ left: 60, top: 50, right: 60, bottom: 60 }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            dependentAxis
          />
          <VictoryAxis
            style={{tickLabels: {angle: 45}}}
          />
          <VictoryBar horizontal data={getDailyDurantionByProject(habits, projects)} />
        </VictoryChart>
      </View>
    </>
  );
}

const getCompletionRate = (habits) => {
  const strictHabits = habits.filter(h => h.strict);
  const fulfilledHabits = strictHabits.filter(h => h.fulfilled);

  if (isEmpty(strictHabits)) return 100;

  return (fulfilledHabits.length / strictHabits.length) * 100;
}

const styles = StyleSheet.create({
  gauges: {
    marginTop: 10,
    marginHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    textAlign: 'center',
    marginBottom: 5,
    fontSize: 20,
  },
  bandwidthHeader: {
    marginHorizontal: 25,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  },
  barGraphContainer: {
    marginHorizontal: 10,
    marginTop: 20,
  }
});

export default Dashboard;

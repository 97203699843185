/* eslint-disable react/prop-types */

import React from "react";
import { StyleSheet } from "react-native";
import { Button, Input } from "@rneui/themed";
import { useStoreActions } from "easy-peasy";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";

import { View, Text } from "@/components/Themed";
import { logger } from "@/helpers/logger";

export function AccountDeletionScreen() {
  const navigation = useNavigation();
  const [confirmation, setConfirmation] = React.useState("");
  const message = "Delete my account and data";

  const deleteAccountAction = useStoreActions(
    (actions) => actions.session.deleteAccount,
  );

  const deleteAccount = async () => {
    try {
      await deleteAccountAction();
      Toast.show({
        type: "success",
        text1: "Account deleted",
        text2: "Thanks for giving us a try!",
        position: "bottom",
      });
      navigation.navigate("SignIn");
    } catch (error) {
      const errors = error?.result?.errors;

      logger.error("unable-to-delete-account", { error });

      if (errors) {
        for (const e of errors) {
          Toast.show({
            type: "error",
            text1: "Whoops",
            text2: e,
          });
        }
      } else {
        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred deleting your account.",
          position: "bottom",
        });
      }
    }
  };

  return (
    <View style={styles.container}>
      <Text
        style={{
          marginBottom: 20,
          marginLeft: 10,
        }}
      >
        Type &apos;{message}&apos; to confirm
      </Text>
      <Input
        value={confirmation}
        onChangeText={setConfirmation}
        autoCapitalize="none"
      />

      <View
        style={{
          paddingHorizontal: 20,
          marginBottom: 20,
        }}
      >
        <Button
          title="Delete Account"
          color="error"
          disabled={confirmation !== message}
          onPress={() => deleteAccount()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 40,
    flex: 1,
  },
});

export default AccountDeletionScreen;

import React from "react";
import { Button } from "@rneui/themed";

interface Props {
  disabled: boolean;
  onPress: () => void;
}

export const CoolButton = ({
  disabled,
  onPress,
}: Props) => {
  return (
    <Button
      type="primary"
      disabled={disabled}
      onPress={async (next) => await onPress(next)}
      title="Purchase"
    />
  );
}

export default CoolButton;

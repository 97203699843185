import React from "react";
import useSWR from "swr";

import { TAGS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import HabitCreate from "./HabitCreate";

export function HabitCreateContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data, mutate } = useSWR(TAGS_URL, fetcher, { suspense: true });

  if (!data) return;

  return <HabitCreate mutate={mutate} existingTags={data.tags} />;
}

export default HabitCreateContainer;

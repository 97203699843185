import React from "react";

import ProjectForm from "./ProjectForm";

interface Props {
  onSubmit: any;
  initialValues: Record<string, unknown>;
}

export function ProjectFormContainer({ onSubmit, initialValues }: Props) {
  return (
    <ProjectForm
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
}

export default ProjectFormContainer;

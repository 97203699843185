import React from "react";
import useSWR from "swr";

import { LOCATION_AUTOMATIONS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import LocationAutomationEdit from "./LocationAutomationEdit";

interface Props {
  locationAutomationId: any;
}

export function LocationAutomationEditContainer({ locationAutomationId }: Props) {
  const fetcher = useRequestAndSetTokens();

  const { data } = useSWR(`${LOCATION_AUTOMATIONS_URL}/${locationAutomationId}`, fetcher, { suspense: true });

  if (!data) return;

  return <LocationAutomationEdit locationAutomation={data.location_automation} />;
}

export default LocationAutomationEditContainer;

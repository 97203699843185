import React from "react";
import { useSWRConfig } from "swr";
import { StyleSheet, Platform } from "react-native";
import Toast from "react-native-toast-message";
import camelCase from "camelcase";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import { View } from "@/components/Themed";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { PROJECTS_URL } from "@/helpers/api";
import ProjectForm from "@/components/ProjectForm";
import logger from "@/helpers/logger";

export function ProjectCreate() {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();
  const { mutate } = useSWRConfig();

  const onSubmit = async (
    {
      name,
      colorHex: color_hex,
      icon,
    },
    formikHelpers,
  ) => {
    selectionAsync();
    formikHelpers.setSubmitting(true);

    try {
      await requestAndSetTokens(PROJECTS_URL, {
        method: "POST",
        body: {
          project: {
            name,
            color_hex,
            icon,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Project created! 😻",
        position: "bottom",
      });

      mutate(PROJECTS_URL);

      navigation.goBack()
    } catch (error) {
      const errors = error?.result?.errors;
      let errorMessage = "An error occurred while updating the project.";

      if (errors) {
        logger.error("Error creating project", { error, errors: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        errorMessage = Object
          .entries(formikErrors)
          .map(e => e.join(' '))
          .join(', ');

        formikHelpers.setErrors(formikErrors);
      }

      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: errorMessage,
        position: "bottom",
      });

      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <View style={styles.container}>
      <ProjectForm onSubmit={onSubmit} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "android" ? 120 : 10,
  },
});

export default ProjectCreate;

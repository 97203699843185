export const timesPhrase = (count: number): string => {
  switch(count) {
    case 1: {
      return "Once";
    }
    case 2: {
      return "Twice";
    }
    case 3: {
      return "Thrice";
    }
    default: {
      return `${count} times`;
    }
  }

};

export default timesPhrase;

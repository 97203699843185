export const headerTokens = (headers) => {
  return {
    "access-token": headers["access-token"],
    "token-type": "Bearer",
    client: headers.client,
    expiry: headers.expiry,
    uid: headers.uid,
  };
};

export default headerTokens;

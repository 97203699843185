/* eslint-disable no-console */

import * as Sentry from "sentry-expo";
import Constants from "expo-constants";

import RingBuffer from "@/helpers/ring-buffer";

export enum LogLevel {
  Info = "INFO",
  Warn = "WARN",
  Error = "ERROR",
}

// Only store the last 10,000 lines of log
const LOG_LIMIT = 10000;

export const ringBuffer = new RingBuffer<number>(LOG_LIMIT);

export const logger = {
  info: (message, payload = {}) => {
    const timestamp = new Date().toISOString();
    ringBuffer.add({ timestamp, message, payload, level: LogLevel.Info });

    console.log(message, payload);
  },
  warn: (message, payload = {}) => {
    const timestamp = new Date().toISOString();
    ringBuffer.add({ timestamp, message, payload, level: LogLevel.Warn });

    console.warn(message, payload);
  },
  error: (message, payload = {}) => {
    const { error, ...extra } = payload;

    if (!error) {
      throw new Error("logger#error must be given 'error' in payload");
    }

    const timestamp = new Date().toISOString();
    ringBuffer.add({ timestamp, message, payload, level: LogLevel.Error });

    if (Constants?.expoConfig?.extra?.isDevelopment) {
      console.error(message, error.message, error.stack, JSON.stringify(extra, undefined, 4));
    } else {
      Sentry.Native.captureException(error, extra);
    }
  },
};

export default logger;

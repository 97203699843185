import React from "react";
import useSWR from "swr";

import { HABITS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import AverageUrgency from "./AverageUrgency";

export function AverageUrgencyContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(HABITS_URL, fetcher, { suspense: true });

  return <AverageUrgency habits={data.habits} />;
}

export default AverageUrgencyContainer;

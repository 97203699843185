import React, { useState } from "react";
import { ActivityIndicator, Alert, Platform, StyleSheet } from "react-native";
import { StatusBar } from "expo-status-bar";
import { Picker } from "@react-native-picker/picker";
import { Button as PaperButton } from "react-native-paper";
import { Button } from "@rneui/themed";
import DateTimePicker, {
  DateTimePickerAndroid,
} from "@react-native-community/datetimepicker";
import { mutate } from "swr";
import Toast from "react-native-toast-message";
import { FontAwesome5 } from "@expo/vector-icons";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";

import { Text, View } from "@/components/Themed";
import { INCIDENTS_URL, VICES_URL } from "@/helpers/api";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import useColorScheme from "@/hooks/useColorScheme";
import { logger } from "@/helpers/logger";
import { selectionAsync } from "@/helpers/haptics";

interface Props {
  incident: Record<string, unknown>;
  vices: any[];
}

function LoadedIncidentEdit({ incident, vices }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const requestAndSetTokens = useRequestAndSetTokens();
  const [occurredAt, setOccurredAt] = useState(new Date(incident.occurred_at));

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate;
    setOccurredAt(currentDate);
  };
  const showMode = (currentMode) => {
    DateTimePickerAndroid.open({
      value: occurredAt,
      onChange,
      mode: currentMode,
      is24Hour: true,
    });
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };

  React.useLayoutEffect(() => {
    const onDelete = async () => {
      selectionAsync();

      try {
        await requestAndSetTokens(`${INCIDENTS_URL}/${incident.id}`, {
          method: "DELETE",
        });

        navigation.goBack();
        mutate(INCIDENTS_URL);
        mutate(VICES_URL);
        Toast.show({
          type: "success",
          text1: "Success",
          text2: "Incident deleted! 🪦",
          position: "bottom",
        });
      } catch (error) {
        logger.error("Error deleting incident", { error });
      }
    };

    const createAlert = () =>
      Alert.alert(
        "Delete Confirm",
        "Are you sure you want to delete this incident?",
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          { text: "Delete", onPress: onDelete },
        ],
      );

    navigation.setOptions({
      title: "Edit Incident",
      headerRight: () => (
        <Button type="clear" onPress={createAlert}>
          <FontAwesome5 name="trash" size={30} style={{ color: "red" }} />
        </Button>
      ),
    });
  }, [navigation, incident, requestAndSetTokens]);

  const getWhetherDisabled = () => {
    const existingDate = new Date(incident.occurred_at);
    existingDate.setSeconds(0, 0);

    const newDate = new Date(occurredAt);
    newDate.setSeconds(0, 0);

    return existingDate.getTime() === newDate.getTime();
  };

  const onEdit = async () => {
    selectionAsync();

    try {
      setIsLoading(true);

      await requestAndSetTokens(`${INCIDENTS_URL}/${incident.id}`, {
        method: "PATCH",
        body: {
          incident: {
            occurred_at: occurredAt,
          },
        },
      });

      mutate(INCIDENTS_URL);
      mutate(VICES_URL);

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Incident updated! 🚀",
        position: "bottom",
      });

      setIsLoading(false);

      navigation.navigate("TimelineList");
    } catch (error) {
      setIsLoading(false);

      logger.error("Error updating incident", { error });
    }
  };

  const pickerStyle = {};
  if (colorScheme === "dark") {
    pickerStyle.backgroundColor = "white";
  }

  return (
    <View style={styles.container}>
      <Text style={styles.label}>Vice:</Text>

      <Picker
        selectedValue={incident.vice.id}
        enabled={false}
        style={pickerStyle}
      >
        {vices.map((vice) => (
          <Picker.Item key={vice.id} label={vice.name} value={vice.id} />
        ))}
      </Picker>

      <Text style={styles.label}>Occurred At:</Text>

      <View style={pickerStyle}>
        <View style={styles.pickerContainer}>
          {Platform.OS === "ios" ? (
            <DateTimePicker
              mode="datetime"
              value={occurredAt}
              onChange={(event, newValue) => setOccurredAt(newValue)}
            />
          ) : (
            <>
              <View style={{ margin: 10 }}>
                <Button
                  type="outline"
                  onPress={showDatepicker}
                  title={format(occurredAt, "PPP")}
                />
              </View>

              <View style={{ margin: 10 }}>
                <Button
                  type="outline"
                  onPress={showTimepicker}
                  title={format(occurredAt, "p")}
                />
              </View>
            </>
          )}
        </View>
      </View>

      <PaperButton
        mode="contained"
        style={styles.button}
        disabled={getWhetherDisabled()}
        loading={isLoading}
        onPress={onEdit}
      >
        Submit
      </PaperButton>

      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

interface Props {
  incident: any;
  isLoading: boolean;
  vices: any[];
}

export default function IncidentEdit({ incident, vices, isLoading }: Props) {
  if (isLoading) {
    return <ActivityIndicator size="large" />;
  }

  return <LoadedIncidentEdit incident={incident} vices={vices} />;
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  label: {
    fontSize: 20,
  },
  pickerContainer: {
    backgroundColor: "transparent",
    marginRight: 50,
    marginTop: 20,
    marginBottom: 50,
  },
  button: {
    marginTop: 20,
  },
});

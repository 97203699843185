import React from "react";
import useSWR from "swr";

import { HABITS_URL, OCCURRENCES_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import OccurrenceEdit from "./OccurrenceEdit";

interface Props {
  occurrenceId: number;
}

export function OccurrenceEditContainer({ occurrenceId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data: habitsData } = useSWR(HABITS_URL, fetcher, {
    suspense: true,
  });

  const { data: occurrenceData } = useSWR(
    `${OCCURRENCES_URL}/${occurrenceId}`,
    fetcher,
    {
      suspense: true,
    },
  );

  return (
    <OccurrenceEdit
      occurrence={occurrenceData.occurrence}
      habits={habitsData.habits}
    />
  );
}

export default OccurrenceEditContainer;

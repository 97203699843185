import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome5 } from "@expo/vector-icons";

import Colors from "@/constants/Colors";
import { Text, View } from "@/components/Themed";
import useColorScheme from "@/hooks/useColorScheme";

interface Props {
  incident: any;
}

export default function IncidentCard({ incident }: Props) {
  const navigation = useNavigation();
  const colorScheme = useColorScheme();

  const onEdit = () => {
    navigation.navigate("Timeline", {
      screen: "IncidentEdit",
      initial: false,
      params: { id: incident.id },
    });
  };

  return (
    <TouchableOpacity onPress={onEdit}>
      <View
        style={[
          styles.card,
          { backgroundColor: Colors[colorScheme].cardBackground },
        ]}
      >
        <View style={styles.nameContainer}>
          <Text style={styles.name}>{incident.vice.name}</Text>
          <Text style={styles.datetime}>
            {format(new Date(incident.occurred_at), "pp")}
          </Text>
        </View>

        {!!incident?.habit?.discarded_at && (
          <View style={styles.iconContainer}>
            <FontAwesome5 name="inbox" size={30} />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  nameContainer: {
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  iconContainer: {
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  name: {
    paddingRight: 20,
    fontSize: 18,
  },
  datetime: {
    fontStyle: "italic",
    fontSize: 12,
  },
  card: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    textAlign: "center",
    fontSize: 20,
    padding: 20,
    borderLeftWidth: 5,
    borderLeftColor: "red",
    borderRadius: 10,
  },
});

import React, { Suspense, useState } from "react";
import { useSWRConfig } from "swr";
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { format, formatDuration, formatRelative, intervalToDuration } from "date-fns";
import { Button } from "@rneui/themed";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from 'react-native-paper';

import { selectionAsync } from "@/helpers/haptics";
import Colors from "@/constants/Colors";
import { createOccurrence } from "@/helpers/api/occurrences";
import useColorScheme from "@/hooks/useColorScheme";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { FontAwesome, Text, View } from "@/components/Themed";
import { getFrequencyText } from "@/components/HabitList/HabitCard";
import OccurrencesData from "@/components/OccurrencesData";
import Anchor from "@/components/Anchor";
import Tabs from "@/components/Tabs";
import ErrorBoundary from "@/components/ErrorBoundary";
import Timeline from "@/components/Timeline/Timeline";
import TimelineContainer from "@/components/Timeline";
import { getTodoIcon } from "@/components/HabitList/HabitCard";
import logger from "@/helpers/logger";

import AutomationList from "./AutomationList";

interface Props {
  habit: any;
  isLoading?: boolean;
}

export function HabitView({
  isLoading = false,
  habit,
}: Props) {
  const theme = useTheme();
  const requestAndSetTokens = useRequestAndSetTokens();
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const [isFetching, setIsFetching] = useState(false);
  const { mutate } = useSWRConfig();

  // For completing the habit
  const onComplete = async () => {
    selectionAsync();
    setIsFetching(true);

    try {
      await createOccurrence({
        habitId: habit.id,
        occurredAt: new Date(),
        requestAndSetTokens,
        mutate,
      });

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);

      logger.error("Error completing habit", { error });
    }
  };

  React.useLayoutEffect(() => {
    if (habit) {
      navigation.setOptions({
        headerTitle: habit.name,
      });
    }

    navigation.setOptions({
      headerRight: () => (
        <Button
          type="clear"
          onPress={() => navigation.navigate("HabitEdit", { id: habit.id })}
        >
          <FontAwesome
            name="edit"
            size={30}
            style={{ color: theme.colors.primary }}
          />
        </Button>
      ),
    });
  }, [navigation, habit, colorScheme, theme.colors.primary]);

  if (isLoading) {
    return (
      <View style={{ marginTop: 50 }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Tabs
        data={[
          {
            key: "data",
            title: "Data",
            scene: () => (
              <ScrollView>
                <View style={styles.topSection}>
                  <TouchableOpacity
                    disabled={isFetching}
                    style={styles.buttonContainer}
                    onPress={async () => await onComplete()}
                  >
                    <FontAwesome
                      size={30}
                      name={getTodoIcon(habit, isFetching)}
                      style={[styles.button, { color: Colors[colorScheme].text }]}
                    />
                  </TouchableOpacity>

                  <View>
                    {habit.discarded_at && (
                      <>
                        <Text style={styles.subtitleArchived}>
                          <FontAwesome name="inbox" size={20} style={{ color: "red", marginRight: 20 }} />
                          &nbsp;Archived On: {format(new Date(habit.discarded_at), "yyyy-MM-dd")}
                        </Text>
                      </>
                    )}

                    <Text style={styles.subtitle}>
                      Frequency: {getFrequencyText(habit)}
                    </Text>

                    <Text style={styles.subtitle}>
                      Next Due: {"   "}{habit.next_due_date ? formatRelative(new Date(habit.next_due_date), new Date()) : 'n/a'}
                    </Text>

                    {habit.tags.length > 0 && (
                      <Text style={styles.subtitle}>
                        Tags:{"           "}
                        {habit.tags.map((tag) => `#${tag.name}`).join(", ")}
                      </Text>
                    )}

                    <Text style={styles.subtitle}>
                      Type: {"          "}{habit.strict ? "Strict" : "Flexible"},{" "}
                      {habit.from_period_start
                        ? "Periodic"
                        : "Rolling"}
                    </Text>

                    {habit.timeliness_duration_in_seconds && (
                      <Text style={styles.subtitle}>
                        Timely for: {" "}{formatDuration(intervalToDuration({ start: 0, end: habit.timeliness_duration_in_seconds * 1000 }), { delimiter: ', ' })}
                      </Text>
                    )}

                    {habit.link && (
                      <Text
                        style={[
                          styles.subtitle,
                          { textDecorationLine: "underline" },
                        ]}
                      >
                        <Anchor href={habit.link}>{habit.link}</Anchor>
                      </Text>
                    )}
                  </View>
                </View>

                <View>
                  <OccurrencesData
                    total={habit.occurrences.length}
                    secondTitle="2x Period"
                    secondValue={habit.two_period_trend}
                    thirdTitle="4x Period"
                    thirdValue={habit.four_period_trend}
                    occurrences={habit.occurrences}
                    showStreaks={getShowStreaks(habit)}
                    longestStreak={getLongestStreak(habit)}
                    currentStreak={getCurrentStreak(habit)}
                  />
                </View>
              </ScrollView>
            ),
          },
          {
            key: "recent",
            title: "Recent",
            scene: () => (
              <View style={styles.recent}>
                <ErrorBoundary>
                  <Suspense fallback={<Timeline isLoading />}>
                    <TimelineContainer
                      habitParams={{
                        habit_id: habit.id,
                        limit: 5,
                      }}
                    />
                  </Suspense>
                </ErrorBoundary>
              </View>
            ),
          },
          {
            key: 'automations',
            title: 'Automations',
            scene: () => (
              <>
                <AutomationList
                  habit={habit}
                />
              </>
            ),
          },
        ]}
      />
    </View>
  );
}

const getShowStreaks = (habit) => {
  return habit.frequency_period === "day" && habit.frequency_number === 1;
};

const getLongestStreak = (habit) => {
  let longestStreak = 0;

  if (getShowStreaks(habit)) {
    const max = Math.max(...habit.streaks.map((o) => o.consecutivedates), 0);

    if (max) longestStreak = max;
  }

  return longestStreak;
};

const getCurrentStreak = (habit) => {
  let currentStreak = 0;

  if (getShowStreaks(habit)) {
    const today = format(new Date(), "yyyy-MM-dd");
    const current = habit.streaks.find((streak) => streak.maxdate === today);

    if (current) {
      currentStreak = current.consecutivedates;
    }
  }

  return currentStreak;
};

const styles = StyleSheet.create({
  container: {
    marginTop: Platform.OS === "android" ? 120 : 0,
    flex: 1,
  },
  topSection: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  subtitle: {
    fontSize: 16,
    marginTop: 10,
  },
  subtitleArchived: {
    fontSize: 20,
    textAlign: "center",
    marginTop: 10,
  },
  recent: {
    padding: 10,
  },
  buttonContainer: {
    marginHorizontal: 20,
    justifyContent: "center",
  },
});

export default HabitView;

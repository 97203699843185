import React from "react";
import { useSWRConfig } from "swr";
import { StyleSheet, Platform } from "react-native";
import Toast from "react-native-toast-message";
import camelCase from "camelcase";
import { useNavigation } from "@react-navigation/native";

import { View } from "@/components/Themed";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { TAGS_URL, VICES_URL } from "@/helpers/api";
import { selectionAsync } from "@/helpers/haptics";
import ViceForm from "@/components/ViceForm";

interface Props {
  existingTags: any[];
}

export function ViceCreate({ existingTags }: Props) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();
  const { mutate } = useSWRConfig();

  // For completing the vice
  const onSubmit = async ({ name, icon }, formikHelpers) => {
    selectionAsync();
    formikHelpers.setSubmitting(true);

    try {
      const json = await requestAndSetTokens(VICES_URL, {
        method: "POST",
        body: {
          vice: {
            name,
            icon,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Vice created! 😻",
        position: "bottom",
      });

      mutate(TAGS_URL);
      mutate(VICES_URL);

      navigation.navigate("ViceView", { id: json.vice.id });
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while updating the vice.",
          position: "bottom",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <View style={styles.container}>
      <ViceForm onSubmit={onSubmit} existingTags={existingTags} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingTop: Platform.OS === "android" ? 120 : 10,
  },
});

export default ViceCreate;

import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState, useStoreRehydrated } from "easy-peasy";
import { Platform } from "react-native";
import Purchases from "react-native-purchases";

import Config from "@/helpers/config";
import logger from "@/helpers/logger";
import storage from "@/helpers/storage-general";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const isRehydrated = useStoreRehydrated();
  const user = useStoreState((state) => state.session.user);
  const setHasCompletedOnboarding = useStoreActions(
    (actions) => actions.general.setHasCompletedOnboarding,
  );
  const validateToken = useStoreActions(
    (actions) => actions.session.validateToken,
  );
  const setCustomerInfo = useStoreActions(
    (actions) => actions.general.setCustomerInfo,
  );

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,

          // eslint-disable-next-line unicorn/prefer-module
          "space-mono": require("../assets/fonts/SpaceMono-Regular.ttf"),
        });

        // Set up onboarding state
        const hasCompletedOnboarding = await storage.get("hasCompletedOnboarding");
        setHasCompletedOnboarding(hasCompletedOnboarding);

        if (user && Platform.OS !== 'web') {
          // Make sure the token is still valid
          await validateToken();

          // Set up RevenueCat
          Purchases.setDebugLogsEnabled(true);

          if (Platform.OS === "ios") {
            await Purchases.configure({
              apiKey: Config.revenueCat.iosKey,
              useAmazon: false,
              appUserID: user.revenue_cat_app_user_id,
            });
          } else if (Platform.OS === "android") {
            await Purchases.configure({
              apiKey: Config.revenueCat.androidKey,
              useAmazon: false,
              appUserID: user.revenue_cat_app_user_id,
            });
          }

          if (Platform.OS !== 'web') {
            const { purchaserInfo } = await Purchases.logIn(
              user.revenue_cat_app_user_id,
            );

            setCustomerInfo(purchaserInfo);
          }
        }
      } catch (error) {
        // We might want to provide this error information to an error reporting service
        logger.error("Error loading revenue cat", { error });
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    if (isRehydrated) {
      loadResourcesAndDataAsync();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRehydrated, setHasCompletedOnboarding, user?.id, validateToken]);

  return isLoadingComplete;
}

import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Card, SegmentedButtons, TextInput } from 'react-native-paper';

import { ScrollView, Text } from "@/components/Themed";
import { ringBuffer, LogLevel } from "@/helpers/logger";

export function ViewLogs() {
  const [value, setValue] = useState();
  const [text, setText] = useState('');

  let ringBufferArray = ringBuffer.toArray();

  if (value) {
    ringBufferArray = ringBufferArray.filter(log => log.level === value);
  }

  if (text) {
    ringBufferArray = ringBufferArray.filter(log => log.message.toLowerCase().match(text.toLowerCase()));
  }

  return (
    <ScrollView style={styles.container}>
      <TextInput
        label="Search"
        autoCapitalize="words"
        value={text}
        onChangeText={setText}
        style={{ marginBottom: 10 }}
      />

      <SegmentedButtons
        value={value}
        onValueChange={setValue}
        style={{ marginBottom: 10 }}
        buttons={[
          {
            value: LogLevel.Info,
            label: 'Info',
          },
          {
            value: LogLevel.Warn,
            label: 'Warn',
          },
          {
            value: LogLevel.Error,
            label: 'Error',
          },
        ]}
      />
      {ringBufferArray.map((log, index) => (
        <Card key={index} style={styles.card}>
          <Card.Content>
            <Text variant="bodyMedium">
              {JSON.stringify(log, null, 2)}
            </Text>
          </Card.Content>
        </Card>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    flex: 1,
  },
  card: {
    marginBottom: 20,
  }
});

export default ViewLogs;

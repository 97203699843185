import React, { useState, useEffect } from "react";
import {
  ActivityIndicator,
  FlatList,
  Alert,
  Platform,
  StyleSheet,
} from "react-native";
import Toast from "react-native-toast-message";
import { useStoreActions } from "easy-peasy";
import { Ionicons } from "@expo/vector-icons";
import Purchases from "react-native-purchases";
import { Button } from "@rneui/themed";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import Config from "@/helpers/config";
import { Text, View } from "@/components/Themed";
import Anchor from "@/components/Anchor";
import logger from "@/helpers/logger";
import CoolButton from "@/components/CoolButton";

import { PackageItem } from "./PackageItem";

export function Upgrade() {
  const navigation = useNavigation();

  const setCustomerInfo = useStoreActions(
    (actions) => actions.general.setCustomerInfo,
  );

  // - State for all available package
  const [packages, setPackages] = useState([]);

  // - State for selecting packages
  const [selectedPackage, setSelectedPackage] = useState();

  // - State for whether loading
  const [isLoading, setIsLoading] = useState(true);

  // - State for whether loading
  const [isRestoring, setIsRestoring] = useState(false);

  const selectedPackageRef = React.useRef(selectedPackage); // <-- ref to cache num state
  React.useEffect(() => {
    // <-- effect to update cache
    selectedPackageRef.current = selectedPackage;
  }, [selectedPackage]);

  const onSelect = (item) => {
    selectionAsync();
    setSelectedPackage(item);
  };

  React.useLayoutEffect(() => {
    const onRestorePurchase = async () => {
      try {
        setIsRestoring(true);
        const customerInfo = await Purchases.restorePurchases();
        const noSubscription = !customerInfo?.entitlements.active[Config.revenueCat.entitlementId];

        if (noSubscription) {
          Toast.show({
            type: "error",
            text1: "Whoops",
            text2: "No active subscription was found.",
            position: "bottom",
          });
        } else {
          setCustomerInfo(customerInfo);
        }
      } catch (error) {
        logger.error("Oops, an error occurred while restoring purchases", {
          error,
        });
      } finally {
        setIsRestoring(false);
      }
    };

    navigation.setOptions({
      headerRight: () => (
        <Button
          titleStyle={{ fontSize: 12 }}
          type="outline"
          title="Restore Purchases"
          onPress={() => onRestorePurchase()}
          disabled={isRestoring}
          loading={isRestoring}
        />
      ),
    });
  }, [isRestoring, navigation, setCustomerInfo]);

  useEffect(() => {
    // Get current available packages
    const getPackages = async () => {
      try {
        const offerings = await Purchases.getOfferings();
        if (
          offerings.current !== null &&
          offerings.current.availablePackages.length > 0
        ) {
          setPackages(offerings.current.availablePackages);
        }
      } catch (error) {
        Alert.alert("Error getting offers", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getPackages();
  }, []);

  const sortedPackages = [...packages].sort(
    (a, b) => b.product.price - a.product.price,
  );

  return (
    <View style={styles.container}>
      <Ionicons
        style={styles.headerImage}
        name="rocket-outline"
        size={80}
        color="black"
      />

      <View style={styles.container}>
        {isLoading ? (
          <View style={styles.loader}>
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <>
            <FlatList
              contentContainerStyle={styles.list}
              data={sortedPackages}
              showsHorizontalScrollIndicator={false}
              horizontal
              renderItem={({ item }) => (
                <PackageItem
                  purchasePackage={item}
                  onSelect={onSelect}
                  isSelected={selectedPackage?.identifier === item.identifier}
                />
              )}
              keyExtractor={(item) => item.identifier}
            />

            <View style={styles.buttonContainer}>
              <CoolButton
                disabled={!selectedPackage}
                onPress={async (next) => {
                  try {
                    const { customerInfo } = await Purchases.purchasePackage(
                      selectedPackageRef.current,
                    );

                    setCustomerInfo(customerInfo);
                  } catch (error) {
                    if (!error.userCancelled) {
                      Alert.alert("Error purchasing package", error.message);
                    }
                  } finally {
                    setIsLoading(false);
                    next();
                  }
                }}
              />
            </View>

            <Text style={styles.text}>
              Recurring billing. Cancel anytime. A purchase will be applied to
              your account at the end of the trial or on confirmation.
              Subscriptions will automatically renew unless canceled within
              24-hours before the end of the current period. You can cancel
              anytime with your iTunes or Play account settings. Any unused
              portion of a free trial will be forfeited if you purchase a
              subscription. For more information, see our{" "}
              <Anchor
                style={styles.anchor}
                href="https://www.the-quantified-self.com/terms"
              >
                Terms of Service
              </Anchor>{" "}
              and{" "}
              <Anchor
                style={styles.anchor}
                href="https://www.the-quantified-self.com/privacy"
              >
                Privacy Policy
              </Anchor>
              .
            </Text>
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "android" ? 120 : 0,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  buttonContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    justifyContent: "center",
  },
  headerImage: {
    marginTop: 20,
    marginBottom: 15,
    textAlign: "center",
  },
  list: {
    marginHorizontal: -10,
  },
});

export default Upgrade;

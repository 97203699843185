import React from "react";
import useSWR from "swr";
import { ActivityIndicator, StyleSheet } from "react-native";
import { useStoreState } from "easy-peasy";
import {
  Button,
  Card,
} from 'react-native-paper';

import handleNewLocations from "@/helpers/handle-new-locations";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { ScrollView, Text } from "@/components/Themed";
import { LOCATION_AUTOMATIONS_URL } from "@/helpers/api";

export function ViewLocationSimulator() {
  const fetcher = useRequestAndSetTokens();
  const user = useStoreState((state) => state.session.user);
  const tokens = useStoreState((state) => state.session.tokens);

  const { data } = useSWR(LOCATION_AUTOMATIONS_URL, fetcher);

  if (!data) {
    return <ActivityIndicator size="large" />;
  }

  return (
    <ScrollView style={styles.container}>
      {data.location_automations.map(automation => (
        <Card key={automation.id}>
          <Card.Content>
            <Text variant="bodyMedium">
              {JSON.stringify(automation, null, 2)}
            </Text>
          </Card.Content>
          <Card.Actions>
            <Button onPress={async () => {
              await handleNewLocations({
                user,
                data: {
                  locations: [
                    {
                      coords: {
                        accuracy: 35,
                        altitude: 23,
                        altitudeAccuracy: 8,
                        heading: -1,
                        latitude: automation.latitude,
                        longitude: automation.longitude,
                        speed: -1,
                      },
                      timestamp: Date.now(),
                    }
                  ],
                },
                tokens,
              });
            }}>
              Simulate
            </Button>
          </Card.Actions>
        </Card>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    flex: 1,
  },
});

export default ViewLocationSimulator;

import React from "react";
import useSWR from "swr";

import { HABITS_URL, TAGS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import HabitEdit from "./HabitEdit";

interface Props {
  habitId: any;
}

export function HabitEditContainer({ habitId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data: habitData } = useSWR(`${HABITS_URL}/${habitId}`, fetcher, {
    suspense: true,
  });

  const { data: tagData } = useSWR(TAGS_URL, fetcher, { suspense: true });

  if (!habitData || !tagData) return;

  return <HabitEdit existingTags={tagData.tags} habit={habitData.habit} />;
}

export default HabitEditContainer;

import React from "react";
import { StyleSheet } from "react-native";

import useColorScheme from "@/hooks/useColorScheme";
import Colors from "@/constants/Colors";
import { Text, View } from "@/components/Themed";

interface Props {
  label: string;
  icon: React.ReactNode;
  value: string;
}

export default function Statistic({ label, icon, value }: Props) {
  const colorScheme = useColorScheme();

  return (
    <View
      style={[
        styles.statistic,
        {
          backgroundColor: Colors[colorScheme].cardBackground,
        },
      ]}
    >
      <Text style={styles.label}>{label}</Text>
      {icon && icon}
      <Text style={styles.value}>{value}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  statistic: {
    flex: 1,
    padding: 10,
    margin: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    fontSize: 16,
  },
  value: {
    fontSize: 14,
  },
});

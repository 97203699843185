import React from "react";
import { Platform, StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import { mutate } from "swr";
import camelCase from "camelcase";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { HABITS_URL, LOCATION_AUTOMATIONS_URL } from "@/helpers/api";
import LocationAutomationForm from "@/components/LocationAutomationForm";
import logger from "@/helpers/logger";

export function LocationAutomationCreate({ habitId }) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();

  const onSubmit = async (
    {
      name,
      latitude,
      longitude,
      radiusInKilometers: radius_in_kilometers,
      shouldConfirm: should_confirm,
    },
    formikHelpers,
  ) => {
    selectionAsync();
    formikHelpers.setSubmitting(true);

    try {
      await requestAndSetTokens(LOCATION_AUTOMATIONS_URL, {
        method: "POST",
        body: {
          location_automation: {
            habit_id: habitId,
            name,
            latitude,
            longitude,
            radius_in_kilometers,
            should_confirm
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Location automation created! 😻",
        position: "bottom",
      });

      // Update all the cached stuff
      mutate(LOCATION_AUTOMATIONS_URL);
      mutate(`${HABITS_URL}/${habitId}`);

      navigation.goBack();
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        logger.error("Error creating location automation", { error: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        logger.error("Error creating location automation", { error });

        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while creating the location automation.",
          position: "bottom",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <View style={styles.container}>
      <LocationAutomationForm onSubmit={onSubmit} shownInModal />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
});

export default LocationAutomationCreate;

import React from "react";
import { ActivityIndicator, Alert, Platform, StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import { mutate } from "swr";
import camelCase from "camelcase";
import { Button } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { PROJECTS_URL, HABITS_URL } from "@/helpers/api";
import ProjectForm from "@/components/ProjectForm";
import logger from "@/helpers/logger";

interface Props {
  project: any;
  isLoading?: boolean;
}

export function ProjectEdit({ isLoading = false, project }: Props) {
  const navigation = useNavigation();
  // TODO: Figure out why this is causing the following error:
  // Warning: Can't perform a React state update on an unmounted component
  const requestAndSetTokens = useRequestAndSetTokens();

  const onSubmit = async (
    formikValues,
    formikHelpers,
  ) => {
    selectionAsync();

    const {
      name,
      colorHex: color_hex,
      icon,
    } = formikValues;
    formikHelpers.setSubmitting(true);

    try {
      const url = `${PROJECTS_URL}/${project.id}`;
      await requestAndSetTokens(url, {
        method: "PATCH",
        body: {
          project: {
            name,
            color_hex,
            icon,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Project updated! 😻",
        position: "bottom",
      });

      mutate(PROJECTS_URL);

      navigation.goBack()
    } catch (error) {
      const errors = error?.result?.errors;
      let errorMessage = "An error occurred while updating the project.";

      if (errors) {
        logger.error("Error creating project", { error, errors: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        errorMessage = Object
          .entries(formikErrors)
          .map(e => e.join(' '))
          .join(', ');

        formikHelpers.setErrors(formikErrors);
      }

      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: errorMessage,
        position: "bottom",
      });

      formikHelpers.setSubmitting(false);
    }
  };

  React.useLayoutEffect(() => {
    if (!project) return;

    const onDelete = async () => {
      selectionAsync();

      try {
        const url = `${PROJECTS_URL}/${project.id}`;
        await requestAndSetTokens(url, {
          method: "DELETE",
        });

        // Update all the cached stuff
        mutate(PROJECTS_URL);
        mutate(HABITS_URL);

        Toast.show({
          type: "success",
          text1: "Success",
          text2: 'Project deleted! 🪦',
          position: "bottom",
        });

        navigation.goBack()
      } catch (error) {
        logger.error("Error deleting project", { error });
      }
    };

    const createAlert = () =>
      Alert.alert(
        "Delete Confirmation",
        `Are you sure you want to delete this project?`,
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          { text: 'Delete', onPress: onDelete },
        ],
      );

    navigation.setOptions({
      headerRight: () => (
        <Button type="clear" onPress={() => createAlert()}>
          <FontAwesome5 name="trash" size={30} style={{ color: "red" }} />
        </Button>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(project), navigation, requestAndSetTokens]);

  if (isLoading) {
    return (
      <ActivityIndicator style={styles.loader} size="large" />
    );
  };

  return (
    <View style={styles.container}>
      <ProjectForm
        onSubmit={onSubmit}
        initialValues={{
          name: project.name,
          colorHex: project.color_hex,
          icon: project.icon,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  loader: {
    marginTop: 100,
  },
});

export default ProjectEdit;

import React from "react";
import { useStoreState } from "easy-peasy";

import Profile from "./Profile";

export function ProfileContainer() {
  const user = useStoreState((state) => state.session.user);

  return <Profile user={user} />;
}

export default ProfileContainer;

import * as React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { TabBar, TabView, SceneMap } from "react-native-tab-view";
import { useTheme } from 'react-native-paper';

interface Props {
  data: any[];
}

export function Tabs({ data }: Props) {
  const theme = useTheme();
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState(
    data.map((route) => ({
      key: route.key,
      title: route.title,
    })),
  );

  const renderScene = SceneMap(
    data.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.scene }),
      {},
    ),
  );

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      style={{
        backgroundColor: theme.colors.surfaceVariant,
      }}
      indicatorStyle={[styles.indicator, { backgroundColor: theme.colors.primary }]}
      labelStyle={styles.label}
      tabStyle={{
        paddingHorizontal: 0,
        color: theme.colors.onSurfaceVariant,
      }}
    />
  );

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      renderTabBar={renderTabBar}
    />
  );
}

const styles = StyleSheet.create({
  indicator: {
    height: 4,
  },
  label: {
    color: 'black',
    fontSize: 14,
    fontWeight: "bold",
  },
});

export default Tabs;

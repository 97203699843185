import React from "react";
import { StyleSheet } from "react-native";

import { View } from "@/components/Themed";

interface Props {
  children: React.ReactNode;
}

export default function Statistics({ children }: Props) {
  return <View style={styles.statistics}>{children}</View>;
}

const styles = StyleSheet.create({
  statistics: {
    flexDirection: "row",
  },
});

import React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { ScrollView, Text } from "./Themed";

export function ViewNavigation() {
  const navigation = useNavigation();

  return (
    <ScrollView style={styles.container}>
      <Text>{JSON.stringify(navigation.getState(), null, 2)}</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    flex: 1,
  },
});

export default ViewNavigation;

import React from "react";
import { StyleSheet } from "react-native";

import { View, Text } from "@/components/Themed";

export default function Explanation() {
  return (
    <View style={styles.container}>
      <Text>
        This is where I can put an explanation of what&apos;s happening; e.g.
        how the pointing system works and stuff
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
});

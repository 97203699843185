import React from "react";
import { StyleSheet, SectionList } from "react-native";
import { Skeleton } from "@rneui/themed";
import { LinearGradient } from "expo-linear-gradient";
import { format } from "date-fns";
import Animated, { Layout } from "react-native-reanimated";

import useColorScheme from "@/hooks/useColorScheme";
import Colors from "@/constants/Colors";
import { Text, View } from "@/components/Themed";
import OccurrenceCard from "@/components/OccurrenceCard";
import IncidentCard from "@/components/IncidentCard";
import EmptyList from "@/components/EmptyList";

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);

// This should be outside of render code for some reason:
// https://github.com/software-mansion/react-native-reanimated/issues/2737
const layout = Layout.springify();

interface Props {
  occurrences: any[];
  incidents: any[];
  isLoading: boolean;
  mutateIncidents: any;
  mutateOccurrences: any;
}

export function Timeline({
  occurrences = [],
  incidents = [],
  isLoading = false,
  mutateIncidents,
  mutateOccurrences,
}: Props) {
  const colorScheme = useColorScheme();

  if (isLoading) {
    return (
      <View>
        <Skeleton
          key={0}
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          key={1}
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          key={2}
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          key={3}
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          key={4}
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
      </View>
    );
  }

  const combinedData = [
    ...occurrences.map((occurrence) => ({
      ...occurrence,
      componentType: "occurrence",
    })),
    ...incidents.map((incident) => ({
      ...incident,
      componentType: "incident",
    })),
  ];

  const groups = combinedData.reduce((groups, datum) => {
    const date = format(new Date(datum.occurred_at), "EEEE, MMMM do");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(datum);
    return groups;
  }, {});

  const groupArray = Object.keys(groups).map((date) => {
    return {
      date,
      fullDate: groups[date][0].occurred_at,
      data: groups[date].sort((a, b) =>
        b.occurred_at.localeCompare(a.occurred_at),
      ),
    };
  });

  const sortedGroupArray = groupArray.sort((a, b) =>
    b.fullDate.localeCompare(a.fullDate),
  );

  const titledSortedGroupArray = sortedGroupArray.map((datum) => ({
    data: datum.data,
    title: format(new Date(datum.fullDate), "EEEE, MMMM do"),
  }));

  const mutate = () => {
    mutateIncidents();
    mutateOccurrences();
  };

  return (
    <AnimatedSectionList
      itemLayoutAnimation={layout}
      sections={titledSortedGroupArray}
      keyExtractor={(item) => item.id.toString()}
      onRefresh={() => mutate()}
      refreshing={false}
      contentContainerStyle={styles.list}
      ListEmptyComponent={<EmptyList noun='events' />}
      renderSectionHeader={({ section: { title } }) => (
        <Text
          style={[
            styles.sectionTitle,
            { backgroundColor: Colors[colorScheme].background },
          ]}
        >
          {title}
        </Text>
      )}
      renderItem={({ item }) => {
        switch (item.componentType) {
          case "incident": {
            return <IncidentCard incident={item} key={item.id} />;
          }
          case "occurrence": {
            return <OccurrenceCard occurrence={item} key={item.id} />;
          }
          default: {
            throw "unknown componentType";
          }
        }
      }}
    />
  );
}

const styles = StyleSheet.create({
  skeleton: {
    marginBottom: 10,
  },
  list: {
    paddingBottom: 200,
  },
  sectionTitle: {
    marginBottom: 10,
    fontSize: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
});

export default Timeline;

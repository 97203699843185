/* eslint-disable react/prop-types */

import React from "react";
import { Alert, Platform, StyleSheet } from "react-native";
import { Button as PaperButton } from 'react-native-paper';
import { useStoreActions, useStoreState } from "easy-peasy";
import * as Linking from "expo-linking";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";

import { View, ScrollView, Text } from "@/components/Themed";
import { storage } from "@/helpers/storage-swr";
import logger from "@/helpers/logger";
import { openAdInspector } from "@/helpers/ads";

const requestFeature = () => {
  Linking.openURL("https://the-quantified-self.canny.io");
};

export function SettingsScreen() {
  const navigation = useNavigation();
  const signOut = useStoreActions((actions) => actions.session.signOut);
  const devMenuOpen = useStoreState((state) => state.general.devMenuOpen);
  const setDevMenuOpen = useStoreActions((actions) => actions.general.setDevMenuOpen);
  const clearCustomerInfo = useStoreActions(
    (actions) => actions.general.clearCustomerInfo,
  );
  const user = useStoreState((state) => state.session.user);

  const onDeleteAccount = () => {
    navigation.navigate("AccountDeletion");
  };

  const editProfile = () => {
    navigation.navigate("Profile");
  };

  const editNotifications = () => {
    navigation.navigate("Notifications");
  };

  const listProjects = () => {
    navigation.navigate("ProjectsList");
  };

  const onSignOut = async () => {
    try {
      storage.clearEverything();
      await signOut();
    } catch (error) {
      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: "An error occurred while signing out.",
        position: "bottom",
      });

      logger.error("unable-to-sign-out", { error });
    }
  };

  const onClearSubscriptionData = async () => {
    await clearCustomerInfo();
  };

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
      <PaperButton
        style={styles.button}
        onPress={() => editProfile()}
      >
        Profile
      </PaperButton>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />

      <PaperButton
        style={styles.button}
        onPress={() => editNotifications()}
      >
        Notifications
      </PaperButton>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />

      <PaperButton
        style={styles.button}
        onPress={() => listProjects()}
      >
        Projects
      </PaperButton>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />

      <PaperButton
        style={styles.button}
        onPress={() => requestFeature()}
      >
        Request a Feature!
      </PaperButton>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />

      <PaperButton
        style={styles.button}
        onPress={() => onDeleteAccount()}
      >
        Delete Account
      </PaperButton>

      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />

      <PaperButton
        style={styles.button}
        onPress={() => onSignOut()}
      >
        Sign out!
      </PaperButton>

      {(user.is_demo_account || user.is_admin_account) && (
        <>
          <Text
            style={{
              textAlign: "center",
              marginTop: 30,
              marginBottom: 10,
              fontSize: 20,
            }}
          >
            Developer Tools
          </Text>

          <PaperButton
            style={styles.button}
            onPress={() => onClearSubscriptionData()}
          >
            Clear subscription data
          </PaperButton>
        </>
      )}

      {user.is_admin_account && (
        <>
          <View
            style={styles.separator}
            lightColor="#eee"
            darkColor="rgba(255,255,255,0.1)"
          />

          <PaperButton
            style={styles.button}
            onPress={async () => {
              try {
                await openAdInspector();
                // The promise will resolve when the inspector is closed.
              } catch (error) {
                // The promise will reject if ad inspector is closed due to an error.
                logger.error("Error opening ad inspector", { error });
              }
            }}
          >
            Show Ad Inspector
          </PaperButton>

          <View
            style={styles.separator}
            lightColor="#eee"
            darkColor="rgba(255,255,255,0.1)"
          />

          <PaperButton
            style={styles.button}
            onPress={async () => {
              storage.clearEverything();
              Alert.alert("Cache cleared");
            }}
          >
            Clear SWR Cache
          </PaperButton>

          <View
            style={styles.separator}
            lightColor="#eee"
            darkColor="rgba(255,255,255,0.1)"
          />

          <PaperButton
            style={styles.button}
            onPress={async () => {
              setDevMenuOpen(!devMenuOpen);
            }}
          >
            Toggle Dev Menu
          </PaperButton>

          <View
            style={styles.separator}
            lightColor="#eee"
            darkColor="rgba(255,255,255,0.1)"
          />
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "ios" ? 10 : 100,
    flex: 1,
  },
  contentContainerStyle: {
    paddingBottom: 60,
  },
  separator: {
    marginVertical: 5,
    height: 1,
  },
  button: {
    marginVertical: 5,
  },
});

export default SettingsScreen;

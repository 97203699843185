export const secondsToDaysHoursMinutes = (seconds) => {
  seconds = Number(seconds);

  const days = Math.floor(seconds / (3600*24));
  const hours = Math.floor(seconds % (3600*24) / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);

  return { days, hours, minutes, seconds };
}

export const daysHoursMinutesToSeconds = ({ days = 0, hours = 0, minutes = 0} = {}) => {
  let seconds = 0;

  seconds += minutes * 60;
  seconds += hours * 3600;
  seconds += days * 86400;

  return seconds;
}

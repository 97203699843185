import React from "react";
import useSWR from "swr";

import { PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ProjectsList from "./ProjectsList";

export function ProjectsListContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data, isValidating, mutate } = useSWR(PROJECTS_URL, fetcher, {
    suspense: true,
  });

  return (
    <ProjectsList
      projects={data.projects}
      isValidating={isValidating}
      mutate={mutate}
    />
  );
}

export default ProjectsListContainer;

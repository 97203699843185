import * as Updates from "expo-updates";
import { Platform } from "react-native";

const Config = {
  apiUrl: "http://localhost:3000",
  // apiUrl: 'http://b908-73-32-213-5.ngrok.io',
  env: "development",
  revenueCat: {
    iosKey: "appl_YHlxMzsTkMgrflQsVJMShXRtonX",
    webKey: "strp_ToKmzqvGVNbNlWePSlTMjFJfIPa",
    androidKey: "goog_YUfELDXrXFxDMEbPqtYbdHQKtic",
    entitlementId: "Standard",
  },
};

if (Platform.OS === "android") {
  Config.apiUrl = "http://10.0.2.2:3000";
}

if (Platform.OS === "ios") {
  Config.apiUrl = "http://192.168.68.69:3000";
}

if (Updates.releaseChannel === "staging") {
  Config.env = "staging";
}

if (Updates.releaseChannel === "production" || process.env.NODE_ENV === 'production') {
  Config.env = "production";
}

if (["staging", "production"].includes(Updates.releaseChannel) || process.env.NODE_ENV === 'production') {
  Config.apiUrl = "https://api.the-quantified-self.com";
}

export default Config;

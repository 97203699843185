import React from "react";

import { Text } from "@/components/Themed";

export const AutomationList = () => {
  return (
    <Text>Automations are not currently supported on web.</Text>
  );
}

export default AutomationList;

import React from "react";
import { StyleSheet } from "react-native";
import NumericInput from "react-native-numeric-input";
import Animated, { FadeInDown, FadeOutUp } from 'react-native-reanimated';

import { Text, View } from "@/components/Themed";
import useColorScheme from "@/hooks/useColorScheme";
import Colors from "@/constants/Colors";
import {
  secondsToDaysHoursMinutes,
  daysHoursMinutesToSeconds,
} from "@/helpers/duration";

const WIDTH = 60;
const HEIGHT = 50;

interface Props {
  value: string;
  onChange: (string) => void;
}

export function DurationPicker({
  seconds = 0,
  onChange,
}: Props) {
  const colorScheme = useColorScheme();
  const { days, hours, minutes } = secondsToDaysHoursMinutes(seconds);

  const handleChangeDays = (newDays) => {
    const newValue = daysHoursMinutesToSeconds({
      days: newDays,
      hours,
      minutes,
    });

    onChange(newValue);
  };

  const handleChangeHours = (newHours) => {
    const newValue = daysHoursMinutesToSeconds({
      days,
      hours: newHours,
      minutes,
    });

    onChange(newValue);
  };

  const handleChangeMinutes = (newMinutes) => {
    const newValue = daysHoursMinutesToSeconds({
      days,
      hours,
      minutes: newMinutes,
    });

    onChange(newValue);
  };

  return (
    <Animated.View style={styles.container} entering={FadeInDown} exiting={FadeOutUp}>
      <View style={styles.piece}>
        <NumericInput
          type='up-down'
          totalHeight={HEIGHT}
          totalWidth={WIDTH}
          value={days}
          onChange={handleChangeDays}
          inputStyle={{ color: Colors[colorScheme].text }}
        />

        <Text style={styles.label}>days</Text>
      </View>

      <View style={styles.piece}>
        <NumericInput
          type='up-down'
          totalHeight={HEIGHT}
          totalWidth={WIDTH}
          value={hours}
          onChange={handleChangeHours}
          inputStyle={{ color: Colors[colorScheme].text }}
        />

        <Text style={styles.label}>hours</Text>
      </View>

      <View style={styles.piece}>
        <NumericInput
          type='up-down'
          totalHeight={HEIGHT}
          totalWidth={WIDTH}
          value={minutes}
          onChange={handleChangeMinutes}
          inputStyle={{ color: Colors[colorScheme].text }}
        />

        <Text style={styles.label}>min</Text>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  piece: {
    flexDirection: 'row',
  },
  label: {
    fontSize: 18,
    marginLeft: 10,
    marginTop: 10,
  },
});

export default DurationPicker;

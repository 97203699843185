import { isWithinInterval } from 'date-fns';

function isWithinTimeRange(start, seconds) {
  const currentTime = new Date();

  const milliseconds = seconds * 1000;
  const end = new Date(start.getTime() + milliseconds);

  return isWithinInterval(
    currentTime,
    { start, end }
  )
}

export const habitIsTimely = (habit): boolean => {
  const {
    period_start,
    timeliness_duration_in_seconds,
  } = habit;
  if (!timeliness_duration_in_seconds) return false;

  const periodStart = new Date(period_start);

  return isWithinTimeRange(periodStart, timeliness_duration_in_seconds);
}

export default habitIsTimely;

import AsyncStorage from '@react-native-async-storage/async-storage';

const prefix = "swr-storage";

// Needs to conform to:
// https://swr.vercel.app/docs/advanced/cache
export const storage = {
  // get(key: string): Data | undefined
  get: async (key) => {
    const value = await AsyncStorage.getItem(`${prefix}/${key}`)

    if (value) {
      return JSON.parse(value);
    }
  },
  // set(key: string, value: Data): void
  set: async (key, data) => {
    await AsyncStorage.setItem(`${prefix}/${key}`, JSON.stringify(data))
  },
  // delete(key: string): void
  removeItem: async (key) => {
    await AsyncStorage.removeItem(`${prefix}/${key}`);
  },
  // CAVEAT: this will clear EVERYTHING, across all stores
  clearEverything: async () => {
    await AsyncStorage.clear()
  },
};

export default storage;

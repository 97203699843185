import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import DropDownPicker from 'react-native-dropdown-picker';
import { FontAwesome5 } from "@expo/vector-icons";
import { useTheme } from 'react-native-paper';

import { View } from "@/components/Themed";

interface Props {
  project: Record<string, any>;
  projects: Record<string, any>[];
  setProject: any;
}

export function ProjectSelector({
  project,
  projects = [],
  setProject,
}: Props) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(projects.map(p => ({
    label: p.name,
    value: p.id,
    icon: () => (
      <FontAwesome5
        name={p.icon}
        size={35}
        style={{ color: p.color_hex }}
      />
    ),
  })));

  return (
    <View style={styles.container}>
      <DropDownPicker
        placeholder="Select a project"
        listMode="FLATLIST"
        dropDownDirection="TOP"
        style={{
          minHeight: 40,
          backgroundColor: theme.colors.surfaceVariant,
          borderColor: theme.colors.border,
        }}
        containerStyle={{
          marginLeft: 20,
          marginRight: 10,
        }}
        open={open}
        value={project}
        items={items}
        setOpen={setOpen}
        onSelectItem={({ value }) => setProject(value)}
        setItems={setItems}
      />

      <MaterialIcons
        name="clear"
        size={30}
        disabled={!project}
        color={project ? "red" : "grey"}
        onPress={() => setProject()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    marginHorizontal: 25,
  },
});

export default ProjectSelector;

import React from "react";
import useSWR from "swr";

import { VICES_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ViceList from "./ViceList";

export function ViceListContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data, isValidating, mutate } = useSWR(VICES_URL, fetcher, {
    suspense: true,
  });

  return (
    <ViceList vices={data.vices} mutate={mutate} isValidating={isValidating} />
  );
}

export default ViceListContainer;

import React, { useState } from "react";
import useSWR from "swr";
import { Platform, StyleSheet, TouchableOpacity } from "react-native";
import * as Linking from "expo-linking";
import { format, formatRelative } from "date-fns";
// import NumberEasing from "react-number-easing";
import { useNavigation } from "@react-navigation/native";
import { FontAwesome5 } from "@expo/vector-icons";

import { selectionAsync } from "@/helpers/haptics";
import Colors from "@/constants/Colors";
import { FontAwesome, Text, View } from "@/components/Themed";
import { createOccurrence } from "@/helpers/api/occurrences";
import { capitalize } from "@/helpers/capitalize";
import { HABITS_URL, PROJECTS_URL } from "@/helpers/api";
import useColorScheme from "@/hooks/useColorScheme";
import pluralize from "@/helpers/pluralize";
import timesPhrase from "@/helpers/times-phrase";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import logger from "@/helpers/logger";

const dayOfWeek = {
  sunday: "sun",
  monday: "mon",
  tuesday: "tues",
  wednesday: "wed",
  thursday: "thurs",
  friday: "fri",
  saturday: "sat",
  sunday: "sun",
}

const getRecentOccurrenceText = (habit) => {
  if (habit.most_recent_occurrence) {
    const then = new Date(habit.most_recent_occurrence.occurred_at);
    const now = new Date();

    return `done ${formatRelative(then, now)}`;
  }

  return "never completed";
};

export const getFrequencyText = (habit) => {
  let period = habit.frequency_period;
  if (habit.frequency_number > 1) {
    period = pluralize(habit.frequency_number, habit.frequency_period);
  }

  let time = '';
  if (habit.period_start_time) {
    time = ` at ${format(new Date(habit.period_start_time), "kk:mm:ss")}`
  }

  let day = '';
  if (habit.period_start_day) {
    day = ` on ${capitalize(dayOfWeek[habit.period_start_day])}`
  }

  return `${timesPhrase(habit.frequency_target)} every ${period}${time}${day}`;
};

const getUrgencyText = (habit) => {
  if (Number.isFinite(habit.urgency_index)) {
    return habit.urgency_index.toFixed(2)
    // return <NumberEasing value={habit.urgency_index} decimals={2} />;
  }

  if (habit.urgency_index === 'Infinity') {
    return (
      <FontAwesome5
        name='infinity'
      />
    )
  }

  return habit.urgency_index;
}

export const getTodoIcon = (habit, isFetching) => {
  if (isFetching) return "check-circle-o";

  if (habit.strict && habit.fulfilled) return  "check-circle-o";

  return "circle-o";
}

const onPressLink = (link) => {
  if (Platform.OS == 'web'){
    window.open(link, '_blank');
  } else {
    Linking.openURL(link);
  }
};

interface Props {
  habit: any;
  urgencyIndexColor: string;
  children: React.ReactNode;
}

const MiddleWrapper = (props) => {
  const { link, ...otherProps } = props;

  if (link) {
    return <TouchableOpacity {...otherProps}>{otherProps.children}</TouchableOpacity>;
  }

  return <View {...otherProps}>{otherProps.children}</View>;
};

// For completing the habit
const onComplete = async ({
  habitId,
  requestAndSetTokens,
  habits,
  onRemove,
  setIsFetching,
}) => {
  selectionAsync();
  setIsFetching(true);

  try {
    await createOccurrence({
      habitId,
      occurredAt: new Date(),
      requestAndSetTokens,
      habits,
    });

    onRemove()

    setIsFetching(false);
  } catch (error) {
    setIsFetching(false);

    logger.error("Error completing habit", { error });
  }
};

export default function HabitCard({
  onRemove,
  habit,
  urgencyIndexColor,
  containerStyles = {},
}: Props) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();
  const colorScheme = useColorScheme();
  const { data: habitsData } = useSWR(HABITS_URL, requestAndSetTokens);
  const { data: projectsData } = useSWR(PROJECTS_URL, requestAndSetTokens);

  const [isFetching, setIsFetching] = useState(false);

  const onView = () => {
    navigation.navigate("HabitView", { id: habit.id });
  };

  if (!habitsData) return;
  if (!projectsData) return;

  const { habits } = habitsData;
  const { projects } = projectsData;

  const project = projects.find(project => project.id === habit.project_id);

  return (
    <TouchableOpacity onPress={onView}>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isFetching
              ? Colors[colorScheme].cardBackgroundDisabled
              : Colors[colorScheme].cardBackground,
          },
          containerStyles,
        ]}
      >
        <TouchableOpacity
          disabled={isFetching}
          style={styles.buttonContainer}
          onPress={async () => await onComplete({
            habitId: habit.id,
            requestAndSetTokens,
            habits,
            onRemove,
            setIsFetching,
          })}
        >
          <FontAwesome
            size={30}
            name={getTodoIcon(habit, isFetching)}
            style={[styles.button, { color: Colors[colorScheme].text }]}
          />
        </TouchableOpacity>

        <View style={styles.nameContainer}>
          <MiddleWrapper link={habit.link} style={styles.nameContainerTop} onPress={() => onPressLink(habit.link)}>
            <Text
              style={[
                styles.name,
                habit.link ? { textDecorationLine: "underline" } : {},
              ]}
            >
              {habit.name}
              {habit.link && (
                <View style={styles.externalLink}>
                  <FontAwesome name="external-link" />
                </View>
              )}
            </Text>
          </MiddleWrapper>

          <Text style={styles.datetime}>
            {getRecentOccurrenceText(habit)}
          </Text>

        </View>

        <View style={[styles.rightSide]}>
          <View style={styles.chipContainer}>
            {project && (
              <View style={[styles.chip, { borderColor: project.color_hex }]}>
                <FontAwesome5
                  name={project.icon}
                  style={styles.projectIcon}
                />

                <Text style={styles.projectName}>
                  {project.name}
                </Text>
              </View>
            )}
          </View>

          <View style={styles.urgencyContainer}>
            <Text style={[styles.urgencyIndex, { color: "#" + urgencyIndexColor }]}>
              {getUrgencyText(habit)}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  chipContainer: {
    backgroundColor: "transparent",
    marginBottom: 10,
  },
  chip: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    backgroundColor: "white",
    alignSelf: 'flex-end',
    borderRadius: 10,
    borderWidth: 2,
  },
  urgencyContainer: {
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  urgencyIndex: {
    minWidth: 30,
    textAlign: 'right',
    marginLeft: 20,
    fontWeight: 'bold',
  },
  projectIcon: {
    marginRight: 5,
  },
  projectName: {
    color: Colors.light.text,
  },
  externalLink: {
    paddingLeft: 5,
    backgroundColor: "transparent",
  },
  rightSide: {
    width: '28%',
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  nameContainer: {
    justifySelf: 'stretch',
    backgroundColor: 'transparent',
    paddingRight: 15,
    width: '60%',
  },
  nameContainerTop: {
    backgroundColor: "transparent",
  },
  name: {
    fontSize: 20,
    marginBottom: 8,
  },
  button: {
    marginRight: 15,
  },
  datetime: {
    fontStyle: "italic",
    marginBottom: 6,
  },
  container: {
    justifyContent: 'space-between',
    flexDirection: "row",
    fontSize: 14,
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: 10,
  },
  buttonContainer: {
    justifyContent: "center",
  },
});

import React from "react";
import { useSWRConfig } from "swr";
import { StyleSheet, Platform } from "react-native";
import Toast from "react-native-toast-message";
import camelCase from "camelcase";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import { View } from "@/components/Themed";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { TAGS_URL, HABITS_URL } from "@/helpers/api";
import HabitForm from "@/components/HabitForm";
import logger from "@/helpers/logger";

interface Props {
  existingTags: any[];
}

export function HabitCreate({ existingTags }: Props) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();
  const { mutate } = useSWRConfig();

  // For completing the habit
  const onSubmit = async (
    {
      name,
      link,
      frequencyPeriod: frequency_period,
      frequencyTarget: frequency_target,
      frequencyNumber: frequency_number,
      isStrict: strict,
      fromPeriodStart: from_period_start,
      importanceScalar: importance_scalar,
      tagsArray,
      durationInMinutes: duration_in_minutes,
      periodStartTime: period_start_time,
      periodStartDay: period_start_day,
      notifyWhenDue: notify_when_due,
      projectId: project_id,
      timelinessDurationInSeconds: timeliness_duration_in_seconds,
    },
    formikHelpers,
  ) => {
    selectionAsync();
    formikHelpers.setSubmitting(true);

    try {
      const json = await requestAndSetTokens(HABITS_URL, {
        method: "POST",
        body: {
          habit: {
            name,
            frequency_target,
            frequency_number,
            frequency_period,
            from_period_start,
            link,
            importance_scalar,
            strict,
            tag_list: tagsArray.join(","),
            duration_in_minutes,
            period_start_time,
            period_start_day,
            notify_when_due,
            project_id,
            timeliness_duration_in_seconds,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Habit created! 😻",
        position: "bottom",
      });

      mutate(TAGS_URL);
      mutate(HABITS_URL);

      navigation.navigate("HabitView", { id: json.habit.id });
    } catch (error) {
      const errors = error?.result?.errors;
      let errorMessage = "An error occurred while updating the habit.";

      if (errors) {
        logger.error("Error creating habit", { error, errors: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        errorMessage = Object
          .entries(formikErrors)
          .map(e => e.join(' '))
          .join(', ');

        formikHelpers.setErrors(formikErrors);
      }

      // TODO why aren't toasts in modals showing???
      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: errorMessage,
        position: "bottom",
      });

      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <View style={styles.container}>
      <HabitForm onSubmit={onSubmit} existingTags={existingTags} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "android" ? 120 : 10,
  },
});

export default HabitCreate;

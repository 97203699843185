/* eslint-disable react/prop-types */

import React from "react";
import { Button, Input, useTheme } from "@rneui/themed";
import { StyleSheet } from "react-native";
import { useStoreActions } from "easy-peasy";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { View, Text } from "@/components/Themed";

export function SignInScreen() {
  const { theme } = useTheme();
  const navigation = useNavigation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const signInAction = useStoreActions((actions) => actions.session.signIn);

  const signIn = async () => {
    try {
      await signInAction({ email, password });
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        for (const e of errors) {
          Toast.show({
            type: "error",
            text1: "Whoops",
            text2: e,
          });
        }
      } else {
        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while signing in.",
          position: "bottom",
        });
      }
    }
  };

  const onPress = () => {
    navigation.navigate("SignUp");
  };

  const onPressPasswordReset = () => {
    navigation.navigate("PasswordReset");
  };

  return (
    <View style={styles.container}>
      <Input
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
      />
      <Input
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry={!showPassword}
        rightIcon={
          <MaterialCommunityIcons
            size={25}
            name={showPassword ? "eye" : "eye-off"}
            color={theme?.colors?.primary}
            onPress={() => setShowPassword(!showPassword)}
          />
        }
      />

      <View
        style={{
          paddingHorizontal: 20,
          marginBottom: 20,
        }}
      >
        <Button title="Sign in" onPress={() => signIn()} />
      </View>

      <View
        style={{
          marginTop: 24,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Forgot your password?</Text>
        <Button
          type="clear"
          title="Reset password!"
          onPress={() => onPressPasswordReset()}
        />
      </View>

      <View
        style={{
          marginTop: 24,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Don&apos;t have an account?</Text>
        <Button type="clear" title="Sign up!" onPress={() => onPress()} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 20,
  },
});

export default SignInScreen;

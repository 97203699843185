import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigation } from "@react-navigation/native";
import { Switch } from "react-native-switch";
import { Pressable } from "react-native";
import { IconButton } from 'react-native-paper';
import { useTheme } from 'react-native-paper';

import HabitsScreen from "@/screens/HabitsScreen";
import HabitsViewScreen from "@/screens/HabitsViewScreen";
import HabitsEditScreen from "@/screens/HabitsEditScreen";
import LocationAutomationEditScreen from "@/screens/LocationAutomationEditScreen";
import HealthAutomationEditScreen from "@/screens/HealthAutomationEditScreen";
import VicesViewScreen from "@/screens/VicesViewScreen";
import VicesEditScreen from "@/screens/VicesEditScreen";

const HabitStack = createNativeStackNavigator();

export function HabitStackScreen() {
  const navigation = useNavigation();
  const setHabitSwitch = useStoreActions(
    (actions) => actions.general.setHabitSwitch,
  );
  const habitSwitch = useStoreState((state) => state.general.habitSwitch);
  const viewHabitCreate = useStoreActions(
    (actions) => actions.modal.viewHabitCreate,
  );
  const viewViceCreate = useStoreActions(
    (actions) => actions.modal.viewViceCreate,
  );
  const theme = useTheme();

  return (
    <HabitStack.Navigator backBehavior="order">
      <HabitStack.Screen
        name="HabitsList"
        component={HabitsScreen}
        options={{
          title: "Habits",
          headerLeft: () => (
            <Switch
              value={habitSwitch}
              onValueChange={(value) => setHabitSwitch(value)}
              activeText={"Make"}
              inActiveText={"Break"}
              backgroundInactive={theme.colors.tertiary}
              backgroundActive={theme.colors.secondary}
              switchWidthMultiplier={3}
            />
          ),
          headerRight: () => (
            <Pressable
              onPress={() => {
                if (habitSwitch) {
                  viewHabitCreate();
                } else {
                  viewViceCreate();
                }
                navigation.navigate("Modal");
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}
            >
              <IconButton
                icon="plus"
                size={18}
                selected
                mode='contained'
              />
            </Pressable>
          ),
        }}
      />

      <HabitStack.Screen
        name="HabitView"
        component={HabitsViewScreen}
        options={{
          title: "Habit View",
        }}
      />

      <HabitStack.Screen
        name="HabitEdit"
        component={HabitsEditScreen}
        options={{
          title: "Habit Edit",
        }}
      />

      <HabitStack.Screen
        name="LocationAutomationEdit"
        component={LocationAutomationEditScreen}
        options={{
          title: "Location Automation Edit",
        }}
      />

      <HabitStack.Screen
        name="HealthAutomationEdit"
        component={HealthAutomationEditScreen}
        options={{
          title: "Health Automation Edit",
        }}
      />

      <HabitStack.Screen
        name="ViceView"
        component={VicesViewScreen}
        options={{
          title: "Vice View",
        }}
      />

      <HabitStack.Screen
        name="ViceEdit"
        component={VicesEditScreen}
        options={{
          title: "Vice Edit",
        }}
      />
    </HabitStack.Navigator>
  );
}

export default HabitStackScreen;

import React from "react";
import useSWR from "swr";

import { VICES_URL, INCIDENTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import IncidentEdit from "./IncidentEdit";

interface Props {
  incidentId: number;
}

export function IncidentEditContainer({ incidentId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data: vicesData } = useSWR(VICES_URL, fetcher, {
    suspense: true,
  });

  const { data: incidentData } = useSWR(
    `${INCIDENTS_URL}/${incidentId}`,
    fetcher,
    {
      suspense: true,
    },
  );

  return (
    <IncidentEdit incident={incidentData.incident} vices={vicesData.vices} />
  );
}

export default IncidentEditContainer;

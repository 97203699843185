import React from "react";

import useSWR from "swr";

import { HABITS_URL, PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import HabitList from "./HabitList";

interface Props {
  text: string;
  setText: any;
}

export function HabitListContainer({ text, setText }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data, isValidating, mutate } = useSWR(HABITS_URL, fetcher, {
    suspense: true,
  });

  const { data: projectsData } = useSWR(PROJECTS_URL, fetcher, {
    suspense: true,
  });

  return (
    <HabitList
      text={text}
      setText={setText}
      habits={data.habits}
      projects={projectsData?.projects}
      isValidating={isValidating}
      mutate={mutate}
    />
  );
}

export default HabitListContainer;

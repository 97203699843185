import React, { useState } from "react";
import { StyleSheet, TouchableHighlight, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import Popover from "react-native-popover-view";
import pluralize from "pluralize";
import { FontAwesome5 } from "@expo/vector-icons";
import NumericInput from "react-native-numeric-input";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import DropDownPicker from 'react-native-dropdown-picker';
import {
  Button as PaperButton,
  Text as PaperText,
  TextInput,
  HelperText,
  SegmentedButtons,
  Switch as PaperSwitch,
} from 'react-native-paper';
import { TimePickerModal } from 'react-native-paper-dates';
import Animated, { ZoomInEasyUp, ZoomOutEasyUp } from 'react-native-reanimated';
import { useTheme } from 'react-native-paper';

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { Text, View } from "@/components/Themed";
import TagsInput from "@/components/TagsInput";
import DurationPicker from "@/components/DurationPicker";
import { capitalize } from "@/helpers/capitalize";
import { formatTime } from "@/helpers/format-time";

interface ErrorTextProps {
  children: React.ReactNode;
}

export const ErrorText = ({ children }: ErrorTextProps) => (
  <Text style={styles.errorText}>{children}</Text>
);

interface Props {
  onSubmit: any;
  existingTags: any[];
  initialValues: any;
}

export const HabitForm = ({
  onSubmit,
  existingTags = [],
  projects,
  initialValues = {},
}: Props) => {
  const theme = useTheme();
  const colorScheme = useColorScheme();
  const [newTag, setNewTag] = useState("");
  const [timely, setTimely] = useState(!!initialValues.timelinessDurationInSeconds);
  const suggestions = getSuggestions(newTag, existingTags);

  const [visible, setVisible] = React.useState(false)
  const onDismiss = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])

  // Period Time Dropdown
  const [openPeriod, setOpenPeriod] = useState(false);

  // Period Day Dropdown
  const [openPeriodDay, setOpenPeriodDay] = useState(false);

  // Dropdown
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(projects.map(p => ({
    label: p.name,
    value: p.id,
    icon: () => (
      <FontAwesome5
        name={p.icon}
        size={35}
        style={{ color: p.color_hex }}
      />
    ),
  })));

  const onSubmitMiddleware = (
    formikValues,
    formikHelpers,
  ) => {
    const newFormikValues = {
      ...formikValues,
    };

    if (!timely) {
      newFormikValues.timelinessDurationInSeconds = null;
    }

    // Only allow period_start_day for periodic weekly habits
    if (!newFormikValues.fromPeriodStart || newFormikValues.frequencyPeriod !== 'week') {
      newFormikValues.periodStartDay = null;
    }

    // Only allow from_period_start for weekly/daily, periodic habits
    if (newFormikValues.fromPeriodStart && ['week', 'day'].includes(newFormikValues.frequencyPeriod)) {
      const {
        periodStartHours,
        periodStartMinutes,
      } = newFormikValues;

      const startTime = new Date();
      startTime.setHours(periodStartHours);
      startTime.setMinutes(periodStartMinutes);
      newFormikValues.periodStartTime = startTime;
    } else {
      newFormikValues.periodStartTime = null;
    }

    delete newFormikValues.periodStartHours;
    delete newFormikValues.periodStartMinutes;

    return onSubmit(newFormikValues, formikHelpers);
  }

  if (initialValues.periodStartTime) {
    const startTime = new Date(initialValues.periodStartTime);
    initialValues.periodStartHours = startTime.getHours();
    initialValues.periodStartMinutes = startTime.getMinutes();
    delete initialValues.periodStartTime;
  }

  return (
    <Formik
      initialValues={{
        name: "",
        link: "",
        frequencyPeriod: "day",
        frequencyTarget: 1,
        frequencyNumber: 1,
        isStrict: true,
        fromPeriodStart: true,
        importanceScalar: "",
        tagsArray: [],
        durationInMinutes: 10,
        periodStartDay: "sunday",
        periodStartHours: 0,
        periodStartMinutes: 0,
        notifyWhenDue: false,
        projectId: undefined,
        timelinessDurationInSeconds: undefined,
        ...initialValues,
      }}
      onSubmit={onSubmitMiddleware}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <KeyboardAwareScrollView contentContainerStyle={styles.container}>
          <View style={styles.formGroup}>
            <TextInput
              label="Name"
              autoCapitalize="words"
              value={values.name}
              onBlur={handleBlur("name")}
              onChangeText={handleChange("name")}
            />

            {touched.name && errors.name && (
              <HelperText type="error" visible={true}>
                {errors.name}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ flexDirection: 'row' }}>
              <PaperText variant="labelLarge" style={styles.newLabel}>Type</PaperText>
              <Popover
                from={
                  <TouchableOpacity style={styles.popoverContainer}>
                    <FontAwesome5
                      name="info-circle"
                      size={20}
                      style={{ color: Colors[colorScheme].text }}
                    />
                  </TouchableOpacity>
                }
              >
                <Text style={styles.popover}>
                  A strict habit is either done or undone, depending on the target
                  frequency. Use it for habits that are non-negotiable; e.g.
                  &quot;feed the cats once per day&quot;. A flexible habit is one
                  that will be assigned an urgency score that goes up
                  indefinitely. Use this for habits that are not as timely; e.g.
                  &quot;play guitar once per week&quot;.
                </Text>
              </Popover>
            </View>

            <SegmentedButtons
              value={values.isStrict ? 'strict' : 'flexible'}
              onValueChange={(val) => setFieldValue("isStrict", val === 'strict')}
              buttons={[
                {
                  value: 'strict',
                  label: 'Strict',
                },
                {
                  value: 'flexible',
                  label: 'Flexible',
                },
              ]}
            />

            {touched.isStrict && errors.isStrict && (
              <HelperText type="error" visible={true}>
                {errors.isStrict}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ flexDirection: 'row' }}>
              <PaperText variant="labelLarge" style={styles.newLabel}>Recurrence</PaperText>
              <Popover
                from={
                  <TouchableOpacity style={styles.popoverContainer}>
                    <FontAwesome5
                      name="info-circle"
                      size={20}
                      style={{ color: Colors[colorScheme].text }}
                    />
                  </TouchableOpacity>
                }
              >
                <Text style={styles.popover}>
                  A &quot;rolling&quot; habit will look at the previous period
                  from a point in time, to determine if a habit has occurred; e.g.
                  looking at &quot;clean the cat fountain every week&quot; on a
                  Tuesday at 2:45pm will search for an occurrence of the habit
                  between the previous Tuesday at 2:45pm and the present moment.
                  Habits that are calculated from period start use the beginning
                  of the previous period as a start date. Use these for daily
                  habits that reset every day at midnight, or weekly habits that
                  reset every week on Sunday.
                </Text>
              </Popover>
            </View>

            <SegmentedButtons
              value={values.fromPeriodStart ? 'periodic' : 'rolling'}
              onValueChange={(val) => setFieldValue("fromPeriodStart", val === 'periodic')}
              buttons={[
                {
                  value: 'periodic',
                  label: 'Periodic',
                },
                {
                  value: 'rolling',
                  label: 'Rolling',
                },
              ]}
            />

            {touched.fromPeriodStart && errors.fromPeriodStart && (
              <HelperText type="error" visible={true}>
                {errors.fromPeriodStart}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Do habit</PaperText>

              <NumericInput
                minValue={1}
                containerStyle={{ marginRight: 10 }}
                totalHeight={40}
                value={values.frequencyTarget}
                onChange={(value) => setFieldValue("frequencyTarget", value)}
                inputStyle={{ color: Colors[colorScheme].text }}
              />
              <PaperText variant="labelLarge">{pluralize("time", values.frequencyTarget)}...</PaperText>
            </View>

            {touched.frequencyTarget && errors.frequencyTarget && (
              <HelperText type="error" visible={true}>
                {errors.frequencyTarget}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>...every</PaperText>

              <NumericInput
                minValue={1}
                totalHeight={40}
                containerStyle={{ marginRight: 10 }}
                value={values.frequencyNumber}
                onChange={(value) => setFieldValue("frequencyNumber", value)}
                inputStyle={{ color: Colors[colorScheme].text }}
              />

              <DropDownPicker
                placeholder="Select a period"
                listMode="SCROLLVIEW"
                dropDownDirection="TOP"
                open={openPeriod}
                style={{
                  width: 100,
                  minHeight: 40,
                  backgroundColor: theme.colors.surfaceVariant,
                  borderColor: theme.colors.border,
                }}
                containerStyle={{ width: 100 }}
                value={values.frequencyPeriod}
                items={periods.map((period) => ({
                  value: period,
                  label: pluralize(period, Number.parseInt(values.frequencyNumber)),
                }))}
                setOpen={setOpenPeriod}
                onSelectItem={({ value }) => setFieldValue("frequencyPeriod", value)}
              />
            </View>

            {touched.frequencyNumber && errors.frequencyNumber && (
              <HelperText type="error" visible={true}>
                {errors.frequencyNumber}
              </HelperText>
            )}
          </View>

          {!!values.fromPeriodStart && ["day", "week"].includes(values.frequencyPeriod) && (
            <Animated.View style={styles.formGroup} entering={ZoomInEasyUp} exiting={ZoomOutEasyUp}>
              <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Starting at...</PaperText>

                <PaperButton onPress={() => setVisible(true)} uppercase={false} mode="outlined">
                  {formatTime({
                    hours: values.periodStartHours,
                    minutes: values.periodStartMinutes,
                  })}
                </PaperButton>

                <TimePickerModal
                  visible={visible}
                  onDismiss={onDismiss}
                  onConfirm={({ hours, minutes }) => {
                    setVisible(false);
                    setFieldValue("periodStartHours", hours);
                    setFieldValue("periodStartMinutes", minutes);
                  }}
                  hours={values.periodStartHours}
                  minutes={values.periodStartMinutes}
                />
              </View>

              {touched.periodStartHours && errors.periodStartHours && (
                <HelperText type="error" visible={true}>
                  {errors.periodStartHours}
                </HelperText>
              )}

              {touched.periodStartMinutes && errors.periodStartMinutes && (
                <HelperText type="error" visible={true}>
                  {errors.periodStartMinutes}
                </HelperText>
              )}
            </Animated.View>
          )}

          {!!values.fromPeriodStart && ["week"].includes(values.frequencyPeriod) && (
            <Animated.View style={styles.formGroup} entering={ZoomInEasyUp} exiting={ZoomOutEasyUp}>
              <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
                <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Starting on...</PaperText>

                <DropDownPicker
                  placeholder="Select a period"
                  listMode="SCROLLVIEW"
                  dropDownDirection="TOP"
                  open={openPeriodDay}
                  style={{
                    width: 140,
                    minHeight: 40,
                    backgroundColor: theme.colors.surfaceVariant,
                    borderColor: theme.colors.border,
                  }}
                  containerStyle={{ width: 140 }}
                  value={values.periodStartDay}
                  items={daysOfWeek.map((day) => ({
                    value: day,
                    label: capitalize(day),
                  }))}
                  setOpen={setOpenPeriodDay}
                  onSelectItem={({ value }) => setFieldValue("periodStartDay", value)}
                />
              </View>

              {touched.periodStartDay && errors.periodStartDay && (
                <HelperText type="error" visible={true}>
                  {errors.periodStartDay}
                </HelperText>
              )}

            </Animated.View>
          )}

          <View style={styles.formGroup}>
            <View style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>It takes</PaperText>

              <NumericInput
                totalHeight={40}
                containerStyle={{ marginRight: 10 }}
                value={values.durationInMinutes}
                onChange={(value) => setFieldValue("durationInMinutes", value)}
                inputStyle={{ color: Colors[colorScheme].text }}
              />

              <PaperText variant="labelLarge">
                {pluralize("minute", values.durationInMinutes)}.
              </PaperText>
            </View>

            <HelperText type="error" visible={touched.durationInMinutes && errors.durationInMinutes}>
              {errors.durationInMinutes}
            </HelperText>
          </View>

          <View style={styles.formGroup}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Notify when due:</PaperText>

              <PaperSwitch
                value={values.notifyWhenDue}
                onValueChange={(val) => setFieldValue("notifyWhenDue", val)}
              />
            </View>

            {touched.notifyWhenDue && errors.notifyWhenDue && (
              <HelperText type="error" visible={true}>
                {errors.notifyWhenDue}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Timely:</PaperText>

              <PaperSwitch
                value={timely}
                onValueChange={(val) => setTimely(val)}
              />

              <Popover
                from={
                  <TouchableOpacity style={styles.popoverContainer}>
                    <FontAwesome5
                      name="info-circle"
                      size={20}
                      style={{ color: Colors[colorScheme].text }}
                    />
                  </TouchableOpacity>
                }
              >
                <Text style={styles.popover}>
                  Timely habits will float to the top of the list for the duration
                  of their timeliness.
                </Text>
              </Popover>
            </View>

            {touched.timely && errors.timely && (
              <HelperText type="error" visible={true}>
                {errors.timely}
              </HelperText>
            )}
          </View>

          {timely && (
            <View style={styles.formGroup}>
              <DurationPicker
                seconds={values.timelinessDurationInSeconds}
                onChange={(value) => {
                  setFieldValue('timelinessDurationInSeconds', value);
                }}
              />
            </View>
          )}


          <View style={styles.formGroup}>
            <TextInput
              label="Link"
              autoCapitalize="none"
              value={values.link}
              onBlur={handleBlur("link")}
              onChangeText={handleChange("link")}
            />

            {touched.link && errors.link && (
              <HelperText type="error" visible={true}>
                {errors.link}
              </HelperText>
            )}

            <HelperText>
              If provided, the habit name will be clickable. You can use
              http:// and https:// to open a browser, or different app schemas,
              for deep linking; e.g. &quot;supercoolapp://&quot; will open SuperCool.app
            </HelperText>
          </View>

          <View style={styles.formGroup}>
            <TextInput
              label="Importance Scalar"
              keyboardType="numeric"
              returnKeyType='done'
              autoCapitalize="none"
              onChangeText={handleChange("importanceScalar")}
              onBlur={handleBlur("importanceScalar")}
              value={values.importanceScalar}
            />

            <HelperText>
              The importance scalar linearly influences how quickly the
              urgency score goes us over time. If left empty, it defaults to 1.
            </HelperText>
          </View>

          <View style={styles.formGroup}>
            <DropDownPicker
              placeholder="Select a project"
              listMode="SCROLLVIEW"
              dropDownDirection="TOP"
              style={{
                backgroundColor: theme.colors.surfaceVariant,
                borderColor: theme.colors.border,
              }}
              open={open}
              value={values.projectId}
              items={items}
              setOpen={setOpen}
              onSelectItem={({ value }) => setFieldValue("projectId", value)}
              setItems={setItems}
            />

            {touched.project && errors.project && (
              <HelperText type="error" visible={true}>
                {errors.project}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <TagsInput
              updateState={({ tag, tagsArray }) => {
                setNewTag(tag);
                setFieldValue("tagsArray", tagsArray);
              }}
              tags={{ tag: newTag, tagsArray: values.tagsArray }}
              containerStyle={{
                backgroundColor: theme.colors.surfaceVariant,
                borderColor: theme.colors.border,
                borderWidth: 1,
                borderRadius: 10
              }}
              placeholder="Add some tags..."
              labelStyle={{ color: Colors[colorScheme].text }}
              label="Press comma & space to add a tag"
              leftElement={<FontAwesome5 name="tag" />}
              leftElementContainerStyle={{ marginLeft: 3 }}
              inputContainerStyle={styles.textInput}
              inputStyle={{
                color: Colors[colorScheme].text
              }}
              autoCorrect={false}
              tagStyle={styles.tag}
              keysForTag={", "}
              autoCapitalize={"none"}
              customElement={
                <View>
                  {suggestions.length > 0
                    ? suggestions.map((suggestion) => {
                        return (
                          <TouchableHighlight
                            onPress={() => {
                              setNewTag("");
                              setFieldValue("tagsArray", [
                                ...values.tagsArray,
                                suggestion.name,
                              ]);
                            }}
                            key={suggestion.id}
                          >
                            <Text>{suggestion.name}</Text>
                          </TouchableHighlight>
                        );
                      })
                    : undefined}
                </View>
              }
            />

            {touched.tagList && errors.tagList && (
              <HelperText type="error" visible={true}>
                {errors.tagList}
              </HelperText>
            )}
          </View>

          <PaperButton
            icon="content-save"
            mode="contained"
            loading={isSubmitting}
            onPress={handleSubmit}
          >
            Submit
          </PaperButton>
        </KeyboardAwareScrollView>
      )}
    </Formik>
  );
};

const periods = ["hour", "day", "week", "month", "year"];
const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const getSuggestions = (newTag, existingTags) => {
  if (newTag === "") {
    return [];
  }

  const suggestions = existingTags.filter((existingTag) => {
    return existingTag.name.includes(newTag);
  });

  return suggestions;
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 100,
  },
  popover: {
    padding: 10,
    color: "black",
  },
  popoverContainer: {
    marginLeft: 5,
  },
  textInput: {
    height: 40,
    marginTop: 8,
    borderRadius: 5,
  },
  tag: {
    backgroundColor: "#fff",
  },
  errorText: {
    color: "red",
    marginVertical: 10,
  },
  formGroup: {
    marginBottom: 30,
  },
  newLabel: {
    marginBottom: 8,
  }
});

export default HabitForm;

import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { CheckBox } from "@rneui/themed";

import { View, Text } from "@/components/Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";

interface Props {
  purchasePackage: Record<string, unknown>;
  isSelected: boolean;
  onSelect: any;
}

export const PackageItem = ({
  purchasePackage,
  isSelected,
  onSelect,
}: Props) => {
  const colorScheme = useColorScheme();

  const {
    packageType,
    product: { title, priceString },
  } = purchasePackage;

  const getPrice = () => {
    // Package types:
    // UNKNOWN
    // CUSTOM
    // LIFETIME
    // ANNUAL
    // SIX_MONTH
    // THREE_MONTH
    // TWO_MONTH
    // MONTHLY
    // WEEKLY
    switch (packageType) {
      case "MONTHLY": {
        return `${priceString}/month`;
      }
      case "ANNUAL": {
        return `${priceString}/year`;
      }
      case "SIX_MONTH": {
        return `${priceString}/half-year`;
      }
      case "THREE_MONTH": {
        return `${priceString}/quarter`;
      }
      default: {
        return priceString;
      }
    }
  };

  return (
    <Pressable onPress={() => onSelect(purchasePackage)}>
      <View
        style={[
          styles.container,
          isSelected ? styles.selectedContainer : {},
          {
            backgroundColor: Colors[colorScheme].cardBackground,
          },
        ]}
      >
        <Text style={styles.title}>{title}</Text>
        <Text>Get 14 days free, then</Text>
        <Text style={styles.price}>{getPrice()}</Text>

        <CheckBox right containerStyle={styles.checkbox} checked={isSelected} />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: 10,
    borderRadius: 10,
    borderWidth: 4,
    borderColor: "transparent",
  },
  selectedContainer: {
    borderColor: "lightblue",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    marginBottom: 20,
  },
  price: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },
  checkbox: {
    backgroundColor: "transparent",
    marginBottom: 0,
    padding: 0,
  },
});

export default PackageItem;

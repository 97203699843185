import React, { Suspense } from "react";
import { StyleSheet } from "react-native";

import ProjectsList from "@/components/ProjectsList/ProjectsList";
import ProjectsListContainer from "@/components/ProjectsList";
import { View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";

export default function ProjectsScreen() {
  return (
    <View style={styles.container}>
      <ErrorBoundary>
        <BannerAd />

        <View style={styles.contentContainer}>
          <Suspense fallback={<ProjectsList isLoading />}>
            <ProjectsListContainer />
          </Suspense>
        </View>
      </ErrorBoundary>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 10,
  },
});

import React from "react";
import useSWR from "swr";

import { VICES_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import ViceEdit from "./ViceEdit";

interface Props {
  viceId: any;
}

export function ViceEditContainer({ viceId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data } = useSWR(`${VICES_URL}/${viceId}`, fetcher, {
    suspense: true,
  });

  if (!data) return;

  return <ViceEdit vice={data.vice} />;
}

export default ViceEditContainer;

import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import TimelineScreen from "@/screens/TimelineScreen";
import OccurrenceEditScreen from "@/screens/OccurrenceEditScreen";
import IncidentEditScreen from "@/screens/IncidentEditScreen";

const TimelineStack = createNativeStackNavigator();

export function TimelineStackScreen() {
  return (
    <TimelineStack.Navigator backBehavior="order">
      <TimelineStack.Screen
        name="TimelineList"
        component={TimelineScreen}
        options={{
          title: "Timeline",
        }}
      />

      <TimelineStack.Screen
        name="OccurrenceEdit"
        component={OccurrenceEditScreen}
        options={{
          title: "Occurrence Edit",
        }}
      />

      <TimelineStack.Screen
        name="IncidentEdit"
        component={IncidentEditScreen}
        options={{
          title: "Incident Edit",
        }}
      />
    </TimelineStack.Navigator>
  );
}

export default TimelineStackScreen;

import React from "react";
import useSWR from "swr";

import { HEALTH_AUTOMATIONS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import HealthAutomationEdit from "./HealthAutomationEdit";

interface Props {
  healthAutomationId: any;
}

export function HealthAutomationEditContainer({ healthAutomationId }: Props) {
  const fetcher = useRequestAndSetTokens();

  const { data } = useSWR(`${HEALTH_AUTOMATIONS_URL}/${healthAutomationId}`, fetcher, { suspense: true });

  if (!data) return;

  return <HealthAutomationEdit healthAutomation={data.health_automation} />;
}

export default HealthAutomationEditContainer;

import React, { useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { useSWRConfig } from "swr";
import { Button as PaperButton } from "react-native-paper";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";

import { INCIDENTS_URL, VICES_URL } from "@/helpers/api";
import Colors from "@/constants/Colors";
import { View, Text } from "@/components/Themed";
import useColorScheme from "@/hooks/useColorScheme";
import CountUp from "@/components/CountUp";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import logger from "@/helpers/logger";
import { selectionAsync } from "@/helpers/haptics";

interface Props {
  vice: any;
}

export const ViceCard = ({ vice }: Props) => {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();
  const [isFetching, setIsFetching] = useState(false);
  const colorScheme = useColorScheme();
  const { mutate } = useSWRConfig();

  const onView = () => {
    navigation.navigate("ViceView", { id: vice.id });
  };

  // For creating incident
  const onReset = async () => {
    selectionAsync();
    setIsFetching(true);

    try {
      await requestAndSetTokens(INCIDENTS_URL, {
        method: "POST",
        body: {
          vice_id: vice.id,
          occurred_at: new Date(),
        },
      });

      Toast.show({
        type: "success",
        text1: "Reset",
        text2: "Get right back on the horse!",
        position: "bottom",
      });

      setIsFetching(false);

      mutate(VICES_URL);
    } catch (error) {
      setIsFetching(false);

      logger.error("Error creating incident", { error });
    }
  };

  return (
    <TouchableOpacity onPress={onView}>
      <View
        style={[
          styles.container,
          { backgroundColor: Colors[colorScheme].cardBackground },
        ]}
      >
        <Text style={styles.title}>{vice.name}</Text>
        <FontAwesome5
          style={styles.icon}
          name={vice.icon}
          size={50}
          color={Colors[colorScheme].text}
        />
        <CountUp
          style={styles.countUp}
          startTime={vice?.last_incident?.occurred_at}
        />
        <PaperButton
          mode="contained"
          style={styles.button}
          loading={isFetching}
          disabled={isFetching}
          onPress={onReset}
        >
          Reset
        </PaperButton>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
    padding: 10,
    borderRadius: 10,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
  },
  icon: {
    textAlign: "center",
    marginBottom: 20,
  },
  countUp: {
    marginBottom: 10,
  },
  button: {
    alignSelf: "flex-end",
  },
});

export default ViceCard;

import React from "react";
import useSWR from "swr";
import {
  ActivityIndicator,
  StyleSheet,
} from "react-native";

import { PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";

import HabitForm from "./HabitForm";

interface Props {
  onSubmit: any;
  existingTags: any[];
}

export function HabitFormContainer({ onSubmit, existingTags, initialValues }: Props) {
  const fetcher = useRequestAndSetTokens();

  const { data, isLoading } = useSWR(PROJECTS_URL, fetcher);

  if (isLoading || !data) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <HabitForm
      projects={data.projects}
      onSubmit={onSubmit}
      existingTags={existingTags}
      initialValues={initialValues}
    />
  );
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 50,
  },
});

export default HabitFormContainer;

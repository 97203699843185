import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Formik } from "formik";
import { FontAwesome5 } from "@expo/vector-icons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import DropDownPicker from 'react-native-dropdown-picker';
import {
  Button as PaperButton,
  Text as PaperText,
  HelperText,
  Switch as PaperSwitch,
} from 'react-native-paper';
import { useTheme } from 'react-native-paper';

import { View } from "@/components/Themed";
import data from "@/components/AutomationCreate/HealthSelector/data";

interface Props {
  onSubmit: any;
  initialValues: any;
}

export const HealthAutomationForm = ({
  onSubmit,
  initialValues = {},
}: Props) => {
  const theme = useTheme();

  // Automation Type Dropdown
  const [open, setOpen] = useState(false);

  const onSubmitMiddleware = (
    formikValues,
    formikHelpers,
  ) => {
    const newFormikValues = {
      ...formikValues,
    };

    return onSubmit(newFormikValues, formikHelpers);
  }

  return (
    <Formik
      initialValues={{
        automationType: undefined,
        shouldConfirm: true,
        ...initialValues,
      }}
      onSubmit={onSubmitMiddleware}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <KeyboardAwareScrollView contentContainerStyle={styles.container}>
          <View style={styles.formGroup}>
            <DropDownPicker
              placeholder="Select an automation type"
              listMode="MODAL"
              searchable
              style={{
                backgroundColor: theme.colors.surfaceVariant,
                borderColor: theme.colors.border,
              }}
              open={open}
              value={values.automationType}
              items={data.map((datum) => ({
                value: datum.automationType,
                label: datum.label,
                icon: datum.icon || (() => <FontAwesome5 name="robot" />),
              }))}
              setOpen={setOpen}
              onSelectItem={({ value }) => setFieldValue("automationType", value)}
            />

            {touched.automationType && errors.automationType && (
              <HelperText type="error" visible={true}>
                {errors.automationType}
              </HelperText>
            )}
          </View>

          <View style={styles.formGroup}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <PaperText variant="labelLarge" style={{ marginRight: 10 }}>Confirm before completing:</PaperText>

              <PaperSwitch
                value={values.shouldConfirm}
                onValueChange={(val) => setFieldValue("shouldConfirm", val)}
              />
            </View>

            {touched.shouldConfirm && errors.shouldConfirm && (
              <HelperText type="error" visible={true}>
                {errors.shouldConfirm}
              </HelperText>
            )}
          </View>

          <PaperButton
            icon="content-save"
            mode="contained"
            loading={isSubmitting}
            onPress={handleSubmit}
          >
            Submit
          </PaperButton>
        </KeyboardAwareScrollView>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 100,
  },
  formGroup: {
    marginBottom: 30,
  },
});

export default HealthAutomationForm;

import React from "react";
import { ActivityIndicator, StyleSheet, Alert, Platform } from "react-native";
import Toast from "react-native-toast-message";
import { mutate } from "swr";
import { Button } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import camelCase from "camelcase";

import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { TAGS_URL, OCCURRENCES_URL, VICES_URL } from "@/helpers/api";
import ViceForm from "@/components/ViceForm";
import logger from "@/helpers/logger";
import { selectionAsync } from "@/helpers/haptics";

const createAlert = (onDelete) =>
  Alert.alert("Delete Confirm", "Are you sure you want to delete this vice?", [
    {
      text: "Cancel",
      style: "cancel",
    },
    { text: "Delete", onPress: onDelete },
  ]);

interface Props {
  vice: any;
  isLoading?: boolean;
}

export function ViceEdit({ isLoading = false, vice }: Props) {
  const navigation = useNavigation();
  const requestAndSetTokens = useRequestAndSetTokens();

  const onSubmit = async ({ name, icon }, formikHelpers) => {
    selectionAsync();
    formikHelpers.setSubmitting(true);

    try {
      const url = `${VICES_URL}/${vice.id}`;
      const json = await requestAndSetTokens(url, {
        method: "PATCH",
        body: {
          vice: {
            name,
            icon,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Vice updated! 😻",
        position: "bottom",
      });

      mutate(TAGS_URL);
      mutate(VICES_URL);
      mutate(url);

      navigation.navigate("ViceView", { id: json.vice.id });
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while updating the vice.",
          position: "bottom",
        });
      }

      formikHelpers.setSubmitting(false);

      logger.error("Error updating vice", { error });
    }
  };

  React.useLayoutEffect(() => {
    const onDelete = async () => {
      selectionAsync();

      try {
        await requestAndSetTokens(`${VICES_URL}/${vice.id}`, {
          method: "DELETE",
        });

        navigation.goBack();
        mutate(OCCURRENCES_URL);
        mutate(VICES_URL);
        Toast.show({
          type: "success",
          text1: "Success",
          text2: "Vice deleted! 🪦",
          position: "bottom",
        });
      } catch (error) {
        logger.error("Error deleting vice", { error });
      }
    };

    if (vice) {
      navigation.setOptions({
        headerTitle: `Edit: ${vice.name}`,
      });
    }

    navigation.setOptions({
      headerRight: () => (
        <Button type="clear" onPress={() => createAlert(onDelete)}>
          <FontAwesome5 name="trash" size={30} style={{ color: "red" }} />
        </Button>
      ),
    });
  }, [navigation, vice, requestAndSetTokens]);

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" />
      ) : (
        <View>
          <ViceForm
            onSubmit={onSubmit}
            existingTags={[]}
            initialValues={{
              name: vice.name,
              icon: vice.icon,
            }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
});

export default ViceEdit;

/* eslint-disable react/prop-types */

import React from "react";
import { View, StyleSheet } from "react-native";
import Onboarding from "react-native-onboarding-swiper";
import { FontAwesome5 } from "@expo/vector-icons";
import { Fontisto } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useStoreActions } from "easy-peasy";

import storage from "@/helpers/storage-general";

const SIZE = 140;

export function OnboardingScreen() {
  const setHasCompletedOnboarding = useStoreActions(
    (actions) => actions.general.setHasCompletedOnboarding,
  );

  const completeOnboarding = async () => {
    setHasCompletedOnboarding(true);

    await storage.set("hasCompletedOnboarding", true)
  };

  return (
    <View style={styles.container}>
      <Onboarding
        onDone={() => completeOnboarding()}
        onSkip={() => completeOnboarding()}
        pages={[
          {
            backgroundColor: "#fff",
            image: (
              <FontAwesome5
                style={{ padding: 10 }}
                name="hat-cowboy"
                size={SIZE}
                color="black"
              />
            ),
            title: "Howdy!",
            subtitle:
              "Welcome to The Quantified Self, where we help you reinforce your habits, wrangle your recurring tasks, and regulate your.",
          },
          {
            backgroundColor: "#fe6e58",
            image: (
              <Fontisto
                style={{ padding: 10 }}
                name="redo"
                size={SIZE}
                color="black"
              />
            ),
            title: "Habituate",
            subtitle:
              "Add new habits at exactly the right cadence. See them in order of urgency, as you define it.",
          },
          {
            backgroundColor: "#CBC3E3",
            image: (
              <FontAwesome5
                style={{ padding: 10 }}
                name="robot"
                size={SIZE}
                color="black"
              />
            ),
            title: "Automate",
            subtitle:
              "Set automations based on workouts and location. Write NFC Tags for easy completion at a tap. Coming soon: automation based on mindfulness, weather, and more!",
          },
          {
            backgroundColor: "#CBC3E3",
            image: (
              <FontAwesome5
                style={{ padding: 10 }}
                name="chart-line"
                size={SIZE}
                color="black"
              />
            ),
            title: "Monitor",
            subtitle:
              "Keep track of the relative urgency of your different categories of habits, and right-size the amount of habits that you maintain according to your available bandwidth.",
          },
          {
            backgroundColor: "#999",
            image: (
              <MaterialCommunityIcons
                style={{ padding: 10 }}
                name="emoticon-devil-outline"
                size={SIZE}
                color="black"
              />
            ),
            title: "Victory over Vices",
            subtitle: "Track and tackle your vices. Replace bad habits with good ones.",
          },
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default OnboardingScreen;

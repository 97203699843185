import React from "react";
import { ActivityIndicator, Platform, StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import camelCase from "camelcase";
import { useStoreActions } from "easy-peasy";

import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { USERS_URL } from "@/helpers/api";
import ProfileForm from "./ProfileForm";
import logger from "@/helpers/logger";
import { selectionAsync } from "@/helpers/haptics";

interface Props {
  user: any;
  isLoading?: boolean;
}

export function Profile({ isLoading = false, user }: Props) {
  const requestAndSetTokens = useRequestAndSetTokens();
  const refreshUser = useStoreActions((actions) => actions.session.refreshUser);

  const onSubmit = async (
    formikValues,
    formikHelpers,
  ) => {
    selectionAsync();

    const {
      averageDailyFreetimeInMinutes: average_daily_freetime_in_minutes,
      bandwidthBufferPercentage: bandwidth_buffer_percentage,
    } = formikValues;
    formikHelpers.setSubmitting(true);

    try {
      await requestAndSetTokens(`${USERS_URL}/${user.id}`, {
        method: "PATCH",
        body: {
          user: {
            average_daily_freetime_in_minutes,
            bandwidth_buffer_percentage,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Profile updated! 😻",
        position: "bottom",
      });

      // Update all the cached stuff
      await refreshUser();
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        logger.error("Error updating profile", { error: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        logger.error("Error-updating-profile", { error });

        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while updating your profile.",
          position: "bottom",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View>
        <ProfileForm
          onSubmit={onSubmit}
          initialValues={{
            averageDailyFreetimeInMinutes: user.average_daily_freetime_in_minutes,
            bandwidthBufferPercentage: user.bandwidth_buffer_percentage,
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  loader: {
    marginTop: 100,
  },
});

export default Profile;

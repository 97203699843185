/* eslint-disable react/prop-types */

import React from "react";
import { StyleSheet } from "react-native";
import { Button, Input } from "@rneui/themed";
import { useStoreActions } from "easy-peasy";
import * as Linking from "expo-linking";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";

import { View, Text } from "@/components/Themed";
import { logger } from "@/helpers/logger";

export function PasswordResetScreen() {
  const navigation = useNavigation();
  const [email, setEmail] = React.useState("");

  const resetPasswordAction = useStoreActions(
    (actions) => actions.session.resetPassword,
  );

  const resetPassword = async () => {
    try {
      const redirectUrl = Linking.createURL("new_password");

      await resetPasswordAction({ email, redirectUrl });

      Toast.show({
        type: "success",
        text1: "Success!",
        text2:
          "An email has been sent containing instructions for resetting your password.",
        position: "bottom",
      });
    } catch (error) {
      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: "An error occurred while resetting password.",
        position: "bottom",
      });

      logger.error("unable-to-reset-password", { error });
    }
  };

  const onPressSignUp = () => {
    navigation.navigate("SignUp");
  };

  const onPressSignIn = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={styles.container}>
      <Input
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
      />

      <View
        style={{
          paddingHorizontal: 20,
          marginBottom: 20,
        }}
      >
        <Button title="Reset Password" onPress={() => resetPassword()} />
      </View>

      <View
        style={{
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Remembered your password?</Text>
        <Button type="clear" title="Sign in!" onPress={() => onPressSignIn()} />
      </View>

      <View
        style={{
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Don&apos;t have an account?</Text>
        <Button type="clear" title="Sign up!" onPress={() => onPressSignUp()} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 20,
  },
});

export default PasswordResetScreen;

import React from "react";
import useSWR from "swr";

import { TAGS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import Upgrade from "./Upgrade";

export function UpgradeContainer() {
  const fetcher = useRequestAndSetTokens();
  const { data, mutate } = useSWR(TAGS_URL, fetcher, { suspense: true });

  if (!data) return;

  return <Upgrade mutate={mutate} existingTags={data.tags} />;
}

export default UpgradeContainer;

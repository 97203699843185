import React, { useCallback } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { List } from 'react-native-paper';

import { FlatList } from "@/components/Themed";
import EmptyList from "@/components/EmptyList";

interface Props {
  vices: Record<string, unknown>[];
  isLoading: boolean;
  mutate: any;
}

export function ProjectsList({
  mutate,
  projects = [],
  isLoading = false,
}: Props) {
  const navigation = useNavigation();

  const renderItem = useCallback(({ item }) => {
    return (
      <List.Item
        title={item.name}
        onPress={() => {
          navigation.navigate("ProjectEdit", { id: item.id });
        }}
        left={props => (
          <FontAwesome5
            {...props}
            name={item.icon}
            size={35}
            style={{ color: item.color_hex }}
          />
        )}
      />
    );
  }, [navigation]);

  if (!projects || isLoading) {
    return (
      <ActivityIndicator style={styles.loader} size="large" />
    );
  };

  return (
    <FlatList
      contentContainerStyle={styles.list}
      data={projects}
      keyExtractor={(item) => item.id.toString()}
      onRefresh={() => mutate()}
      refreshing={false}
      renderItem={renderItem}
      ListEmptyComponent={<EmptyList noun='projects' />}
    />
  );
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  list: {
    paddingBottom: 150,
    paddingHorizontal: 10,
  },
});

export default ProjectsList;

import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import ProjectsScreen from "@/screens/ProjectsScreen";
import ProjectEditScreen from "@/screens/ProjectEditScreen";
import AccountDeletion from "@/screens/AccountDeletionScreen";
import Profile from "@/screens/ProfileScreen";
import EditNotifications from "@/screens/EditNotificationsScreen";
import SettingsScreen from "@/screens/SettingsScreen";

const SettingsStack = createNativeStackNavigator();

export function SettingsStackScreen() {
  return (
    <SettingsStack.Navigator backBehavior="order">
      <SettingsStack.Screen
        name="SettingsList"
        component={SettingsScreen}
        options={{ title: "Settings" }}
      />

      <SettingsStack.Screen
        name="ProjectsList"
        component={ProjectsScreen}
        options={{ title: "Projects" }}
      />

      <SettingsStack.Screen
        name="ProjectEdit"
        component={ProjectEditScreen}
        options={{
          title: "Project Edit",
        }}
      />

      <SettingsStack.Screen
        name="Profile"
        component={Profile}
        options={{ title: "Profile" }}
      />

      <SettingsStack.Screen
        name="Notifications"
        component={EditNotifications}
        options={{ title: "Notifications" }}
      />

      <SettingsStack.Screen
        name="AccountDeletion"
        component={AccountDeletion}
        options={{ title: "Account Deletion" }}
      />
    </SettingsStack.Navigator>
  );
}

export default SettingsStackScreen;

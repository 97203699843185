import React, { Suspense } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { Button } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from 'react-native-paper';

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { FontAwesome, View } from "@/components/Themed";
import ErrorBoundary from "@/components/ErrorBoundary";
import Timeline from "@/components/Timeline/Timeline";
import TimelineContainer from "@/components/Timeline";

interface Props {
  vice: Record<string, unknown>;
  isLoading?: boolean;
}

export function ViceView({ isLoading = false, vice }: Props) {
  const theme = useTheme();
  const navigation = useNavigation();
  const colorScheme = useColorScheme();

  React.useLayoutEffect(() => {
    if (vice) {
      navigation.setOptions({
        headerTitle: vice.name,
      });
    }

    navigation.setOptions({
      headerRight: () => (
        <Button
          type="clear"
          onPress={() => navigation.navigate("ViceEdit", { id: vice.id })}
        >
          <FontAwesome
            name="edit"
            size={30}
            style={{ color: theme.colors.primary }}
          />
        </Button>
      ),
    });
  }, [navigation, vice, theme.colors.primary]);

  return (
    <View style={styles.container}>
      {isLoading ? (
        <ActivityIndicator size="large" />
      ) : (
        <View>
          <FontAwesome5
            style={styles.icon}
            name={vice.icon}
            size={50}
            color={Colors[colorScheme].text}
          />

          <ErrorBoundary>
            <Suspense fallback={<Timeline isLoading />}>
              <TimelineContainer
                viceParams={{
                  vice_id: vice.id,
                  limit: 5,
                }}
              />
            </Suspense>
          </ErrorBoundary>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  icon: {
    textAlign: "center",
    marginBottom: 20,
    marginTop: 20,
  },
});

export default ViceView;

import React from "react";
import { StyleSheet } from "react-native";
import { Formik } from "formik";
import { Input } from "@rneui/themed";
import { Button } from 'react-native-paper';
import ColorPicker, { Panel1, Swatches, Preview } from 'reanimated-color-picker';
import { FontAwesome5 } from "@expo/vector-icons";

import Colors from "@/constants/Colors";
import useColorScheme from "@/hooks/useColorScheme";
import { Text, View, ScrollView } from "@/components/Themed";

interface ErrorTextProps {
  children: React.ReactNode;
}

export const ErrorText = ({ children }: ErrorTextProps) => (
  <Text style={styles.errorText}>{children}</Text>
);

interface Props {
  onSubmit: any;
  initialValues: any;
}

export const ProjectForm = ({
  onSubmit,
  initialValues = {},
}: Props) => {
  const colorScheme = useColorScheme();

  const onSubmitMiddleware = (
    formikValues,
    formikHelpers,
  ) => {
    return onSubmit(formikValues, formikHelpers);
  }

  return (
    <Formik
      initialValues={{
        name: "",
        colorHex: '#0000FF',
        icon: 'book',
        ...initialValues,
      }}
      onSubmit={onSubmitMiddleware}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <ScrollView contentContainerStyle={styles.container}>
          <Input
            placeholder="Name"
            autoCapitalize="words"
            onChangeText={handleChange("name")}
            onBlur={handleBlur("name")}
            value={values.name}
            errorMessage={touched.name && errors.name ? errors.name : undefined}
          />

          <Input
            placeholder="Icon"
            onChangeText={handleChange("icon")}
            onBlur={handleBlur("icon")}
            value={values.icon}
            autoCapitalize={"none"}
            autoCorrect={false}
            rightIcon={
              <FontAwesome5
                name={values.icon}
                size={35}
                style={{ color: Colors[colorScheme].text }}
              />
            }
            errorMessage={touched.icon && errors.icon ? errors.icon : undefined}
          />

          <View style={styles.colorContainer}>
            <ColorPicker
              style={{ width: '80%' }}
              value={values.colorHex}
              onComplete={({ hex }) => setFieldValue("colorHex", hex)}
            >
              <Preview hideInitialColor />
              <Panel1 />
              <Swatches style={styles.swatch}/>
            </ColorPicker>
          </View>

          <Button
            style={styles.button}
            icon="content-save"
            mode="contained"
            loading={isSubmitting}
            onPress={handleSubmit}
          >
            Submit
          </Button>
        </ScrollView>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 100,
  },
  errorText: {
    color: "red",
    marginVertical: 10,
  },
  colorContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  swatch: {
    marginTop: 10,
  },
  button: {
    marginTop: 20,
  },
});

export default ProjectForm;

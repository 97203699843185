import React from "react";
import useSWR from "swr";
import queryString from "query-string";

import { OCCURRENCES_URL, INCIDENTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";
import isEmpty from "@/helpers/isEmpty";

import Timeline from "./Timeline";

interface Props {
  viceParams: Record<string, any>;
  habitParams: Record<string, any>;
}

export function TimelineContainer({ viceParams, habitParams }: Props) {
  const fetcher = useRequestAndSetTokens();

  let occurrencesUrl = OCCURRENCES_URL;
  let incidentsUrl = INCIDENTS_URL;

  const showVicesOnly = !isEmpty(viceParams);
  const showHabitsOnly = !isEmpty(habitParams);

  // If there is a viceId, then this timeline should only show that vices incidents
  if (showVicesOnly) {
    occurrencesUrl = undefined;
    incidentsUrl = `${incidentsUrl}?${queryString.stringify(viceParams)}`;
  } else if (showHabitsOnly) {
    occurrencesUrl = `${occurrencesUrl}?${queryString.stringify(habitParams)}`;
    incidentsUrl = undefined;
  }

  const { data: occurrencesData, mutate: mutateOccurrences } = useSWR(
    occurrencesUrl,
    fetcher,
    { suspense: true },
  );
  const { data: incidentsData, mutate: mutateIncidents } = useSWR(
    incidentsUrl,
    fetcher,
    { suspense: true },
  );

  const props = {
    mutateIncidents: mutateIncidents,
    mutateOccurrences: mutateOccurrences,
  };

  if (showVicesOnly) {
    props.occurrences = [];
    props.incidents = incidentsData.incidents;
  } else if (showHabitsOnly) {
    props.occurrences = occurrencesData.occurrences;
    props.incidents = [];
  } else {
    props.occurrences = occurrencesData.occurrences;
    props.incidents = incidentsData.incidents;
  }

  return <Timeline {...props} />;
}

export default TimelineContainer;

import React, { Suspense } from "react";
import { StyleSheet } from "react-native";
import { useStoreState } from "easy-peasy";

import { View } from "@/components/Themed";
import HabitList from "@/components/HabitList/HabitList";
import HabitListContainer from "@/components/HabitList";
import ViceList from "@/components/ViceList/ViceList";
import ViceListContainer from "@/components/ViceList";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";

export const EMPTY_SELECTED_TAG = undefined;

export function HabitsScreen() {
  const habitSwitch = useStoreState((state) => state.general.habitSwitch);

  return (
    <View style={styles.container}>
      <ErrorBoundary>
        <BannerAd />

        <View style={styles.contentContainer}>
          {habitSwitch ? (
            <Suspense fallback={<HabitList isLoading />}>
              <HabitListContainer />
            </Suspense>
          ) : (
            <Suspense fallback={<ViceList isLoading />}>
              <ViceListContainer />
            </Suspense>
          )}
        </View>
      </ErrorBoundary>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
});

export default HabitsScreen;

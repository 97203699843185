/* eslint-disable react/prop-types */

import React from "react";
import { Button, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { View, Text } from "@/components/Themed";

export function ConfirmationScreen({ route }) {
  const navigation = useNavigation();

  const { account_confirmation_success: success } = route.params;

  let message = "Oops! A problem occurred!";
  if (success) {
    message = "Email succssfully confirmed!";
  }

  const onPress = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={styles.container}>
      <Text
        style={{
          textAlign: "center",
          fontSize: 20,
        }}
      >
        {message}
      </Text>
      {success ? (
        <Button title="Sign in!" onPress={() => onPress()} />
      ) : undefined}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 50,
    flex: 1,
  },
});

export default ConfirmationScreen;

import React from "react";
import { StyleSheet } from "react-native";
import { FAB } from 'react-native-paper';
import { useStoreActions } from "easy-peasy";
import { useNavigation } from "@react-navigation/native";

export function DevMenu() {
  const navigation = useNavigation();
  const [state, setState] = React.useState({ open: false });
  const viewNavigation = useStoreActions(
    (actions) => actions.modal.viewNavigation,
  );
  const viewLocationSimulator = useStoreActions(
    (actions) => actions.modal.viewLocationSimulator,
  );
  const viewRegisteredNotifications = useStoreActions(
    (actions) => actions.modal.viewRegisteredNotifications,
  );
  const viewLogs = useStoreActions(
    (actions) => actions.modal.viewLogs,
  );

  const onStateChange = ({ open }) => setState({ open });

  const { open } = state;

  return (
    <FAB.Group
      style={styles.fabStyle}
      open={open}
      visible
      icon={open ? 'calendar-today' : 'plus'}
      actions={[
        {
          icon: 'map',
          label: 'Navigation',
          onPress: () => {
            viewNavigation()
            navigation.navigate("Modal");
          },
        },
        {
          icon: 'earth',
          label: 'Location Simulator',
          onPress: () => {
            viewLocationSimulator()
            navigation.navigate("Modal");
          },
        },
        {
          icon: 'bell',
          label: 'Registered Notifications',
          onPress: () => {
            viewRegisteredNotifications()
            navigation.navigate("Modal");
          },
        },
        {
          icon: 'note-text-outline',
          label: 'Logs',
          onPress: () => {
            viewLogs()
            navigation.navigate("Modal");
          },
        },
      ]}
      onStateChange={onStateChange}
      onPress={() => {
        if (open) {
          // do something if the speed dial is open
        }
      }}
    />
  );
}
const styles = StyleSheet.create({
  fabStyle: {
    paddingBottom: 160,
    paddingRight: 5,
  },
});

export default DevMenu;

/* eslint-disable react/prop-types */

import React from "react";
import { Button, Input } from "@rneui/themed";
import { useStoreActions } from "easy-peasy";
import Toast from "react-native-toast-message";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { View, Text } from "@/components/Themed";
import { logger } from "@/helpers/logger";

export function NewPasswordScreen({ route }) {
  const navigation = useNavigation();
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  const { reset_password_token: resetPasswordToken } = route.params;

  const setNewPasswordAction = useStoreActions(
    (actions) => actions.session.setNewPassword,
  );

  const setNewPassword = async () => {
    try {
      await setNewPasswordAction({
        password,
        passwordConfirmation,
        resetPasswordToken,
      });

      Toast.show({
        type: "success",
        text1: "Success!",
        text2: "Your password has been reset.",
        position: "bottom",
      });

      navigation.navigate("SignIn");
    } catch (error) {
      Toast.show({
        type: "error",
        text1: "Whoops",
        text2: "An error occurred while setting new password.",
        position: "bottom",
      });

      logger.error("unable-to-set-new-password", { error });
    }
  };

  const onPressSignUp = () => {
    navigation.navigate("SignUp");
  };

  const onPressSignIn = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={styles.container}>
      <Input
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />

      <Input
        placeholder="Password Confirmation"
        value={passwordConfirmation}
        onChangeText={setPasswordConfirmation}
        secureTextEntry
      />

      <View
        style={{
          paddingHorizontal: 20,
          marginBottom: 20,
        }}
      >
        <Button title="Set New Password" onPress={() => setNewPassword()} />
      </View>

      <View
        style={{
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Remembered your password?</Text>
        <Button type="clear" title="Sign in!" onPress={() => onPressSignIn()} />
      </View>

      <View
        style={{
          marginTop: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Don&apos;t have an account?</Text>
        <Button type="clear" title="Sign up!" onPress={() => onPressSignUp()} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 20,
  },
});

export default NewPasswordScreen;

import React, { useCallback, useState, useRef } from "react";
import { LayoutAnimation, ScrollView, StyleSheet } from "react-native";
import { Button, Skeleton } from "@rneui/themed";
import Rainbow from "rainbowvis.js";
import { LinearGradient } from "expo-linear-gradient";
import { FlashList } from "@shopify/flash-list";
import { sort } from 'fast-sort';
import { useIsFocused } from '@react-navigation/native';

import { View, Text } from '@/components/Themed';
import { RED, YELLOW, GREEN } from "@/constants/Colors";
import { partition } from "@/helpers/partition";
import isEmpty from "@/helpers/isEmpty";
import EmptyList from "@/components/EmptyList";
import { getDailyDurationInMinutes } from "@/helpers/get-daily-bandwidth";
import { FilterAndSort } from "@/components/FilterAndSort";
import habitIsTimely from "@/helpers/habit-is-timely";

import HabitCard from "./HabitCard";
import HabitCardArchived from "./HabitCardArchived";

const getFilteredHabits = (habits, text, tag, project) => {
  let filteredHabits = habits;

  if (!isEmpty(text)) {
    filteredHabits = filteredHabits.filter((habit) => {
      return habit.name.toLowerCase().match(text.toLowerCase());
    });
  }

  if (tag) {
    filteredHabits = filteredHabits.filter((habit) => {
      return habit.tags.some((t) => t.id === tag);
    });
  }

  if (project) {
    filteredHabits = filteredHabits.filter((habit) => {
      return habit.project_id === project
    });
  }

  return filteredHabits;
};

const getRainbow = (filteredHabits) => {
  // Instantiate Rainbow so we can calculate a gradient and generate hexes (green to red)
  const rainbow = new Rainbow();

  // Calculate the maximum range of the filtered habits
  const filteredFilteredHabits = filteredHabits.filter((habit) => {
    return Number.isFinite(habit.urgency_index);
  });
  const indexes = filteredFilteredHabits.map((o) => o.urgency_index);
  const maxIndex = Math.max(
    Math.max(...indexes), // Potentially -Infinity
    Math.max(...[1]),
  );

  // Configure Rainbow
  rainbow.setSpectrum(GREEN, YELLOW, RED);
  rainbow.setNumberRange(0, maxIndex);

  return rainbow;
};

const getSortedHabits = (habits, projects, sortOrder) => {
  if (sortOrder === 'durationInMinutes') {
    return sort(habits).by([
      { desc: h => getDailyDurationInMinutes(h) },
      { asc: h => h.name.toLowerCase() },
    ]);
  }

  if (sortOrder === 'name') {
    return sort(habits).by([
      { asc: h => h.name.toLowerCase() },
    ]);
  }

  if (sortOrder === 'project') {
    return sort(habits).by([
      {
        asc: (h) => {
          const project = projects.find(project => project.id === h.project_id);

          if (project) {
            return project.name.toLowerCase();
          }
        },
      },
    ]);
  }

  const [newHabits, oldHabits] = partition(
    habits,
    (h) => h.urgency_index === "Infinity",
  );

  const [strictHabits, flexibleHabits] = partition(oldHabits, (h) => h.strict);

  const sortedNewHabits = newHabits.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const undoneHabits = strictHabits
    .filter((h) => h.strict && !h.fulfilled)
    .sort(sortFunction);

  const sortedFlexibleHabits = flexibleHabits
    .filter((h) => Number.isFinite(h.urgency_index))
    .sort(sortFunction);

  const doneHabits = strictHabits
    .filter((h) => h.strict && h.fulfilled)
    .sort(sortFunction);

  return [
    ...sortedNewHabits,
    ...undoneHabits,
    ...sortedFlexibleHabits,
    ...doneHabits,
  ];
};

const sortFunction = (habitA, habitB) => {
  return (
    habitB.urgency_index - habitA.urgency_index ||
    habitA.name.localeCompare(habitB.name)
  );
};

const getUrgencyIndexColor = (habit, rainbow) => {
  // If it's a number, calculate the hex
  if (Number.isFinite(habit.urgency_index)) {
    return rainbow.colourAt(habit.urgency_index);
  }

  // Red ('Infinity', 'Undone', etc...)
  return RED;
};

interface Props {
  habits?: any[];
  mutate: any;
  isLoading?: boolean;
}

export default function HabitList({
  habits = [],
  projects = [],
  mutate,
  isLoading = false,
}: Props) {
  const isFocused = useIsFocused();

  // Archived habits
  const [showArchivedHabits, setShowArchivedHabits] = useState(false);

  // Search and sort
  const [tag, setTag] = useState();
  const [project, setProject] = useState();
  const [text, setText] = useState("");
  const [sortOrder, setSortOrder] = useState();

  const filteredHabits = getFilteredHabits(habits, text, tag, project);

  // TODO IS THIS THE CAUSE FOR SLOW PERFORMANCE
  // Get rainbow for coloring the urgency numbers;
  const rainbow = getRainbow(filteredHabits);

  // Partition out the archived habits
  const [archivedHabits, activeHabits] = partition(filteredHabits, (h) => h.discarded_at);

  // Partition out timely habits
  // Timely = for weekly or daily habits with periodStartWhatever, the currentTime within X seconds of periodStart
  const [timelyHabits, untimelyHabits] = partition(activeHabits, (h) => habitIsTimely(h));

  // Sort those habits
  const sortedUntimelyHabits = getSortedHabits(untimelyHabits, projects, sortOrder);
  const sortedTimelyHabits = getSortedHabits(timelyHabits, projects, sortOrder);

  const list = useRef<FlashList<number> | null>(null);

  // TODO IS THIS THE CAUSE FOR SLOW PERFORMANCE
  const onRemove = () => {
    // This must be called before `LayoutAnimation.configureNext` in order for the animation to run properly.
    list.current?.prepareForLayoutAnimationRender();
    // After removing the item, we can start the animation.
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }

  const renderItem = useCallback(({ item }) => {
    return (
      <HabitCard
        onRemove={onRemove}
        habit={item}
        urgencyIndexColor={getUrgencyIndexColor(item, rainbow)}
      />
    );
  }, [rainbow]);

  const renderArchivedItem = useCallback(({ item }) => {
    return (
      <HabitCardArchived
        habit={item}
        urgencyIndexColor={getUrgencyIndexColor(item, rainbow)}
      />
    );
  }, [rainbow]);

  if (isLoading) {
    return (
      <ScrollView style={styles.flashListContainer}>
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
        <Skeleton
          LinearGradientComponent={LinearGradient}
          animation="wave"
          height={90}
          style={styles.skeleton}
        />
      </ScrollView>
    );
  }

  return (
    <ScrollView style={styles.container}>
      {isFocused && (
        <FilterAndSort
          isFocused={isFocused}
          tag={tag}
          onChangeTag={setTag}
          text={text}
          onChangeText={setText}
          project={project}
          onChangeProject={setProject}
          sortOrder={sortOrder}
          onChangeSortOrder={setSortOrder}
        />
      )}

      {timelyHabits.length > 0 && (
        <View style={styles.timelyContainer}>
          <Text style={styles.timelyHeader}>Timely</Text>

          <FlashList
            estimatedItemSize={101}
            data={sortedTimelyHabits}
            keyExtractor={(item) => item.id.toString()}
            contentContainerStyle={styles.flashListContainerTimely}
            renderItem={renderItem}
          />
        </View>
      )}

      <FlashList
        // Saving reference to the `FlashList` instance to later trigger `prepareForLayoutAnimationRender` method.
        ref={list}
        estimatedItemSize={101}
        data={sortedUntimelyHabits}
        keyExtractor={(item) => item.id.toString()}
        onRefresh={() => mutate()}
        refreshing={false}
        contentContainerStyle={styles.flashListContainer}
        renderItem={renderItem}
        ListEmptyComponent={<EmptyList containerStyles={styles.emptyListContainer} noun='habits' />}
      />

      {isEmpty(archivedHabits) ? undefined : (
        <>
          <Button
            type="secondary"
            onPress={() => setShowArchivedHabits(!showArchivedHabits)}
            title={showArchivedHabits ? "Hide Archived" : "Show Archived"}
            containerStyle={{ marginBottom: 10 }}
          />

          {showArchivedHabits && (
            <FlashList
              estimatedItemSize={101}
              data={archivedHabits}
              keyExtractor={(item) => item.id.toString()}
              contentContainerStyle={styles.flashListArchivedContainer}
              renderItem={renderArchivedItem}
            />
          )}
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  skeleton: {
    marginBottom: 10,
  },
  emptyListContainer: {
    marginTop: 80,
  },
  timelyHeader: {
    fontSize: 20,
    marginBottom: 10,
  },
  timelyContainer: {
    marginTop: 15,
    marginHorizontal: 5,
    paddingHorizontal: 10,
    paddingTop: 5,
    borderColor: 'green',
    borderWidth: 2,
    borderRadius: 10,
  },
  flashListContainerTimely: {
  },
  flashListContainer: {
    paddingTop: 10,
    paddingHorizontal: 15,
  },
  flashListArchivedContainer: {
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 50,
  },
});

import React from "react";
import { StyleSheet } from "react-native";
import intervalToDuration from "date-fns/intervalToDuration";

import { Text, View } from "@/components/Themed";
import useTime from "@/hooks/useTime";

interface Props {
  startTime: string;
}

const Timer = ({ startTime }: Props) => {
  const now = useTime();
  const then = new Date(startTime);
  const duration = intervalToDuration({ start: then, end: now });

  return (
    <View style={styles.timerContainer}>
      <View style={styles.timeCard}>
        <Text style={styles.label}>Years</Text>
        <Text style={styles.value}>{duration.years}</Text>
      </View>

      <View style={styles.timeCard}>
        <Text style={styles.label}>Months</Text>
        <Text style={styles.value}>{duration.months}</Text>
      </View>

      <View style={styles.timeCard}>
        <Text style={styles.label}>Days</Text>
        <Text style={styles.value}>{duration.days}</Text>
      </View>

      <View style={styles.timeCard}>
        <Text style={styles.label}>Hours</Text>
        <Text style={styles.value}>{duration.hours}</Text>
      </View>

      <View style={styles.timeCard}>
        <Text style={styles.label}>Minutes</Text>
        <Text style={styles.value}>{duration.minutes}</Text>
      </View>

      <View style={styles.timeCard}>
        <Text style={styles.label}>Seconds</Text>
        <Text style={styles.value}>{duration.seconds}</Text>
      </View>
    </View>
  );
};

interface Props {
  startTime: string;
  style: any;
}

export function CountUp({ startTime, style }: Props) {
  return (
    <View style={[style, styles.container]}>
      {startTime ? (
        <Timer startTime={startTime} />
      ) : (
        <Text style={{ textAlign: "center" }}>Never occurred! nice...</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
  },
  timerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  timeCard: {
    backgroundColor: "transparent",
  },
  label: {
    textAlign: "center",
  },
  value: {
    textAlign: "center",
  },
});

export default CountUp;

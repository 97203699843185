import logger from "./logger";
import isEmpty from "./isEmpty";
import { request, AUTOMATIONS_URL } from "./api";

export const handleNewLocations = async ({ user, data, tokens }) => {
  try {
    if (!user) {
      logger.info("no-user-present");
      return;
    }

    if (!tokens || isEmpty(tokens)) {
      logger.info("no-tokens");
      return;
    }

    const locations = data?.locations;

    if (!locations || isEmpty(locations)) {
      logger.warn('no-locations-given');

      return;
    }

    logger.info('sending-locations', { locations });

    await request(AUTOMATIONS_URL, {
      method: 'POST',
      headers: {
        ...tokens,
      },
      body: {
        type: 'location',
        locations: locations.map(location => ({
          accuracy: location?.coords?.accuracy,
          altitude: location?.coords?.altitude,
          altitudeAccuracy: location?.coords?.altitudeAccuracy,
          heading: location?.coords?.heading,
          latitude: location?.coords?.latitude,
          longitude: location?.coords?.longitude,
          speed: location?.coords?.speed,
          timestamp: location?.timestamp,
        })),
      }
    });

    logger.info('handle-new-locations-success');
  } catch (error) {
    logger.error('handle-new-locations', { error });
  }
}

export default handleNewLocations;

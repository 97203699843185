import React from "react";
import { ActivityIndicator, Alert, Platform, StyleSheet } from "react-native";
import Toast from "react-native-toast-message";
import { mutate } from "swr";
import camelCase from "camelcase";
import { Button } from "@rneui/themed";
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import { selectionAsync } from "@/helpers/haptics";
import useRequestAndSetTokens from "@/hooks/useRequestAndSetTokens";
import { View } from "@/components/Themed";
import { TAGS_URL, OCCURRENCES_URL, HABITS_URL } from "@/helpers/api";
import HabitForm from "@/components/HabitForm";
import logger from "@/helpers/logger";

interface Props {
  habit: any;
  isLoading?: boolean;
  existingTags: any[];
}

enum Action {
  Archive = "Archive",
  Unarchive = "Unarchive",
}

export function HabitEdit({ isLoading = false, habit, existingTags = []}: Props) {
  const navigation = useNavigation();
  // TODO: Figure out why this is causing the following error:
  // Warning: Can't perform a React state update on an unmounted component
  const requestAndSetTokens = useRequestAndSetTokens();

  const onSubmit = async (
    formikValues,
    formikHelpers,
  ) => {
    selectionAsync();

    const {
      name,
      link,
      frequencyPeriod: frequency_period,
      frequencyTarget: frequency_target,
      frequencyNumber: frequency_number,
      isStrict: strict,
      fromPeriodStart: from_period_start,
      importanceScalar: importance_scalar,
      tagsArray,
      durationInMinutes: duration_in_minutes,
      periodStartTime: period_start_time,
      periodStartDay: period_start_day,
      notifyWhenDue: notify_when_due,
      projectId: project_id,
      timelinessDurationInSeconds: timeliness_duration_in_seconds,
    } = formikValues;
    formikHelpers.setSubmitting(true);

    try {
      const url = `${HABITS_URL}/${habit.id}`;
      const json = await requestAndSetTokens(url, {
        method: "PATCH",
        body: {
          habit: {
            name,
            frequency_target,
            frequency_number,
            frequency_period,
            from_period_start,
            link,
            importance_scalar,
            strict,
            tag_list: tagsArray.join(","),
            duration_in_minutes,
            period_start_time,
            period_start_day,
            notify_when_due,
            project_id,
            timeliness_duration_in_seconds,
          },
        },
      });

      Toast.show({
        type: "success",
        text1: "Success",
        text2: "Habit updated! 😻",
        position: "bottom",
      });

      // Update all the cached stuff
      mutate(TAGS_URL);
      mutate(url, { habit: json.habit }, { revalidate: false });
      mutate(OCCURRENCES_URL);
      mutate(HABITS_URL);

      navigation.navigate("HabitView", { id: json.habit.id });
    } catch (error) {
      const errors = error?.result?.errors;

      if (errors) {
        logger.error("Error updating habit", { error: errors });

        const formikErrors = Object.fromEntries(
          Object.entries(errors).map(([k, v]) => [camelCase(k), v.join(", ")]),
        );

        formikHelpers.setErrors(formikErrors);
      } else {
        logger.error("Error updating habit", { error });

        Toast.show({
          type: "error",
          text1: "Whoops",
          text2: "An error occurred while updating the habit.",
          position: "bottom",
        });
      }
      formikHelpers.setSubmitting(false);
    }
  };

  React.useLayoutEffect(() => {
    if (!habit) return;

    const createAlert = (onArchive, action: Action) =>
      Alert.alert(
        "Archive Confirmation",
        `Are you sure you want to ${action.toLowerCase()} this habit?`,
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          { text: action, onPress: action === Action.Archive ? onArchive: onUnarchive },
        ],
      );

    const onUnarchive = () => {
      return executeRequest(Action.Unarchive);
    }

    const onArchive = () => {
      return executeRequest(Action.Archive);

    }

    const executeRequest = async (action: Action) => {
      selectionAsync();

      try {
        const url = `${HABITS_URL}/${habit.id}`;
        const json = await requestAndSetTokens(url, {
          method: "DELETE",
        });

        // Update all the cached stuff
        mutate(url, { habit: json.habit }, { revalidate: false });
        mutate(OCCURRENCES_URL);
        mutate(HABITS_URL);

        Toast.show({
          type: "success",
          text1: "Success",
          text2: `Habit ${action}d! 🪦`,
          position: "bottom",
        });

        navigation.navigate("HabitView", { id: json.habit.id });
      } catch (error) {
        logger.error("Error archiving habit", { error });
      }
    };

    navigation.setOptions({
      headerTitle: `Edit: ${habit.name}`,
    });

    navigation.setOptions({
      headerRight: () => (
        <>
          {habit.discarded_at ? (
            <Button type="clear" onPress={() => createAlert(onArchive, 'Unarchive')}>
              <FontAwesome5 name="inbox" size={30} style={{ color: "green" }} />
            </Button>
          ) : (
            <Button type="clear" onPress={() => createAlert(onArchive, 'Archive')}>
              <FontAwesome5 name="inbox" size={30} style={{ color: "red" }} />
            </Button>
          )}
        </>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(habit), navigation, requestAndSetTokens]);

  if (isLoading) {
    return (
      <ActivityIndicator style={styles.loader} size="large" />
    );
  }

  return (
    <View style={styles.container}>
      <HabitForm
        onSubmit={onSubmit}
        existingTags={existingTags}
        initialValues={{
          name: habit.name,
          link: habit.link,
          frequencyPeriod: habit.frequency_period,
          frequencyTarget: habit.frequency_target,
          frequencyNumber: habit.frequency_number,
          isStrict: habit.strict,
          durationInMinutes: habit.duration_in_minutes,
          fromPeriodStart: habit.from_period_start,
          importanceScalar: habit.importance_scalar,
          tagsArray: habit.tags.map((tag) => tag.name),
          periodStartTime: habit.period_start_time,
          periodStartDay: habit.period_start_day,
          notifyWhenDue: habit.notify_when_due,
          projectId: habit.project_id,
          timelinessDurationInSeconds: habit.timeliness_duration_in_seconds,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
    paddingTop: Platform.OS === "android" ? 120 : 0,
  },
  loader: {
    marginTop: 100,
  },
});

export default HabitEdit;

import React, { Suspense } from "react";
import { StyleSheet } from "react-native";

import { View } from "@/components/Themed";
import { RootTabScreenProps } from "@/types";
import ErrorBoundary from "@/components/ErrorBoundary";
import BannerAd from "@/components/BannerAd";
import HabitEdit from "@/components/HabitEdit/HabitEdit";
import HabitEditContainer from "@/components/HabitEdit";

export default function HabitsEditScreen({
  route,
}: RootTabScreenProps<"Habits">) {
  const { id } = route.params;

  return (
    <View style={styles.container}>
      <BannerAd />

      <View style={styles.container}>
        <ErrorBoundary>
          <Suspense fallback={<HabitEdit isLoading />}>
            <HabitEditContainer habitId={id} />
          </Suspense>
        </ErrorBoundary>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

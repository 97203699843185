import React from "react";
import useSWR from "swr";
import { ActivityIndicator } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { Pressable } from "react-native";
import { useStoreActions } from "easy-peasy";
import { useTheme } from 'react-native-paper';

import { HABITS_URL, PROJECTS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import Dashboard from "./Dashboard";

interface Props {
  selectedProject?: any;
}

export function DashboardContainer({
  selectedProject,
}: Props) {
  const theme = useTheme();
  const navigation = useNavigation();
  const fetcher = useRequestAndSetTokens();
  const viewProjectCreate = useStoreActions(
    (actions) => actions.modal.viewProjectCreate,
  );
  const { data: projectsData, isLoading: projectsAreLoading } = useSWR(
    PROJECTS_URL,
    fetcher,
    { suspense: true,
  });

  const { data: habitsData, isLoading: habitsAreLoading} = useSWR(
    HABITS_URL,
    fetcher,
    { suspense: true },
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            viewProjectCreate();
            navigation.navigate("Modal");
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
          })}
        >
          <FontAwesome
            name="plus-circle"
            size={39}
            color={theme.colors.primary}
            style={{ marginRight: 22 }}
          />
        </Pressable>
      ),
    });
  }, [navigation, theme.colors.primary, viewProjectCreate]);

  if (!projectsData || projectsAreLoading) {
    return (
      <ActivityIndicator size="large" />
    );
  };

  if (!habitsData || habitsAreLoading) {
    return (
      <ActivityIndicator size="large" />
    );
  };

  return (
    <Dashboard
      projects={projectsData.projects}
      habits={habitsData.habits}
      selectedProject={selectedProject}
    />
  );
}

export default DashboardContainer;

import React from "react";
import useSWR from "swr";

import { HABITS_URL } from "@/helpers/api";
import { useRequestAndSetTokens } from "@/hooks/useRequestAndSetTokens";

import HabitView from "./HabitView";

interface Props {
  habitId: any;
}

export function HabitViewContainer({ habitId }: Props) {
  const fetcher = useRequestAndSetTokens();
  const { data, isLoading, isValidating, mutate } = useSWR(`${HABITS_URL}/${habitId}`, fetcher, {
    suspense: true,
  });

  if (!data) return;

  return (
    <HabitView
      habit={data.habit}
      isLoading={isLoading}
      isValidating={isValidating}
      mutate={mutate}
    />
  );
}

export default HabitViewContainer;

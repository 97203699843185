import { useCallback } from 'react';
import { useStoreActions, useStoreState } from "easy-peasy";

import { request } from "@/helpers/api";

export const useRequestAndSetTokens = () => {
  const tokens = useStoreState((state) => state.session.tokens);
  const clearTokens = useStoreActions((actions) => actions.session.clearTokens);
  const clearUser = useStoreActions((actions) => actions.session.clearUser);

  const requestFunction = useCallback(async (url, options = {}) => {
    options.headers = tokens;

    // Try to make the request, and if it fails with an unauthorized
    // error, clear the tokens and user
    try {
      const { result } = await request(url, options);
      return result;
    } catch (error) {
      if (error.statusCode === 401) {
        clearUser();
        clearTokens();
      }
      throw error;
    }
  }, [tokens, clearTokens, clearUser]);

  return requestFunction;
};

export default useRequestAndSetTokens;

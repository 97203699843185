/* eslint-disable unicorn/no-array-reduce */

export type Predicate = (...item: any[]) => boolean;

export const partition = (arr, fn: Predicate) =>
  arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[], []],
  );

export default partition;
